import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/client/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClient(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClientMembers(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client/member", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClientMember(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/client/member", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClientLoans(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client/loan", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClientLoan(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/loan", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },


    addMultiClientData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client/multi", {"clients": data })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportClientData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/export", {responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    checkImportClientData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client/validateClientImportData", data )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // multi clients table list
    submissionRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/clientsubmissionrecord", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // export multi clients fail list excel
    exportSubmissionData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/client_submission/export", { params: queryParams, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    

    addMultiClientMemberData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client/member/multi", {"members": data })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportClientMemberData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/member/export", {responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    checkImportClientMemberData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client/member/validateImportData", data )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // multi clients table list
    submissionHomeMemberRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/submissionRecord", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // export multi clients fail list excel
    exportSubmissionHomeMemberData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/client_submission/export", { params: queryParams, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClientReject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client_reject_option/", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClientAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client_account_option/", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editClientReject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/client_reject_option/", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editClientAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/client_account_option/", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    delectClientReject(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/client_reject_option/", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    delectClientAccount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/client_account_option/", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // 2023-10-11
    fetchReceipts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/receipt/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportReceipts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/receipt/export", { params: queryParams, responseType: "blob" })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  }
};
