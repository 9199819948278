<template>
  <!-- modal Add Coin-->
  <validation-observer ref="editLoanForm" #default="{ handleSubmit }">
    <b-modal
      id="modal-loan-edit"
      hide-footer
      cancel-variant="outline-secondary"
      centered
      @ok="handleEdit"
      :ok-disabled="false"
      headerClass="px-2 py-2 border-bottom bgWhite"
    >
      <template #modal-header="{ close }">
        <h3 class="m-0">修改借貸記錄</h3>
        <a @click="close()">
          <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
        </a>
      </template>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-2" @submit.prevent="handleSubmit(handleEdit)">
          <div class="px-2">
            <b-row class="">
              <!-- client_id-->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="客戶"
                  rules="required"
                >
                  <b-form-group
                    label="客戶："
                    :label-for="`loan_client_id`"
                    label-cols-md="3"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="loanDataTemp.client_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="clientsOptions"
                        :filter="clientSearch"
                        :clearable="false"
                        label="name_cn"
                        :reduce="(option) => option.id"
                        placeholder="輸入客戶"
                      >
                        <template #option="data">
                          {{ data.name_cn }} - {{ data.id_number }}
                        </template>
                        <template #selected-option="data">
                          {{ data.name_cn }} - {{ data.id_number }}
                        </template>
                      </v-select>
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- loan_date -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="貸款日期"
                  rules="required"
                >
                  <b-form-group
                    label="貸款日期："
                    :label-for="`loan_date`"
                    label-cols-md="3"
                    class="required"
                  >
                    <flat-pickr
                      v-model="loanDataTemp.loan_date"
                      class="form-control"
                      :config="{ allowInput: true, dateFormat: 'Y-m-d', maxDate:maxDate }"
                      @input="calcLoanCloseDate"
                      placeholder="輸入貸款日期"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- supplier_id-->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="銀行/財務機構"
                  rules="required"
                >
                  <b-form-group
                    label="銀行/財務機構："
                    :label-for="`loan_supplier_id`"
                    label-cols-md="3"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="loanDataTemp.supplier_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="loanSupplierOptions"
                        :clearable="false"
                        label="company_name_cn"
                        :reduce="(option) => option.id"
                        placeholder="輸入銀行/財務機構"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- amount -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="貸款本金"
                  rules="required"
                >
                  <b-form-group
                    label="貸款本金"
                    :label-for="`loan_amount`"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      :id="`loan_amount`"
                      v-model="loanDataTemp.amount"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入貸款本金"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first_pay_date -->
              <b-col cols="12" md="6">
                <!-- <validation-provider
                  #default="validationContext"
                  name="首次供款日期"
                  rules="required"
                > -->
                <b-form-group
                  label="首次供款日期："
                  :label-for="`loan_first_pay_date`"
                  label-cols-md="3"
                >
                  <!-- class="required" -->
                  <flat-pickr
                    v-model="loanDataTemp.first_pay_date"
                    class="form-control"
                    :config="{ altInput:true,altFormat:'Y-m-d' , allowInput: true, dateFormat: 'Y-m-d' }"
                    placeholder="輸入首次供款日期"
                  />
                  <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback> -->
                  </b-form-group>
                <!-- </validation-provider> -->
              </b-col>

              <!-- contract_end_date -->
              <b-col cols="12" md="6">
                <!-- <validation-provider
                  #default="validationContext"
                  name="合約完結日期"
                  rules="required"
                > -->
                  <b-form-group
                    label="合約完結日期："
                    :label-for="`loan_contract_end_date`"
                    label-cols-md="3"
                  >
                    <!-- class="required" -->
                    <flat-pickr
                      v-model="loanDataTemp.contract_end_date"
                      class="form-control"
                      :config="{ altInput:true,altFormat:'Y-m-d' , allowInput: true, dateFormat: 'Y-m-d' }"
                      placeholder="輸入合約完結日期"
                    />
                    <!-- <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback> -->
                  </b-form-group>
                <!-- </validation-provider> -->
              </b-col>

              <hr />
              <!-- period -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="總期數"
                  rules="required"
                >
                  <b-form-group
                    label="總期數："
                    :label-for="`loan_period`"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      :id="`loan_period`"
                      type="number"
                      v-model="loanDataTemp.period"
                      :state="getValidationState(validationContext)"
                      @keyup="calcLoanCloseDate"
                      placeholder="輸入總期數"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- is_monthly -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="供款週期"
                  rules="required"
                 >
                <b-form-group
                  label="供款週期："
                  :label-for="`loan_is_monthly`"
                  label-cols-md="3"
                  class="required"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="loanDataTemp.is_monthly"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="loan_is_monthly"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                       @input="calcLoanCloseDate"
                      placeholder="輸入供款週期"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- min_paid -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="每月/最低供款："
                  :label-for="`loan_min_paid`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_min_paid`"
                    type="number"
                    v-model="loanDataTemp.min_paid"
                    placeholder="輸入每月/最低供款"
                  />
                </b-form-group>
              </b-col>

              <!-- remain_period-->
              <b-col cols="12" md="6">
                <b-form-group
                  label="尚欠期數："
                  :label-for="`loan_remain_period`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_remain_period`"
                    v-model="loanDataTemp.remain_period"
                    placeholder="輸入尚欠期數"
                  />
                </b-form-group>
              </b-col>
              <hr />

              <!-- contract_number -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="合約編號："
                  :label-for="`loan_contract_number`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_contract_number`"
                    v-model="loanDataTemp.contract_number"
                    placeholder="輸入合約編號"
                  />
                </b-form-group>
              </b-col>

              <!-- application_number -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="申請編號："
                  :label-for="`loan_application_number`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_application_number`"
                    v-model="loanDataTemp.application_number"
                    placeholder="輸入申請編號"
                  />
                </b-form-group>
              </b-col>

              <!-- annual_interest_rate -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="年利率："
                  :label-for="`loan_annual_interest_rate`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_annual_interest_rate`"
                    type="number"
                    v-model="loanDataTemp.annual_interest_rate"
                    placeholder="輸入年利率"
                  />
                </b-form-group>
              </b-col>

              <!-- total_interest -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="總利息："
                  :label-for="`loan_total_interest`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_total_interest`"
                    v-model="loanDataTemp.total_interest"
                    placeholder="輸入總利息"
                  />
                </b-form-group>
              </b-col>
              <hr />

              <!-- loan_status_id -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="合約狀況："
                  :label-for="`loan_status_id`"
                  label-cols-md="3"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="loanDataTemp.loan_status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="loanStatusOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="輸入合約狀況"
                      @input="
                        () => {
                          trigger = !trigger;
                        }
                      "
                      :key="`is_monthly${trigger}`"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                </b-form-group>
              </b-col>

              <!-- admin_status_id -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="狀態："
                  :label-for="`admin_status_id`"
                  label-cols-md="3"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="loanDataTemp.admin_status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="adminStatusOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="輸入狀態"
                      @input="
                        () => {
                          trigger = !trigger;
                        }
                      "
                      :key="`is_monthly${trigger}`"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                </b-form-group>
              </b-col>
              <hr />

              <!-- pay_date -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="供款日："
                  :label-for="`loan_pay_date`"
                  label-cols-md="3"
                >
                  <b-form-input
                    :id="`loan_pay_date`"
                    v-model="loanDataTemp.pay_date"
                    placeholder="輸入供款日"
                  />
                </b-form-group>
              </b-col>

              <!-- remark -->
              <b-col cols="12" md="12">
                <div
                  class="
                    d-flex
                    flex-wrap flex-md-nowrap
                    align-items-center
                    full_input
                  "
                >
                  <label>貸款備註：</label>
                  <div class="input_wrap">
                    <b-form-input
                      id="loan_remark"
                      v-model="loanDataTemp.remark"
                      placeholder="輸入貸款備註"
                    />
                  </div>
                </div>
              </b-col>
              <hr />
            </b-row>
          </div>
          <div class="d-flex flex-wrap m-2 justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
              style="line-height: 1.1"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hideModal"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
import loanStoreModule from "../loanStoreModule";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      trigger: false,
      isInit: true,
      loanDataTemp:{},
      required,
      loan_is_monthly: [
        { id: 1, name: "月供" },
        { id: 0, name: "半月供" },
      ],
    };
  },
  props: {
    loanData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-loan-edit", "#btnShow");
    },
    handleEdit() {
      if(this.loanDataTemp.hasOwnProperty('client'))
        delete this.loanDataTemp['client']

      if(this.loanDataTemp.hasOwnProperty('supplier'))
        delete this.loanDataTemp['supplier']

      if(this.loanDataTemp.hasOwnProperty('logs'))
        delete this.loanDataTemp['logs']
        
      if(this.loanDataTemp.hasOwnProperty('creator'))
        delete this.loanDataTemp['creator']

      if(this.loanDataTemp.hasOwnProperty('loan_status'))
        delete this.loanDataTemp['loan_status']

      if(this.loanDataTemp.hasOwnProperty('admin_status'))
        delete this.loanDataTemp['admin_status']
        
      this.loading = true;
      store
        .dispatch("app-loan/editLoan", this.loanDataTemp)
        .then((response) => {
          this.loading = false;
          this.$bvModal.hide("modal-loan-edit");
          this.$swal({
            text: response.data.message,
            icon: "success",
            confirmButtonText: "提交",
            cancelButtonText: "取消",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });

          this.$emit("refetch-data");
        })
        .catch((error) => {
          this.loading = false;
          const message = error.response.data.message;
          var showMessage = "";
          var showMessage = message;
          if (typeof showMessage === "object") {
            showMessage = "";
            for (var key in message) {
              if (message.hasOwnProperty(key)) {
                showMessage += message[key] + "\n";
              }
            }
          }
          this.$swal({
            text: showMessage,
            icon: "error",
            cancelButtonText: "取消",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    clientSearch(options, search) {
      var returnOptions = options.filter((item) => {
        return (
          item.name_cn.toLowerCase().includes(search.toLowerCase()) ||
          item.id_number.toLowerCase().includes(search.toLowerCase())
        );
      });
      return returnOptions;
    },

    calcLoanCloseDate() {
      if (
        this.loanDataTemp.loan_date &&
        this.loanDataTemp.period &&
        (this.loanDataTemp.is_monthly || this.loanDataTemp.is_monthly  == 0) 
      ) {
        const data = {'loan_date': this.loanDataTemp.loan_date, 'period' : this.loanDataTemp.period, 'is_monthly': this.loanDataTemp.is_monthly}

        store
          .dispatch("app-loan/getContractEndDate",data )
          .then((response) => {
            this.loanDataTemp.first_pay_date = response.data.data.paid_date[0]
            this.loanDataTemp.contract_end_date = response.data.data.contract_end_date
            this.loanDataTemp.pay_date = response.data.data.paid_date.join(' , ');
          })
          .catch((error) => {
            this.$swal({
              text: JSON.stringify(error.response.data.message),
              icon: "error",
              cancelButtonText: "取消",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              showClass: {
                popup: "animate__animated animate__bounceIn",
              },
              buttonsStyling: false,
            });
          });
      }
    },
  },
  watch:{
   loanData:{
      handler() {
        this.loanDataTemp =  { ...this.loanData };
      },deep:true
    },
  },
  setup() {
    const resetLoanData = () => {
      props.loanData = JSON.parse(JSON.stringify({ id: 0, loan_date: formatTimeWithoutHMS(new Date()) }));
    };
    // need
    const formatTimeWithoutHMS = (time) => {
      let newt = new Date(time),
        month,
        day,
        hour,
        min,
        sec;
      month = newt.getMonth() + 1;
      month = month < 10 ? "0" + month : month;

      day = newt.getDate();
      day = day < 10 ? "0" + day : day;

      return `${newt.getFullYear()}-${month}-${day}`;
    };

    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetLoanData);

    const adminStatusOptions = ref([]);
    const loanStatusOptions = ref([]);
    const loanSupplierOptions = ref([]);
    const clientsOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          admin_status: true,
          loan_status: true,
          supplier: true,
          client: true,
        })
        .then((response) => {
          adminStatusOptions.value = response.data.admin_statuses;
          loanStatusOptions.value = response.data.loan_statuses;
          loanSupplierOptions.value = response.data.suppliers;
          clientsOptions.value = response.data.clients;
        });
    };

    onMounted(() => {
      refetchOption();
    });

    return {
      adminStatusOptions,
      loanStatusOptions,
      loanSupplierOptions,
      clientsOptions,
      refetchOption,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    };
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#modal-loan-edit {
  .modal-dialog {
    max-width: 1200px;
  }
}
</style>