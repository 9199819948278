<template>
  <b-modal
    id="modal-predict"
    cancel-variant="outline-secondary"
    centered
    hide-footer
    size="lg"
    :ok-disabled="false"
    headerClass="px-2 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >
    <template #modal-header="{ close }">
      <h3 class="m-0">模擬抽獎</h3>
      <a @click="close()">
        <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
      </a>
    </template>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
      <b-form class="mt-sm-1" @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" md="12">
            <validation-provider
              #default="validationContext"
              name="抽獎次數"
              rules="required"
            >
              <b-form-group
                label="抽獎次數："
                label-for="modal_award_draw_no"
                label-cols-md="3"
                class="required"
              >
                <b-form-input
                  id="modal_award_draw_no"
                  v-model="number_of_draw"
                  placeholder="輸入抽獎次數"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <div class="d-flex" v-if="predictData">
          <h5>獎品總值：{{ predictData.total_reward }}</h5>
        </div>
        <b-table
          ref="refAwardListTable"
          class="position-relative mt-2"
          :items="predictData.prize"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="未找到匹配的記錄"
        >
          <!-- Column: count -->
          <template #cell(count)="data">
            <div class="d-flex">{{ data.item.count }}次</div>
          </template>

          <!-- Column: winning_percentage -->
          <template #cell(winning_percentage)="data">
            <div class="d-flex">{{ data.item.winning_percentage }}%</div>
          </template>

          <!-- Column: is_coin -->
          <template #cell(is_coin)="data">
            <div class="d-flex">
              <feather-icon
                v-if="data.item.is_coin"
                size="24"
                icon="CheckIcon"
              ></feather-icon>
              <feather-icon v-else size="24" icon="XIcon"></feather-icon>
            </div>
          </template>
        </b-table>

        <div class="d-flex justify-content-end mt-3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            提交
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            取消
          </b-button>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCardBody,
  BCard,
  BTable,
  BFormInvalidFeedback,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import awardStoreModule from "../../awardStoreModule";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
import { ref, onUnmounted } from "@vue/composition-api";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    vSelect,
    BCard,
    BFormInvalidFeedback,
    BFormCheckbox,
    BTable,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      number_of_draw: 1000,
      predictData: "",
      tableColumns: [
        { key: "reward_title", label: "獎品名稱" },
        { key: "amount", label: "獎品價值" },
        { key: "winning_percentage", label: "得獎率" },
        { key: "is_coin", label: "是否幣" },
        { key: "count", label: "中獎次數" },
        { key: "total_reward", label: "總值" },
      ],
    };
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-predict");
    },

    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-award/fetchWheelPredict", this.number_of_draw)
            .then((response) => {
              this.loading = false;
              this.predictData = response.data;
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
  },
  setup(props, { emit }) {
    const AWARD_APP_STORE_MODULE_NAME = "app-award";

    if (!store.hasModule(AWARD_APP_STORE_MODULE_NAME))
      store.registerModule(AWARD_APP_STORE_MODULE_NAME, awardStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AWARD_APP_STORE_MODULE_NAME))
        store.unregisterModule(AWARD_APP_STORE_MODULE_NAME);
    });

    const resetAwardData = () => {
      props.AwardData = JSON.parse(JSON.stringify({}));
    };

    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetAwardData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

