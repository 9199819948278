<template>
  <div class="p-2 bgCommon">
    <page-list-add-new
      :is-add-new-page-sidebar-active.sync="isAddNewPageSidebarActive"
      :data="pageData"
      :id="id"
      @refetch-data="refetchData"
    />

    <div class="mb-1">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          class="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <label>Show</label> -->
          <!-- <h3 class="font-weight-bolder text-nowrap mb-0">
            <feather-icon size="18" icon="BookmarkIcon" />
            頁面
          </h3> -->
          <span class="title-size text-nowrap">頁面</span>
          <div class="form-col-select mx-50">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <!-- <label>entries</label> -->

          <div
            class="d-flex mt-1 mt-sm-0 align-items-center w-100 justify-content-end"
          >
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜尋..."
              />
            </div>
            <b-button variant="primary" @click="addPage" v-if="false">
              <span class="text-nowrap">Add Page</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-card no-body class="mb-0">
      <b-card-body class="p-2">
        <b-table
          ref="refPageListTable"
          class="position-relative text-justify"
          :items="fetchPages"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(show_details)="row">
            <b-button
              v-if="row.item.content"
              size="sm"
              @click="row.toggleDetails"
              class="mr-2"
            >
              {{ row.detailsShowing ? "隱藏" : "顯示" }}
            </b-button>
          </template>

          <template #row-details="row">
            <div v-html="row.item.content" class="page-detail"></div>
          </template>

          <!-- Column: is_active -->
          <template #cell(is_active)="data">
            <feather-icon
              v-if="data.item.is_active"
              icon="CheckIcon"
              size="18"
              class="mr-50 text-success"
            />
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="handleEdit(data.item)"
                v-if="ability.can('update', 'page')"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">修改</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="confirmDelete(data.item.id)"
                v-if="false"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalPages"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import pageStoreModule from "../pageStoreModule";
import usePagesList from "./usePagesList";
import PageListAddNew from "./PageListAddNew.vue";

export default {
  components: {
    PageListAddNew,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
  },
  data() {
    return {
      id: 0,
      pageData: {},
    };
  },
  methods: {
    confirmDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the page.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("page/deletePage", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleEdit(item) {
      this.id = item.id;
      this.pageData = { ...item };
      this.isAddNewPageSidebarActive = true;
    },
    addPage() {
      this.id = 0;
      this.pageData = { id: 0, title: "", content: "", is_active: 1 };
      this.isAddNewPageSidebarActive = true;
    },
  },
  setup() {
    const APP_CLIENT_STORE_MODULE_NAME = "page";
    const isAddNewPageSidebarActive = ref(false);

    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(APP_CLIENT_STORE_MODULE_NAME, pageStoreModule);

    const {
      fetchPages,
      tableColumns,
      perPage,
      currentPage,
      totalPages,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refPageListTable,
      ability,
      statusFilter,
    } = usePagesList();

    return {
      isAddNewPageSidebarActive,
      fetchPages,
      tableColumns,
      perPage,
      currentPage,
      totalPages,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refPageListTable,
      ability,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.page-detail img {
  max-width: 100%;
}

.page-detail {
  a {
    color: #22c48b !important;
    &:hover {
      color: #189368!important;
    }
  }
}
</style>
