<template>
  <div class="p-2 bgCommon">
    <!-- Table Top -->

    <div
      class="d-flex flex-wrap mt-2 mb-1 align-items-center justify-content-between"
    >
      <!-- <h3 class="font-weight-bolder text-nowrap mb-0">
            <feather-icon size="18" icon="GiftIcon" />
            中獎記錄
          </h3> -->
      <div class="d-flex align-items-center">
        <div>
          <!-- <feather-icon size="16" icon="SearchIcon"></feather-icon> -->
          <span class="title-size text-nowrap">中獎記錄</span>
        </div>
        <div class="form-col-select ml-2">
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block"
          />
          <feather-icon size="18" icon="ChevronDownIcon" />
        </div>
      </div>
      <div class="d-flex align-items-center mobile-w100 mt-1 mt-sm-0">
        <div class="search-primary">
          <feather-icon size="16" icon="SearchIcon" />
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="搜尋記錄"
          />
        </div>
      </div>
    </div>
    <b-card no-body class="mb-0">
      <b-card-body class="p-2">
        <b-table
          ref="refRecordListTable"
          class="position-relative"
          :items="fetchRecords"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="未找到匹配的記錄"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: create time -->
          <template #cell(created_at)="data">
            {{ getCorrectDateTime(data.item.created_at) }}
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRecords"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useRecordsList from "./useRecordsList";
import awardStoreModule from "../../awardStoreModule";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {},
  data() {
    return {
      recordInfo: { id: 0, password: "", password_confirmation: "" },
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-record";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, awardStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchRecords,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRecordListTable,
      refetchData,
      ability,
    } = useRecordsList();

    return {
      // Sidebar
      fetchRecords,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRecordListTable,
      refetchData,
      ability,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.search-primary {
  width: 100%;
}
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
    