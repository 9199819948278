<template>
  <b-sidebar
    id="add-new-page-sidebar"
    :visible="isAddNewPageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-page-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">修改頁面</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="標題"
              rules="required"
            >
              <b-form-group label="標題">
                <b-form-input
                  type="text"
                  placeholder="輸入標題"
                  v-model="pageData.title"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="內容"
              rules="required"
            >
              <b-form-group label="內容">
                <div class="quill-editor">
                  <!-- 图片上传组件辅助-->
                  <el-upload class="avatar-uploader" :action="uploadUrl" :headers="headerObj" name="file" :show-file-list="false" :on-success="uploadSuccess">
                  </el-upload>
                  <!--富文本编辑器组件-->
                  <quill-editor v-model="pageData.content" :content="pageData.content" :options="editorOption" ref="QuillEditor">
                  </quill-editor>
                </div>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
                  #default="validationContext"
                  name="網址別名"
                  rules="required"
                >
                <b-form-group
                  class="required"
                  label="網址別名"
                  label-for="slug"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="slug"
                    v-model="pageData.slug"
                    @update="checkSlug"
                    placeholder="輸入網址別名"
                    disabled
                  />
                 <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="排序"
                  rules="required"
                >
                <b-form-group
                  class="required"
                  label="排序"
                  label-for="sort_order"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="sort_order"
                    v-model="pageData.sort_order"
                    placeholder="輸入排序"
                  />
                 <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                
    
            <b-form-group
              label="狀態"
              label-for="is_active"
              label-cols-md="3"
              class="mt-2"
            >
              <!-- <b-form-input id="is_active"  v-model="pageData.is_active" /> -->
              <b-form-checkbox
                :checked="pageData.is_active"
                name="is_active"
                switch
                inline
                v-model="pageData.is_active"
                value="1"
                style="margin-top: 5px"
              >
                {{ pageData.is_active ? "啟用" : "禁用" }}
              </b-form-checkbox>
            </b-form-group>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                保存
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BImg,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import pageStoreModule from "../pageStoreModule";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  directives: {
    Ripple,
  },
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BImg,
    BCardText,
    BOverlay,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
  },
  methods: {
    checkSlug(){
      this.checkSlug = this.checkSlug.replace(/^\s+|\s+$/gm,'')
    },
    uploadSuccess (res) {
      // 获取富文本组件实例
      //console.log({res})
      let quill = this.$refs.QuillEditor.quill
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, 'image', res.file_path)
        // 调整光标到最后
        quill.setSelection(length + 1)
      } else {
        // 提示信息，需引入Message
        this.$message.error('图片插入失败！')
      }
    },
    onSubmit() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let formData = new FormData();
          //formData.append('file', this.pageData.file)
          formData.append("id", this.pageData.id);
          formData.append("title", this.pageData.title);
          formData.append("content", this.pageData.content);
          formData.append("slug", this.pageData.slug);
          formData.append("sort_order", this.pageData.sort_order);

          formData.append("is_active", this.pageData.is_active ? 1 : 0);
          
          store
            .dispatch("page/addPage", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$emit("update:is-add-new-page-sidebar-active", false);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  model: {
    prop: "isAddNewPageSidebarActive",
    event: "update:is-add-new-page-sidebar-active",
  },
  props: {
    isAddNewPageSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const APP_FAQ_STORE_MODULE_NAME = "page";

    if (!store.hasModule(APP_FAQ_STORE_MODULE_NAME))
      store.registerModule(APP_FAQ_STORE_MODULE_NAME, pageStoreModule);

    const blankData = ref({
      id: 0,
      is_active: 1,
      title: "",
      content: "",
    });

    const pageData = ref(JSON.parse(JSON.stringify(blankData)));

    const resetpageData = () => {
      pageData.value = JSON.parse(JSON.stringify(blankData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpageData);

    watch(
      () => [props.id, props.isAddNewPageSidebarActive],
      () => {
        if (props.isAddNewPageSidebarActive) {
          pageData.value = JSON.parse(JSON.stringify(props.data));
        }
      }
    );

    const uploadUrl = process.env.VUE_APP_UPLOAD_URL

    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const editorOption = {
      modules: {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],                    
            [{ 'header': 1 }, { 'header': 2 }],               
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],    
            [{ 'script': 'sub' }, { 'script': 'super' }],    
            [{ 'indent': '-1' }, { 'indent': '+1' }],      
            [{ 'direction': 'rtl' }],                      
            [{ 'size': ['small', false, 'large', 'huge'] }], 
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }], 
            [{ 'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image']
          ],
          handlers: {
            image: (value) => {
              //console.log("image", value);
              if (value) {
               document.querySelector('.avatar-uploader input').click()
              } else {
                this.quill.format("image", false);
              }
            },
          },
        },
      },
    };

    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      pageData,
      editorOption,
      headerObj,
      uploadUrl,
    };
  },
};

</script>

<style>
.b-sidebar.b-sidebar-right {
  width: 50% !important;
}

.ql-editor {
  min-height: 350px;
}

@media screen and (max-width: 780px) {
  .b-sidebar.b-sidebar-right {
    width: 100% !important;
  }
}
</style>

