import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useClientsReceiptList(client_id) {
  if(!client_id) client_id = null;
  // Use toast
  const toast = useToast();

  const refReceiptListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "編號", sortable: true },
    { key: "payment_receipts", label: "收據文件", sortable: true },
    { key: "payment_documents", label: "其他文件", sortable: true },
    { key: "amount", label: "還款金額", sortable: true },
    { key: "payment_date", label: "還款日期", sortable: true },
    { key: "receive_method_id", label: "收款方式", sortable: true },
    { key: "receive_account_id", label: "收款帳號", sortable: true },
    { key: "receipt_number", label: "收據編號", sortable: true },
    { key: "receipt_date", label: "收據日期", sortable: true },
    { key: "remark", label: "備註", sortable: true },
    { key: "edit",  label: "修改"},
  ];
  const perPage = ref(10);
  const totalRows = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refReceiptListTable.value
      ? refReceiptListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    };
  });

  const refetchData = () => {
    refReceiptListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery],
    () => {
      refetchData();
    }
  );

  const fetchReceipts = (ctx, callback) => {
    store
      .dispatch("app-client/fetchReceipts", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        client_id: client_id
      })
      .then((response) => {
        const { receipts, total } = response.data;
        callback(receipts);
        totalRows.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching client list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };



  onMounted(() => {
  });

  return {
    fetchReceipts,
    tableColumns,
    perPage,
    currentPage,
    totalRows,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReceiptListTable,
    ability,
    refetchData,
  };
}
