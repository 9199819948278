<template>
  <div class="auth-wrapper auth-v2">

    <modal-login />
    
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
         <img class="d-lg-none" src="~@/assets/images/logo/logo-dark.png">
         <img class="d-none d-lg-block" src="~@/assets/images/logo/logo.png">
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center bg-login-image"
      >
        <div class="bg-login-image__effect__wrapper">
          <img src="~@/assets/images/pages/login-bg2.png"  class="bg-login-image__effect">
        </div>
        <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5" style="z-index:2">
          <b-img
            fluid
            class="left-image"
            :src="imgUrl"
            alt="Login V2"
          />
        </div> -->
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="6"
        class="d-flex align-items-center px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="7"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            X Data 資料庫搜尋系統
          </b-card-title>
          <b-card-text class="mt-3">
            安全·可靠·最齊
          </b-card-text>
          <b-card-text class="mt-2">
            提供一站式搜尋系統，專業分析，AI智能應用， 更準確及快速運作系統
          </b-card-text>

            <b-button
                class="mt-3 trigger-login-modal px-3"
                type="submit"
                variant="primary"
                @click="$bvModal.show('modal-login')"
              >
                立即登入
            </b-button>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import ModalLogin from "../modal/ModalLogin.vue";
import {
   BRow, BCol, BLink, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'


export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    VuexyLogo,
    ModalLogin
  },
  data() {
    return {
      imgUrl: require('@/assets/images/pages/login-v2.svg'),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.left-image{
  position: absolute;
  right:-15%;
}

</style>
