import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useClientsSubmissionList() {
  // Use toast
  const toast = useToast();

  const refClientSubmissionListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "ID", sortable: true },
    { key: "file_name", label: "檔案名稱", sortable: true },
    { key: "users.name", label: "用戶名稱" , sortable: true},
    { key: "successful", label: "成功數" , sortable: true},
    { key: "failed", label: "失敗數" , sortable: true},
    { key: "total_records", label: "總數" , sortable: true},
    { key: "created_at", label: "生成日期" },
    { key: "actions" , label: "下載"},
  ];
  const perPage = ref(10);
  const totalClients = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const clientsSubmissionList = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refClientSubmissionListTable.value
      ? refClientSubmissionListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClients.value,
    };
  });

  const refetchData = () => {
    refClientSubmissionListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery],
    () => {
      refetchData();
    }
  );

  const fetchClientsSubmission = (ctx, callback) => {
    store
      .dispatch("app-client/submissionRecord", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const { client_submissions, total } = response.data;
        callback(client_submissions);
        clientsSubmissionList.value = client_submissions
        totalClients.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "獲取貸款清單時出錯",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchClientsSubmission,
    tableColumns,
    perPage,
    currentPage,
    totalClients,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refClientSubmissionListTable,
    ability,
    clientsSubmissionList,
    refetchData,
  };
}
