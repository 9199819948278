<template>
  <div class="p-2 bgCommon">
    <modal-password-edit :supplier-info="supplierInfo" />
    <modal-add-coin :supplier-coin="supplierCoin" @refetch-data="refetchData" />
    <modal-coin-record :supplier-data="supplierData" />
    <modal-sub-account
      :ability="ability"
      :account-id="accountId"
      :sub-account="subAccount"
      @reset-password="triggerResetPasswordModal"
      @remove-account="removeItem"
    />
    <div
      class="d-flex flex-wrap mt-2 mb-1 align-items-center justify-content-between"
    >
      <div class="d-flex flex-wrap align-items-center mobile-w100">
        <div>
          <!-- <feather-icon size="16" icon="SearchIcon"></feather-icon> -->
          <span class="title-size text-nowrap">財務公司</span>
        </div>
        <div class="form-col-select ml-2">
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block"
          />
          <feather-icon size="18" icon="ChevronDownIcon" />
        </div>
        <div class="search-primary ml-0 ml-sm-1 mobile-w100 mt-1 mt-sm-0">
          <feather-icon size="16" icon="SearchIcon" />
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="搜尋財務公司"
          />
        </div>
        <div class="search-primary ml-0 ml-sm-1 mobile-w100 mt-1 mt-sm-0">
          <v-select
            v-model="companyFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="companyOptions"
            label="name"
            :clearable="false"
            :reduce="(option) => option.id"
            placeholder="選擇公司／街客"
          />
        </div>
      </div>
      <b-button
        v-if="ability.can('create', 'supplier')"
        variant="primary"
        class="mobile-w100 mt-1 mt-sm-0"
        :to="{ name: 'suppliers-create' }"
      >
        <span class="text-nowrap">新增財務公司</span>
      </b-button>
    </div>
    <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
    <b-card no-body class="">
      <b-card-body class="p-2">
        <b-table
          ref="refSupplierListTable"
          class="position-relative"
          :items="fetchSuppliers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="未找到匹配的記錄"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(coin_records)="data">
            <a
              class="ml-1"
              href="javascript:void(0)"
              @click="triggerShowCoinRecordModal(data.item.id)"
            >
              <font-awesome-icon icon="fas fa-file-invoice-dollar" />
            </a>
          </template>
          <!-- Column: create time -->
          <!-- <template #cell(created_at)="data">
          {{data.item.created_at.slice(0,10)}}
        </template> -->

          <template #cell(childs)="data">
            <!-- <b-button v-if="data.item.childs.length > 0" size="sm" @click="data.toggleDetails" class="mr-2 line-fix text-nowrap">
              {{ data.detailsShowing ? "隱藏" : "顯示" }}帳戶
            </b-button> -->
            <b-button
              v-if="data.item.childs.length > 0"
              size="sm"
              @click="triggerSubAccountModal(data.item.childs, data.item.id)"
              >顯示帳戶</b-button
            >
            <div v-else>沒有旗下帳戶</div>
          </template>

          <template #cell(uploadrate)="data">
            <span v-if="data.item.client_count">{{
              (
                (data.item.valid_client_count * 100) /
                data.item.client_count
              ).toFixed(2) + "%"
            }}</span>
          </template>

          <!-- <template #row-details="data">

          <b-card >
            <div>
              <b-badge class="m-1" v-for="(acc,index) of data.item.childs" :key="acc.created_at">
                  <span class="align-middle ml-50">{{acc.contact_name}}</span>
                  <span class="align-middle ml-50">({{acc.email}})</span> 
                  <b-dropdown 
                    class="action-trigger-btn sub_action"
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <div class="d-flex pl-50 border-left whiteBorder">
                          <feather-icon
                            icon="EyeIcon"
                            size="16"
                            class="align-middle"
                          />
                        </div>
                      </template>

                      <b-dropdown-item
                        v-if="ability.can('update', 'supplier')"
                        :to="{ name: 'suppliers-edit', params: { id: data.item.id } }"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">修改資料</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="ability.can('update','supplier')" @click="triggerResetPasswordModal(acc)">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">更改密碼</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="ability.can('delete','supplier')" @click="removeItem(acc)">
                        <feather-icon icon="Trash2Icon" />
                        <span class="align-middle ml-50">刪除帳戶</span>
                      </b-dropdown-item>
                  </b-dropdown>
              </b-badge>
            </div>
          </b-card>

        </template> -->
          <!-- Column: is_active -->
          <template #cell(is_active)="data">
            <feather-icon
              v-if="data.item.is_active"
              icon="CheckIcon"
              size="18"
              class="mr-50 text-success"
            />
          </template>

          <!-- Column: Actions -->
          <!-- -->
          <template #cell(actions)="data">
            <b-dropdown
              :class="`action-trigger-btn ${
                suppliersList.length <= 3 ? 'hor' : 'ver'
              }`"
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content class="">
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                class="coins_btn"
                v-if="ability.can('update', 'supplier')"
                @click="triggerAddCoinModal(data.item)"
              >
                <span class="svg_circle">
                  <feather-icon icon="DollarSignIcon" />
                </span>
                <span class="align-middle ml-50">充值</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item
            :to="{ name: 'suppliers-detail', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">詳細資料</span>
            </b-dropdown-item> -->

              <b-dropdown-item
                v-if="ability.can('update', 'supplier')"
                :to="{ name: 'suppliers-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">修改資料</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="ability.can('update', 'supplier')"
                @click="triggerResetPasswordModal(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">更改密碼</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="ability.can('delete', 'supplier')"
                @click="removeItem(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">刪除帳戶</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalSuppliers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
    </b-overlay>
  </div>
</template>
    
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useSuppliersList from "./useSuppliersList";
import supplierStoreModule from "../supplierStoreModule";
import ModalPasswordEdit from "../modal/ModalPasswordEdit.vue";
import ModalAddCoin from "../modal/ModalAddCoin.vue";
import ModalCoinRecord from "../modal/ModalCoinRecord.vue";
import ModalSubAccount from "../modal/ModalSubAccount.vue";

export default {
  components: {
    ModalPasswordEdit,
    ModalAddCoin,
    ModalCoinRecord,
    ModalSubAccount,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      supplierInfo: { id: 0, password: "", password_confirmation: "" },
      supplierCoin: {},
      supplierData: {},
      subAccount: [],
      accountId: 0,
      loading: false
    };
  },
  methods: {
    triggerSubAccountModal(item, id) {
      this.subAccount = item;
      this.accountId = id;
      this.$bvModal.show("modal-sub-account");
    },
    triggerResetPasswordModal(item) {
      this.supplierInfo.id = item.id;
      this.supplierInfo.name = item.contact_name;
      this.supplierInfo.password = "";
      this.supplierInfo.password_confirmation = "";
      this.$bvModal.show("modal-reset-supplier-password");
    },
    triggerAddCoinModal(item) {
      this.supplierCoin.supplier_id = item.id;
      this.$bvModal.show("modal-supplier-add-coin");
    },
    triggerShowCoinRecordModal(id) {
      this.loading = true
      this.$store
        .dispatch("app-supplier/fetchSupplierCoinRecords", id )
        .then((response) => {
          this.loading = false
          this.supplierData = response.data.coin_records
          // this.$swal({
          //   text: response.data.message,
          //   icon: "success",
          //   confirmButtonText: "確定",
          //   customClass: {
          //     confirmButton: "btn btn-primary",
          //   },
          //   showClass: {
          //     popup: "animate__animated animate__bounceIn",
          //   },
          //   buttonsStyling: false,
          // });
          this.$bvModal.show("modal-supplier-coin-record");
        });
    },
    removeItem(item, isSub) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的行動是最終的，您將無法檢索這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-supplier/deleteSupplierAccount", { id: item.id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              if (isSub) {
                this.subAccount = this.subAccount.filter(
                  (item2) => item2.id != item.id
                );
              }
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-supplier";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, supplierStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchSuppliers,
      tableColumns,
      perPage,
      currentPage,
      totalSuppliers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSupplierListTable,
      refetchData,
      suppliersList,
      ability,
      companyOptions,
      companyFilter,
    } = useSuppliersList();

    return {
      // Sidebar
      fetchSuppliers,
      tableColumns,
      perPage,
      currentPage,
      totalSuppliers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSupplierListTable,
      refetchData,
      suppliersList,
      ability,
      companyOptions,
      companyFilter,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
.coins_btn {
  display: flex;
  .dropdown-item {
    display: flex;
    align-items: center;
    width: 100%;
    &:hover {
      .svg_circle {
        border-color: $primary;
      }
    }
    .svg_circle {
      border: 1px solid #6e6b7b;
      border-radius: 100%;
      width: 18px;
      height: 18px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}
.app-timeline {
  max-height: 550px;
  overflow: auto;
  padding-left: 7px !important;
}
</style>
    