<template>
<b-modal
    id="modal-supplier-coin-record"
    ok-title="submit"
    cancel-variant="outline-secondary"
    scrollable
      centered
    title="交易記錄"
    size="lg"
    hide-footer
  >
    <b-card class="pt-2">
        <app-timeline>
          <app-timeline-item
            v-for="item in supplierData"
            :key="item.id"
          >
            <div class="d-flex record justify-content-between">
              <div class="info">
                <h4 v-if="item.is_system==0">{{item.creator.name}}</h4>
                <div class="d-flex"><b>金額： </b><span>{{item.amount}}</span></div>
                <div class="d-flex"><b>備註： </b><span class="remark">{{item.remark}}</span></div>
              </div>
              <small class="text-muted mr-1 text-right"><pre v-html="getCorrectDateTime(item.created_at)"></pre></small>
            </div>
          </app-timeline-item>
        </app-timeline>
    </b-card>
</b-modal>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
    BModal,
    BCard,
    BCardBody,
} from "bootstrap-vue"

export default {
    components:{
        BModal,
        BCard,
        BCardBody,
        AppTimeline,
        AppTimelineItem,    
    },
    data(){
      return{
      }
    },
    methods:{
        hide()
        {
            this.$bvModal.hide("modal-supplier-coin-record")
        },
    },
    props: {
        supplierData:{
            type: Object,
            required: true,
        }
    }
    
}
</script>
<style lang="scss" scoped>
.record{
  b{
    width:50px;
  }
}
.info{
  width: calc(100% - 75px);
}
 .remark{
  width:65%
 }
</style>