<template>
  <b-modal
    id="modal-sub-account"
    centered
    title="旗下帳戶"
    size="lg"
    ok-only
    ok-variant="secondary"
    ok-title="Cancel"
  >
    <b-card>
      <div>
        <b-badge
          :class="`m-1 ${acc.is_active ? '' : 'bgColorRed'}`"
          v-for="acc of subAccount"
          :key="acc.id + acc.created_at"
        >
          <span class="align-middle ml-50">{{ acc.contact_name }}</span>
          <span class="align-middle ml-50">({{ acc.email }})</span>
          <b-dropdown
            class="action-trigger-btn sub_action"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <div class="d-flex pl-50 border-left whiteBorder">
                <feather-icon icon="EyeIcon" size="16" class="align-middle" />
              </div>
            </template>

            <b-dropdown-item
              v-if="ability.can('update', 'supplier')"
              :to="{ name: 'suppliers-edit', params: { id: accountId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改資料</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="ability.can('update', 'supplier')"
              @click="$emit('reset-password', acc)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">更改密碼</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="ability.can('delete', 'supplier')"
              @click="$emit('remove-account', acc, true)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">刪除帳戶</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-badge>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  props: {
    subAccount: {
      type: Array,
      required: true,
    },
    accountId:{
      type: Number,
      required: true,
    },
    ability: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-sub-account", "#btnShow");
    },
  },
};
</script>

<style></style>
