import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/supplier/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSupplier(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/supplier/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addSupplier(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/supplier", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteSupplierAccount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/supplier", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    addSupplierCoin(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/supplier/coin", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSuppliersSearchCount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/supplier/searchcount", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSupplierCoinRecords(ctx, val ) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/supplier/coin/${val}`,)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchApproves(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/supplier_change_request/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteApprove(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/supplier_change_request/`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateApprove(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/supplier_change_request", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportSearchRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/supplier/searchcount/export", { params: queryParams, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  }
};
