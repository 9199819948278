export default [
    {
        path: "/clients",
        name: "clients-list",
        component: () => import("@/views/client/clients-list/ClientsList.vue"),
        meta:{
            action: "read",
            resource: "client",
        }
    },
    {
        path: "/clients/create",
        name: "clients-create",
        component: () => import("@/views/client/clients-edit/ClientsEdit.vue"),
        meta:{
            action:"create",
            resource:"client"
        }
    },
    {
        path: "/clients/multi/create",
        name: "clients-multi-create",
        component: () => import("@/views/client/clients-multi/ClientMultiCreate.vue"),
        meta:{
            action:"create",
            resource:"client"
        }
    },
    {
        path: "/clients/multi/edit/home-members",
        name: "clients-multi-home-members",
        component: () => import("@/views/client/clients-multi/ClientMultiEditHomeMember.vue"),
        meta:{
            action:"update",
            resource:"client"
        }
    },
    {
        path: "/clients/edit/:id",
        name: "clients-edit",
        component: () => import("@/views/client/clients-edit/ClientsEdit.vue"),
        meta:{
            action:"update",
            resource:"client"
        }
    },
]