<template>
  <div class="p-2 bgCommon">
    <!--
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />
    -->
    <!-- Table Container Card -->
    <user-password-edit :user-info="userInfo" />

    <div class="mb-1">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div>
            <span class="title-size text-nowrap">員工資料</span>
          </div>
          <!-- <label>Show</label> -->
          <!-- <h3 class="font-weight-bolder text-nowrap mb-0">員工資料</h3> -->
          <div class="form-col-select mx-50">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <!-- Search -->
          <div class="search-primary mt-1 mt-sm-0">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="搜尋"
            />
          </div>
          <!-- <label>entries</label> -->
        </b-col>

        <b-col cols="12" md="6">
          <div
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              variant="primary"
              v-if="ability.can('create', 'user')"
              @click="isAddNewUserSidebarActive = true"
              :to="{ name: 'users-create' }"
            >
              <span class="text-nowrap">添加用戶</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-card no-body class="mb-0">
      <b-card-body class="p-2">
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="未找到匹配的記錄"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: User -->
          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.fullName)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                  :to="{
                    name: 'apps-users-view',
                    params: { id: data.item.id },
                  }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.fullName }}
              </b-link>
              <small class="text-muted">@{{ data.item.username }}</small>
            </b-media>
          </template>
          <!-- Column: roles  -->
          <template #cell(role)="data">
            <div v-if="data.item.role">
              <span v-for="item in data.item.role" :key="item.id">{{
                item.name
              }}</span>
            </div>
          </template>
          <!--Column: Position -->
          <template #cell(position_id)="data">
            <div v-if="data.item.position">
              {{ data.item.position.position }}
            </div>
          </template>

          <!--Column: Team Lewader -->

          <template #cell(is_teamleader)="data">
            <b-badge
              pill
              v-if="data.item.is_teamleader"
              :variant="`light-primary`"
              class="text-capitalize"
            >
              Team Leader
            </b-badge>
          </template>
          <!-- Column: Team -->
          <template #cell(team_id)="data">
            <b-badge
              v-for="team in data.item.teams"
              :key="team.id"
              class="text-capitalize"
            >
              {{ team.team_name }}
            </b-badge>
          </template>

          <template #cell(is_active)="data">
            <feather-icon
              v-if="data.item.is_active"
              icon="CheckIcon"
              size="18"
              class="mr-50 text-success"
            />
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              class="action-trigger-btn"
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content class="">
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- <b-dropdown-item :to="{ name: 'users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>
 -->
              <b-dropdown-item
                v-if="ability.can('update', 'user')"
                :to="{ name: 'users-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">修改資料</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="ability.can('update', 'user')"
                @click="triggerResetPasswordModal(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">重設密碼</span>
              </b-dropdown-item>
              <!--
            <b-dropdown-item v-if="ability.can('delete','users')">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            -->
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useUsersList from "./useUsersList";
import userStoreModule from "../userStoreModule";
import UserListAddNew from "./UserListAddNew.vue";
import UserPasswordEdit from "./UserPasswordEdit.vue";

export default {
  components: {
    UserListAddNew,
    UserPasswordEdit,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    triggerResetPasswordModal(item) {
      this.userInfo.id = item.id;
      this.userInfo.name = item.name;
      this.userInfo.password = "";
      this.userInfo.password_confirmation = "";
      this.$bvModal.show("modal-reset-user-password");
    },
  },
  data() {
    return {
      userInfo: { id: 0, password: "", password_confirmation: "" },
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      ability,
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      ability,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
