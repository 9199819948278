<template>
  <div class="p-2 bgCommon">

    <modal-image-viewer
      id="1"
      :image-list="previewImage"
      :selected_file="0"
    ></modal-image-viewer>

    <modal-pdf id="2" :pdfUrl="pdfUrl"></modal-pdf>

    <div
      class="d-flex flex-wrap flex-sm-nowrap mt-2 mb-1 align-items-center justify-content-between"
    >
      <!-- Table Top -->

      <div class="d-flex flex-wrap flex-sm-nowrap align-items-center">
        <div>
          <!-- <feather-icon size="16" icon="SearchIcon"></feather-icon> -->
          <span class="title-size text-nowrap">客戶</span>
        </div>

        <div class="form-col-select ml-2">
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block"
          />
          <feather-icon size="18" icon="ChevronDownIcon" />
        </div>
        <div
          class="d-flex align-items-center mobile-w100 ml-0 ml-sm-1 mt-1 mt-sm-0"
        >
          <div class="search-primary">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="搜尋收據"
            />
          </div>
        </div>
      </div>
    </div>
    <b-card class="card-statistics p-1 p-sm-0">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
      <b-table
        ref="refReceiptListTable"
        class="position-relative sticky-rtl-tb"
        :items="fetchReceipts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="未找到匹配的記錄"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(receive_method)="data">
          {{
            data.item.receive_method && data.item.receive_method.name
          }}
        </template>
        <template #cell(receive_account_id)="data">
          {{
            data.item.receive_account && data.item.receive_account.name
          }}
        </template>

        <template #cell(payment_receipts)="data">
          <ul class="table-image-group">
            <li class="">
              <!-- :key="doc.id"
                    v-for="doc of data.item.payment_documents" -->
              <a
                href="javascript:void(0)"
                @click="tirggerViewer(data.item.payment_receipts)"
                v-if="
                  data.item.payment_receipts &&
                  data.item.payment_receipts.length > 0
                "
              >
                <embed
                  v-if="
                    data.item.payment_receipts[0].original_image_path.indexOf(
                      'pdf'
                    ) > -1
                  "
                  class="item"
                  :src="data.item.payment_receipts[0].image_path"
                  type="application/pdf"
                />
                <img
                  v-else
                  class="item"
                  :src="data.item.payment_receipts[0].image_path"
                  alt="image slot"
                />
              </a>
              <template v-else> - - </template>
            </li>
          </ul>
        </template>

        <template #cell(payment_documents)="data">
          <ul class="table-image-group">
            <li class="">
              <!-- :key="doc.id"
                    v-for="doc of data.item.payment_documents" -->
              <a
                href="javascript:void(0)"
                @click="tirggerViewer(data.item.payment_documents)"
                v-if="
                  data.item.payment_documents &&
                  data.item.payment_documents.length > 0
                "
              >
                <embed
                  v-if="
                    data.item.payment_documents[0].original_image_path.indexOf(
                      'pdf'
                    ) > -1
                  "
                  class="item"
                  :src="data.item.payment_documents[0].image_path"
                  type="application/pdf"
                />
                <img
                  v-else
                  class="item"
                  :src="data.item.payment_documents[0].image_path"
                  alt="image slot"
                />
              </a>
              <template v-else> - - </template>
            </li>
          </ul>
        </template>

        <template #cell(amount)="data">
          {{ Math.round(Number(data.item.amount)) }}
        </template>

        <template #cell(remark)="data">
          <span>{{
            data.item.remark && data.item.remark !== "undefined"
              ? data.item.remark
              : "- -"
          }}</span>
        </template>

        <template
          #cell(edit)="data"
        >
          <div class="d-flex align-items-center justify-content-center">
            <a href="javascript:void(0)" @click="exportReceipt(data.item.id)">
              <feather-icon icon="DownloadIcon" size="18" />
            </a>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
              {{ dataMeta.to }}個記錄</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      </b-overlay>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useClientsReceiptList from "./useClientsReceiptList";
import clientStoreModule from "../clientStoreModule";
import ModalImageViewer from "@/layouts/components/file/ModalImageViewer.vue";
import ModalPdf from "@/layouts/components/file/ModalPdf.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalPdf,
    ModalImageViewer
  },
  methods: {
    exportReceipt(id){
      this.loading = true;
      this.$store
        .dispatch("app-client/exportReceipts", { id })
        .then((response) => {
          this.loading = false;
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf2");
        })
        .catch((error) => {
          this.loading = false;
          console.log({ error });
        });
    },
    tirggerViewer(files) {
      this.previewImage = files.map((ele) => {
        return {
          id: ele.id,
          src: ele.image_path,
          type:
            ele.original_image_path.indexOf("pdf") > -1
              ? "application/pdf"
              : "image/jpeg",
          is_exist: 1,
        };
      });
      this.$bvModal.show(`modal-image-viewer1`);
    },
  },
  props:{
    client_id:{}
  },
  data() {
    return {
      previewImage:null,
      loading:false,
      pdfUrl:null
    };
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-client";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const previewImage = ref([]);

    const {
      fetchReceipts,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReceiptListTable,
      refetchData,
      ability,
    } = useClientsReceiptList(props.client_id);

    return {
      // Sidebar
      fetchReceipts,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReceiptListTable,
      refetchData,
      ability,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search-primary {
  // width: calc(100% - 700px);
  width: calc(100% - 500px);
}
.top-button-list {
  // width: 500px;
  margin: 0;
  .btn {
    width: 150px;
    margin: 0 auto;
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .search-primary {
    width: 100%;
  }
  .top-button-list {
    margin-left: auto;
    margin-right: 20px;
    .btn {
      width: 150px;
      margin: 0 auto;
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .search-primary {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .top-button-list {
    margin: 0;
    width: 100%;
  }
}
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
    