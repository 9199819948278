<template>
    <div>
        <!-- User Info: Input Fields -->
        <b-form>
            <b-row>
                <b-col cols="12" md="12">
                    <div class="d-flex mb-1">
                        <feather-icon icon="UserIcon" size="19" />
                        <h4 class="mb-0 ml-50">職位：{{roleData.name}}</h4>
                    </div>
                    <b-form-group label="職位名稱" label-for="name">
                        <b-form-input id="name" v-model="roleData.name" autofocus trim placeholder="輸入職位名稱" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
        <!-- Role Permission -->
        <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                    <feather-icon icon="LockIcon" size="18" />
                    <span class="align-middle ml-50">職位權限</span>
                </b-card-title>
            </b-card-header>
            <b-table striped responsive class="mb-0" :items="permissionData">
                <template #cell(module)="data">
                    {{ getRoleName(data.value) }}
                </template>

                <template #cell()="data">
                    <b-form-checkbox v-model="rolePermission" :value="data.item.module+'.'+data.field.key"
                        :checked="data.value" />
                </template>
            </b-table>
        </b-card>
        <!-- Action Buttons -->
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="handleSubmit">
            提交
        </b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="hide" variant="outline-danger"
        class="mb-1 mb-sm-0 mr-sm-1 mobile-w100">
            取消
        </b-button>
        <b-button variant="outline-secondary" type="reset" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleReset">
            重置
        </b-button>
    </div>
</template>

<script>
import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, onMounted, watch } from "@vue/composition-api";
import useRolesList from "../roles-list/useRolesList";
import store from "@/store";

export default {
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        vSelect,
    },
    directives: {
        Ripple,
    },
    props: {
        roleData: {
            type: Object,
            required: true,
        },
        permissionData: {
            type: Array,
            required: true
        },
    },
    methods: {
        getRoleName(name){
            return (this.roleName.find(role => role.name == name) && this.roleName.find(role => role.name == name).value) || name
        },
        handleSubmit() {
            const data = {
                id: this.roleData.id,
                name: this.roleData.name,
                permission: this.rolePermission,
            }

            this.$swal({
                title: "你確定嗎?",
                showCancelButton: true,
                confirmButtonText: "提交",
                cancelButtonText: "取消",
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    store.dispatch("app-role/updateRole", data)
                        .then((response) => {
                            this.$swal({
                                text: response.data.message,
                                icon: 'success',
                                confirmButtonText: "確定",
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                showClass: {
                                    popup: 'animate__animated animate__bounceIn',
                                },
                                buttonsStyling: false,
                            })
                            this.$emit('refetch-data')
                        }).catch((error) => {
                            this.$swal({
                                text: JSON.stringify(error.response.data.message),
                                icon: 'error',
                                confirmButtonText: "確定",
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                showClass: {
                                    popup: 'animate__animated animate__bounceIn',
                                },
                                buttonsStyling: false,
                            })
                        })
                }
            })

        },
        handleReset() {
            this.updateRolePermission()
        },
        hide() {
            this.$router.replace("/roles/list");
        }
    },
    setup(props) {

        const rolePermission = ref([])

        const roleName = ref([
            {name: 'user', value: '員工'},
            {name: 'client', value: '客戶'},
            {name: 'supplier', value: '財務公司'},
            {name: 'loan', value: '借貸記錄'},
            {name: 'role', value: '員工職位及權限'},
            {name: 'page', value: '頁面'},
            {name: 'payment', value: '充值記錄'},
            {name: 'drawsetting', value: '獎品設定'},
            {name: 'draw', value: '中獎記錄'},
        ])

        const updateRolePermission = () => {
            rolePermission.value = []
            props.permissionData.forEach(rp => {
                if (rp.admin) rolePermission.value.push(rp.module + '.admin')
                if (rp.create) rolePermission.value.push(rp.module + '.create')
                if (rp.read) rolePermission.value.push(rp.module + '.read')
                if (rp.update) rolePermission.value.push(rp.module + '.update')
                if (rp.delete) rolePermission.value.push(rp.module + '.delete')
            })
        }

        onMounted(() => {
            updateRolePermission()
        })

        return {
            rolePermission,
            updateRolePermission,
            roleName,
        }

    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
