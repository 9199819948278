<template>
  <component :is="clientData === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="clientData === undefined">
      <h4 class="alert-heading">Error fetching client data</h4>
      <div class="alert-body">
        找不到具有此客戶ID 的客戶。請查看
        <b-link class="alert-link" :to="{ name: 'clients-list' }">
          客戶名單
        </b-link>
      </div>
    </b-alert>

    <b-tabs v-if="clientData" class="tabs-primary">
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <div class="text_circle_icon">A</div>
          <span class="d-none d-sm-inline-flex">個人資料</span>
        </template>

        <client-edit-tab-information
          :client-data="clientData"
          :supplier-options="loanSupplierOptions"
          :loading="loading"
          :gender-options="genderOptions"
          @refetch-data="refetchData"
          class="p-2"
        />
      </b-tab>
      <template v-if="clientData.id != 0">
        <b-tab>
          <template #title>
            <div class="text_circle_icon">B</div>
            <span class="d-none d-sm-inline-flex">家庭成員資料</span>
          </template>

          <client-edit-home-member
            :client-data="clientData"
            @refetch-data="refetchData"
            class="p-2"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <div class="text_circle_icon">C</div>
            <span class="d-none d-sm-inline-flex">個人財務狀況</span>
          </template>

          <client-edit-finance-information
            :client-data="clientData"
            :loan-supplier-options="loanSupplierOptions"
            :admin-status-options="adminStatusOptions"
            :loan-status-options="loanStatusOptions"
            @refetch-data="refetchData"
            class="p-2"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <div class="text_circle_icon">D</div>
            <span class="d-none d-sm-inline-flex">拒絕記錄</span>
          </template>

            <!-- :search-record-id="clientData.id"
            :isEdit="isRejectEdit" 
            :rejectLogs="rejectLogs" -->
          <client-edit-reject-record
            :rejectData="reject_records"
            :supplier-options="loanSupplierOptions"
            @refetch-data="refetchData" class="p-2"/>
        </b-tab>
        
        <b-tab>
          <template #title>
            <div class="text_circle_icon">E</div>
            <span class="d-none d-sm-inline-flex">帳務記錄</span>
          </template>

          <client-edit-account-record
            :accountData="account_records"
            :supplier-options="loanSupplierOptions"
            @refetch-data="refetchData" class="p-2"/>
        </b-tab>
        <b-tab v-if="clientData && clientData.id_number ">
          <template #title>
            <div class="text_circle_icon">F</div>
            <span class="d-none d-sm-inline-flex">合約記錄</span>
          </template>

          <contract-list :id_number="clientData.id_number" class="p-2"/>
        </b-tab>

        <b-tab v-if="clientData && clientData.id">
          <template #title>
            <div class="text_circle_icon">G</div>
            <span class="d-none d-sm-inline-flex">收據記錄</span>
          </template>
          <clients-receipt-list :client_id="clientData.id" class="p-2"/>
        </b-tab>
      </template>
    </b-tabs>
  </component>
</template>

<script>
import { BCard, BAlert, BLink, BTabs, BTab } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import clientStoreModule from "../clientStoreModule";
import ClientEditTabInformation from "./ClientEditTabInformation.vue";
import ClientEditHomeMember from "./ClientEditHomeMember.vue";
import ClientEditFinanceInformation from "./ClientEditFinanceInformation.vue";
import ClientEditRejectRecord from "./ClientEditRejectRecord.vue";
import ClientEditAccountRecord from "./ClientEditAccountRecord.vue";
import ContractList from "@/views/contract/contracts-list/ContractsList.vue";
import ClientsReceiptList from  "./ClientsReceiptList.vue";


export default {
  components: {
    ClientEditTabInformation,
    ClientEditFinanceInformation,
    ClientEditHomeMember,
    ClientEditRejectRecord,
    ClientEditAccountRecord,
    ClientsReceiptList,
    ContractList,
    BCard,
    BAlert,
    BTabs,
    BTab,
    BLink,
  },
  setup() {
    const clientData = ref({});
    const reject_records = ref([])
    const account_records = ref([])

    const CLIENT_APP_STORE_MODULE_NAME = "app-client";

    const client = { id: 0 };
    const loading = ref(false);

    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      loading.value = true;
      if (router.currentRoute.name == "clients-create") {
        clientData.value = client;
        loading.value = false;
      } else {
        store
          .dispatch("app-client/fetchClient", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            clientData.value = response.data.client;

            if(clientData.value.reject_records)
              reject_records.value = clientData.value.reject_records
            

            if(clientData.value.account_records)
              account_records.value = clientData.value.account_records

            loading.value = false;
          })
          .catch((error) => {
            console.log("error when fetching client", error);
            loading.value = false;
            if (error.response.status === 404) {
              clientData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    const adminStatusOptions = ref([]);
    const loanStatusOptions = ref([]);
    const loanSupplierOptions = ref([]);
    const genderOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          admin_status: true,
          loan_status: true,
          supplier: true,
          gender: true,
        })
        .then((response) => {
          adminStatusOptions.value = response.data.admin_statuses;
          loanStatusOptions.value = response.data.loan_statuses;
          loanSupplierOptions.value = response.data.suppliers;
          genderOptions.value = response.data.genders;
        });
    };

    return {
      clientData,
      reject_records,
      account_records,
      adminStatusOptions,
      loanStatusOptions,
      loanSupplierOptions,
      genderOptions,
      refetchData,
      refetchOption,
      loading,
    };
  },
};
</script>
