export default [

      {
        path: "/roles/list",
        name: "roles-list",
        component: () => import("@/views/role/roles-list/RolesList.vue"),
        meta: {
          action: "read",
          resource: "user",
        },
      },
      {
        path: "/roles/permission/edit/:id",
        name: "roles-permission-edit",
        component: () => import("@/views/role/roles-edit/RolesPermissionEdit.vue"),
        meta: {
          action: "read",
          resource: "user",
        },
      },
];
