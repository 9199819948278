export default [
    {
        path: "/payment/record-list",
        name: "payment-record-list",
        component: () => import("@/views/payment/Records-list/RecordsList.vue"),
        meta:{
            action: "read",
            resource: "Auth",
        }
    },
]