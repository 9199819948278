export default [
    {
        path: "/suppliers",
        name: "suppliers-list",
        component: () => import("@/views/supplier/suppliers-list/SuppliersList.vue"),
        meta:{
            action: "read",
            resource: "supplier",
        }
    },
    {
        path: "/suppliers/searchcount",
        name: "suppliers-searchcount-list",
        component: () => import("@/views/supplier/suppliers-search-count-list/SuppliersSearchCountList.vue"),
        meta:{
            action: "read",
            resource: "supplier",
        }
    },
    {
        path: "/suppliers/create",
        name: "suppliers-create",
        component: () => import("@/views/supplier/suppliers-edit/SuppliersEdit.vue"),
        meta:{
            action:"create",
            resource:"supplier"
        }
    },
    {
        path: "/suppliers/edit/:id",
        name: "suppliers-edit",
        component: () => import("@/views/supplier/suppliers-edit/SuppliersEdit.vue"),
        meta:{
            action:"update",
            resource:"supplier"
        }
    },
    {
        path: "/suppliers/approve/list",
        name: "approves-list",
        component: () => import("@/views/supplier/approves-list/ApprovesList.vue"),
        meta:{
            action:"read",
            resource:"supplier"
        }
    },
]