<template>
  <!-- modal Add Coin-->
  <validation-observer ref="addCoinForm" #default="{ handleSubmit }">
    <b-modal
      id="modal-supplier-add-coin"
      hide-footer
      cancel-variant="outline-secondary"
      centered
      title="充值"
      @ok="handleAdd"
      :ok-disabled="false"
    >
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-2" @submit.prevent="handleSubmit(handleAdd)" ref="addCoinForm">
          <div class="px-2">
            <validation-provider
              #default="{ errors }"
              name="充值金額"
              vid="coin_amount"
              rules="required"
            >
              <b-form-group class="required" label="充值金額：">
                <b-form-input
                  id="coins_amount"
                  type="number"
                  placeholder="輸入充值金額"
                  v-model="supplierCoin.amount"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="備註"
              vid="coin_remark"
              rules="required"
            >
              <b-form-group class="required" label="備註：">
                <b-form-input
                  v-model="supplierCoin.remark"
                  placeholder="輸入備註"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="d-flex flex-wrap m-2 justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
              style="line-height: 1.1;"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hideModal"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import zh_CN from 'vee-validate/dist/locale/zh_CN.json';
localize('zh_cn',zh_CN)
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      loading: false,
    };
  },
  props: {
    supplierCoin: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-supplier-add-coin", "#btnShow");
    },
    handleAdd() {
      this.loading = true;
      store
      .dispatch("app-supplier/addSupplierCoin", this.supplierCoin)
        .then((response) => {
          this.loading = false;
          this.$bvModal.hide("modal-supplier-add-coin");
          this.$swal({
            text: response.data.message,
            icon: "success",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.$emit("refetch-data");
          this.supplierCoin.amount = ""
          this.supplierCoin.remark = ""
        })
        .catch((error) => {
          this.loading = false;
          const message = error.response.data.message;
          var showMessage = "";
          var showMessage = message;
          if (typeof showMessage === "object") {
            showMessage = "";
            for (var key in message) {
              if (message.hasOwnProperty(key)) {
                showMessage += message[key] + "\n";
              }
            }
          }
          this.$swal({
            text: showMessage,
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>

<style></style>
