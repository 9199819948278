export default [
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/user/users-list/UsersList.vue'),
    meta:{
      action: 'read',
      resource: 'user',
    }
  },
  {
    path: '/users/view/:id',
    name: 'users-view',
    component: () => import('@/views/user/users-view/UsersView.vue'),
    meta:{
      action: 'read',
      resource: 'user',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    meta:{
      action: 'update',
      resource: 'user'
    }
  },
  { 
    path:'/users/create',
    name: 'users-create',
    component:() => import('@/views/user/users-edit/UsersEdit.vue'),
    meta:{
      action: 'create',
      resource: 'user'
    }

  },
]