import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLoans(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/loan/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLoan(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/loan/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editLoan(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/loan/single", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addLoan(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/loan", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClientLoan(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/loan", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportLoanTemplateData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/loan/export", {responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getContractEndDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/loan/getContractEndDate", { params: data})
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    submissionRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/loan/submissionRecord", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportSubmissionData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/loan/loan_submission/export", { params: queryParams, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    checkImportLoanData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/loan/validateSubmissionImportData", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },




    exportSearchTemplateData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_search_record/template/export", {responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    submissionSearchRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_search_record/submissionRecord", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportSubmissionSearchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_search_record/result/export", { params: queryParams, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    checkImportSearchData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client_search_record/validateSubmissionImportData", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },





    exportRejectTemplateData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_reject_record/template/export", {responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    submissionRejectRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_reject_record/submissionRecord", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportSubmissionRejectData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_reject_record/result/export", { params: queryParams, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    checkImportRejectData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client_reject_record/validateSubmissionImportData", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },





    exportAccountTemplateData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_account_record/template/export", {responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    submissionAccountRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_account_record/submissionRecord", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportSubmissionAccountData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client_account_record/result/export", { params: queryParams, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    checkImportAccountData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client_account_record/validateSubmissionImportData", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    
  }
};
