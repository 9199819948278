import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchContracts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContract(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/contract/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editContract(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/contract/single", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addContract(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/contract", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClientContract(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/contract", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteHistory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/contract/history", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportContractTemplateData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/export", {responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createSignLink(ctx ,queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/link", {params: queryParams} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    
    submissionRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/submissionRecord", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportSubmissionData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/contract_submission/export", { params: queryParams, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    checkImportContractData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/contract/validateSubmissionImportData", {'data' : data })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    calculatePaymentSchedule(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/payment_schedules/calculate", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportContract(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/export", {params: queryParams, responseType: "blob" })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    recalculatePaymentSchedule(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/contract/payment_schedule", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportSignedContract(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/signed_contract/export", {params: queryParams, responseType: "blob" })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    unSign(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/contract/signed_contract", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  }
};
