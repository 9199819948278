import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useLoansList() {
  // Use toast
  const toast = useToast();

  const refLoanListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "ID", sortable: true },
    { key: "created_at", label: "記錄生成日期", sortable: true },
    { key: "loan_date", label: "貸款日期", sortable: true },
    { key: "supplier.company_name_cn", stickyColumn: true, label: "公司名稱（中文）" },
    { key: "client.name_cn", label: "借款人姓名(中)" },
    // { key: "client.name_en", label: "借款人姓名(英)" },
    { key: "client.id_number", label: "身份證號碼" },
    { key: "amount", label: "貸款本金", sortable: true },
    { key: "period", label: "總期數", sortable: true },
    { key: "is_monthly", label: "供款週期", sortable: true },
    { key: "contract_end_date", label: "合約完結日期", sortable: true },
    { key: "loan_status", label: "合約狀況" },
    { key: "remark", label: "貸款備註" },
    { key: "actions" , label: "操作"},
  ];
  const perPage = ref(10);
  const totalLoans = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const loansList = ref([])

  const filter_client_id = ref(null);
  const filter_supplier_id = ref(null);
  const filter_loan_status = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refLoanListTable.value
      ? refLoanListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalLoans.value,
    };
  });

  const refetchData = () => {
    refLoanListTable.value.refresh();
  };



  watch(
    [currentPage, perPage, searchQuery, filter_client_id, filter_supplier_id, filter_loan_status],
    () => {
      refetchData();
    }
  );

  const fetchLoans = (ctx, callback) => {
    store
      .dispatch("app-loan/fetchLoans", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        client_id: filter_client_id.value,
        supplier_id: filter_supplier_id.value,
        loan_status_id: filter_loan_status.value
      })
      .then((response) => {
        const { loans, total } = response.data;
        callback(loans);
        loansList.value = loans
        totalLoans.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "獲取貸款清單時出錯",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const loanStatusOptions = ref([]);
  const loanSupplierOptions = ref([]);
  // const clientsOptions = ref([]);

  const refetchOption = () => {
    store
      .dispatch("app/fetchOptionList", {
        loan_status: true,
        supplier:true,
        client:true
      })
      .then((response) => {
        loanStatusOptions.value = response.data.loan_statuses;
        loanSupplierOptions.value = response.data.suppliers;
        // clientsOptions.value = response.data.clients;
      });
  };

  onMounted(() => {
    refetchOption();
  });

  return {
    fetchLoans,
    tableColumns,
    perPage,
    currentPage,
    totalLoans,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLoanListTable,
    ability,
    loansList,
    refetchData,
    loanStatusOptions,
    loanSupplierOptions,
    // clientsOptions,
    refetchOption,
    filter_client_id,
    filter_supplier_id,
    filter_loan_status
  };
}
