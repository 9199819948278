export default [
    {
        path: "/loans",
        name: "loans-list",
        component: () => import("@/views/loan/loans-list/LoansList.vue"),
        meta:{
            action: "read",
            resource: "loan",
        }
    },
    {
        path: "/loans/create",
        name: "loans-create",
        component: () => import("@/views/loan/loans-edit/LoanEdit.vue"),
        meta:{
            action:"create",
            resource:"loan"
        }
    },
    {
        path: "/loans/multi/create",
        name: "loans-multi-create",
        component: () => import("@/views/loan/loans-multi/LoanMultiCreate.vue"),
        meta:{
            action:"create",
            resource:"loan"
        }
    },
    {
        path: "/searchs/multi/create",
        name: "searchs-multi-create",
        component: () => import("@/views/loan/loans-multi/SearchMultiCreate.vue"),
        meta:{
            action:"create",
            resource:"loan"
        }
    },

    {
        path: "/rejects/multi/create",
        name: "rejects-multi-create",
        component: () => import("@/views/loan/loans-multi/RejectMultiCreate.vue"),
        meta:{
            action:"create",
            resource:"loan"
        }
    },
    {
        path: "/accounts/multi/create",
        name: "accounts-multi-create",
        component: () => import("@/views/loan/loans-multi/AccountMultiCreate.vue"),
        meta:{
            action:"create",
            resource:"loan"
        }
    },
    // {
    //     path: "/loans/edit/:id",
    //     name: "loans-edit",
    //     component: () => import("@/views/loan/loans-edit/LoanEdit.vue"),
    //     meta:{
    //         action:"read",
    //         resource:"Auth"
    //     }
    // },
    // {
    //     path: "/loans/detail/:id",
    //     name: "loans-detail",
    //     component: () => import("@/views/loan/loans-detail/LoansDetail.vue"),
    //     meta:{
    //         action:"read",
    //         resource:"Auth"
    //     }
    // }


]