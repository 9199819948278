<template>
  <div>
    <div class="">
      <b-alert variant="danger" :show="true" class="p-1 px-2 mb-1">
        請注意修改供款表會重置所有的還款記錄!!
      </b-alert>

      <h4 class="d-flex align-items-center">
        <feather-icon size="18" icon="FileTextIcon" />
        <span class="ml-1">修改供款表</span>
      </h4>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refScheduleObserver"
      >
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-form class="pt-1" @submit.prevent="handleSubmit(formSubmitted)">
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`loan_date_2`"
                  name="貸款日期"
                  rules="required"
                >
                  <b-form-group
                    label="貸款日期："
                    :label-for="`loan_date_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <flat-pickr
                      v-model="newContractData.loan_date"
                      class="form-control"
                      :config="{
                        altInput: true,
                        altFormat: 'Y-m-d',
                        allowInput: true,
                        dateFormat: 'Y-m-d',
                        maxDate: $store.state.app.maxDate,
                      }"
                      @input="calcLoanCloseDate(newContractData)"
                      placeholder="輸入貸款日期"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`v-loan_first_pay_date_2`"
                  name="首次供款日期"
                  rules="required"
                >
                  <b-form-group
                    label="首次供款日期："
                    :label-for="`loan_first_pay_date_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <!-- class="required" -->
                    <div class="d-flex">
                      <flat-pickr
                        v-model="newContractData.first_pay_date"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'Y-m-d',
                          allowInput: true,
                          dateFormat: 'Y-m-d',
                        }"
                        placeholder="輸入首次供款日期"
                        @input="calcLoanCloseDate(newContractData)"
                      />
                      <feather-icon
                        @click="newContractData.first_pay_date = null"
                        class="resetButton"
                        size="18"
                        icon="RotateCcwIcon"
                      />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`loan_amount_2`"
                  name="貸款本金"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="貸款本金"
                    :label-for="`loan_amount_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`loan_amount_2`"
                      v-model="newContractData.amount"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入貸款本金"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`period_2`"
                  name="總期數"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="總期數"
                    :label-for="`period_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`period_2`"
                      v-model="newContractData.period"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入總期數"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`annual_interest_rate_2`"
                  name="年利率"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="年利率"
                    :label-for="`annual_interest_rate_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`annual_interest_rate_2`"
                      v-model="newContractData.annual_interest_rate"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入年利率"
                      v-limit-number:max="48"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`v-loan_is_monthly_2`"
                  name="供款週期"
                  rules="required"
                >
                  <b-form-group
                    label="供款週期："
                    :label-for="`loan_is_monthly_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="newContractData.is_monthly"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="loan_is_monthly"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        @input="calcLoanCloseDate(newContractData)"
                        :placeholder="getArrayString(loan_is_monthly)"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="利息計算"
                  rules="required"
                >
                  <b-form-group
                    label="利息計算："
                    label-for="interest_type_id_2"
                    label-cols-md="4"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="newContractData.interest_type_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="interestTypeOptions"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        :placeholder="getArrayString(interestTypeOptions)"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="供款金額(每期)："
                  label-for="average_amount_2"
                  label-cols-md="4"
                  class="required"
                >
                  <b-form-input
                    id="average_amount_2"
                    :value="
                      newContractData.average_amount &&
                      formatInput(newContractData.average_amount)
                    "
                    placeholder="自動計算"
                    disabled
                    :key="`average_amount_2_${trigger ? 1 : 0}`"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="總供款額："
                  label-for="total_amount_2"
                  label-cols-md="4"
                  class="required"
                  :key="`total_amount_2_${trigger ? 1 : 0}`"
                >
                  <b-form-input
                    id="total_amount_2"
                    :value="
                      newContractData.total_amount &&
                      formatInput(newContractData.total_amount)
                    "
                    placeholder="自動計算"
                    disabled
                    :key="`total_interest_2_${trigger ? 1 : 0}`"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="總利息："
                  label-for="total_interest_2"
                  label-cols-md="4"
                  class="required"
                >
                  <b-form-input
                    id="total_interest_2"
                    :value="
                      newContractData.total_interest &&
                      formatInput(newContractData.total_interest)
                    "
                    placeholder="自動計算"
                    disabled
                    :key="`total_interest_2_${trigger ? 1 : 0}`"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="w-100 my-2" />

            <div class="d-flex justify-content-end align-items-center mb-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mobile-w100"
                type="submit"
              >
                <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                提交
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
      <b-table
        ref="refScheduleTable"
        class="position-relative"
        :items="newContractData.schedules"
        responsive
        :fields="tableColumns"
        primary-key="period"
        show-empty
        empty-text="未找到匹配的記錄"
        :key="`schedule_table_${trigger ? 1 : 0}`"
      >
        <template #cell(principal)="data">
          {{ formatInput(data.item.principal) }}
        </template>

        <template #cell(interest)="data">
          {{ formatInput(data.item.interest) }}
        </template>

        <template #cell(payment_amount)="data">
          {{ formatInput(data.item.payment_amount) }}
        </template>

        <template #cell(remaining_principal)="data">
          {{ formatInput(data.item.remaining_principal) }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
  BAlert,
  BTable,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, watch, computed } from "@vue/composition-api";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from "vee-validate";
import { min_value } from "vee-validate/dist/rules";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";

extend("min_value", min_value);

export default {
  data() {
    return {
      tem_data: {
        loan_date: null,
        period: null,
        is_monthly: null,
        first_pay_date: null,
      },
      loan_is_monthly: [
        { id: 1, name: "月供" },
        { id: 0, name: "半月供" },
      ],
      tableColumns: [
        { key: "period", label: "期數" },
        { key: "pay_date", label: "供款日" },
        { key: "principal", label: "本金" },
        { key: "interest", label: "利息" },
        { key: "payment_amount", label: "供款金額" },
        { key: "remaining_principal", label: "尚欠本金" },
      ],
      trigger: true,
    };
  },
  directives: {
    Ripple,
    "limit-number": {
      bind: function (el, binding) {
        const max = Number(binding.value) || Infinity;
        el.addEventListener("input", function (event) {
          const input = event.target;
          let value = Number(input.value);
          if (isNaN(value) || value < 0) {
            value = 0;
          } else if (value > max) {
            value = max;
          }
          if (input.value !== value.toString()) {
            input.value = value.toString();
            // Dispatch a change event instead of input
            input.dispatchEvent(new Event("change"));
          }
        });
      },
    },
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    BAlert,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    disable_field() {
      if (this.newContractData.id > 0) {
        return true;
      } else {
        return false;
      }
    },
    scheduleData() {
      //check if any of the fields are null
      if (
        !this.newContractData ||
        this.newContractData.loan_date == null ||
        this.newContractData.amount == null ||
        this.newContractData.annual_interest_rate == null ||
        this.newContractData.period == null ||
        this.newContractData.interest_type_id == null
      ) {
        return null;
      }

      return {
        loan_date: this.newContractData.loan_date,
        amount: this.newContractData.amount,
        is_monthly: this.newContractData.is_monthly,
        annual_interest_rate: this.newContractData.annual_interest_rate,
        period: this.newContractData.period,
        interest_type_id: this.newContractData.interest_type_id,
        first_pay_date: this.newContractData.first_pay_date,
      };
    },
  },
  watch: {
    newContractData: {
      handler() {
        // This function will run whenever newContractData or any of its nested properties change.
        // You can perform any necessary operations here.
      },
      deep: true,
    },
    scheduleData: {
      handler(newVal, oldVal) {
        // Update scheduleData whenever newContractData changes
        if (newVal != null) {
          this.$store
            .dispatch("app-contract/calculatePaymentSchedule", newVal)
            .then((response) => {
              //console.log(response.data);
              if (this.newContractData) {
                this.newContractData.schedules = response.data.schedules;
                this.newContractData.total_amount = Number(
                  response.data.total_amount
                );
                this.newContractData.average_amount = Number(
                  response.data.average_amount
                );
                this.newContractData.total_interest = Number(
                  response.data.total_amount - this.newContractData.amount
                );
              }
              this.trigger = !this.trigger;
            });
        }
      },
      deep: true,
    },
    "newContractData.name_en": {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.newContractData.name_en = newVal.toUpperCase();
        }
      },
    },
    "newContractData.supplier_create_id": {
      deep: true,
      handler(newVal, oldVal) {
        if (this.newContractData.tem) {
          this.newContractData.tem = false;
          return;
        }
        if (this.supplierOptions && this.newContractData.id == 0) {
          let supplier = this.supplierOptions.find((x) => x.id == newVal);
          if (supplier) {
            this.newContractData.supplier_name_en = supplier.company_name_en;
            this.newContractData.supplier_name_cn = supplier.company_name_cn;
            this.newContractData.moneylender_number =
              supplier.moneylender_number;
            this.newContractData.supplier_address = supplier.address;
            this.newContractData.contact_name = supplier.contact_name;
          } else {
            this.newContractData.supplier_name_en = "";
            this.newContractData.supplier_name_cn = "";
            this.newContractData.moneylender_number = "";
            this.newContractData.supplier_address = "";
            this.newContractData.contact_name = "";
          }
        }
      },
    },
  },
  methods: {
    formatInput(myNumber) {
      try {
        if (myNumber != null) {
          myNumber = Number(Math.round(myNumber));
        }
        return myNumber;
      } catch (error) {
        return null;
      }
    },
    calcLoanCloseDate(item) {
      if (
        item.loan_date &&
        item.period &&
        (item.is_monthly || item.is_monthly == 0)
      ) {
        const data = {
          loan_date: item.loan_date,
          period: item.period,
          is_monthly: item.is_monthly,
          first_pay_date: item.first_pay_date,
        };

        if (
          data.loan_date == this.tem_data.loan_date &&
          data.period == this.tem_data.period &&
          data.is_monthly == this.tem_data.is_monthly &&
          data.first_pay_date == this.tem_data.first_pay_date
        ) {
          return;
        }
        this.tem_data = { ...data };
        console.log({ data, tem_data: this.tem_data });
        store
          .dispatch("app-loan/getContractEndDate", data)
          .then((response) => {
            console.log({ data });

            item.first_pay_date = response.data.data.paid_date[0];
            item.contract_end_date = response.data.data.contract_end_date;
            console.log("pay_date");
            if (item.is_monthly == 1) {
              if (response.data.data.paid_date.length > 0) {
                //get the last two digit of the date
                item.pay_date =
                  "每月" + response.data.data.paid_date[0].slice(-2) + "號";
              }
            } else {
              if (response.data.data.paid_date.length > 1) {
                //get the last two digit of the date

                let date1 = parseInt(response.data.data.paid_date[0].slice(-2));
                let date2 = parseInt(response.data.data.paid_date[1].slice(-2));
                // small number first

                if (date1 > date2) {
                  item.pay_date = "每月" + date2 + "號及" + date1 + "號";
                } else {
                  item.pay_date = "每月" + date1 + "號及" + date2 + "號";
                }
              }
            }
          })
          .catch((error) => {
            this.$swal({
              text: JSON.stringify(error.response.data.message),
              icon: "error",
              cancelButtonText: "取消",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              showClass: {
                popup: "animate__animated animate__bounceIn",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    getArrayString($arr) {
      if ($arr.length == 0) return "";
      // my array if a object with the field of name, i would like to join all the name field with a '/'
      return $arr
        .map(function (item) {
          return item.name;
        })
        .join("/");
    },
    hide() {
      this.$router.replace("/contracts");
    },
    formSubmitted() {
      console.log("this.newContractData", this.newContractData);
      const submitDate = {
        id: this.newContractData.id,
        pay_date: this.newContractData.pay_date,
        loan_date: this.newContractData.loan_date,
        first_pay_date: this.newContractData.first_pay_date,
        annual_interest_rate: this.newContractData.annual_interest_rate,
        amount: this.newContractData.amount,
        period: this.newContractData.period,
        is_monthly: this.newContractData.is_monthly,
        interest_type_id: this.newContractData.interest_type_id,
      };

      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-contract/recalculatePaymentSchedule", submitDate)
            .then((response) => {
              this.loading = false;
              this.$emit("update:allow-to-leave", true);
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              if (this.newContractData.id == 0 || true) {
                this.hide();
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
  },

  props: {
    contractData: {
      type: Object,
      required: true,
    },
    supplierOptions: {
      type: Array,
      required: true,
    },
    interestTypeOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const newContractData = ref({
      contracts: [JSON.parse(JSON.stringify({ id: 0 }))],
    });
    const resetContractData = () => {
      props.contractData = {
        contracts: [JSON.parse(JSON.stringify({ id: 0 }))],
      };
    };
    watch(
      () => props.contractData,
      (newVal) => {
        newContractData.value = { ...newVal };
      }
    );

    watch(
      () => store.state.app.user_info,
      (newVal) => {
        if (newContractData.value.id == 0) {
          if (props.supplierOptions.length > 0) {
            newContractData.value.supplier_create_id = parseInt(
              store.state.app.user_info.userData.parent_id ||
                store.state.app.user_info.userData.id
            );
            let supplier = props.supplierOptions.find(
              (x) => x.id == newContractData.value.supplier_create_id
            );
            newContractData.value.supplier_name_en = supplier.company_name_en;
            newContractData.value.supplier_name_cn = supplier.company_name_cn;
            newContractData.value.moneylender_number =
              supplier.moneylender_number;
            newContractData.value.supplier_address = supplier.address;
          }
        }
      }
    );

    watch(
      () => props.supplierOptions,
      (newVal) => {
        newContractData.value.supplier_create_id = parseInt(
          store.state.app.user_info.userData.parent_id ||
            store.state.app.user_info.userData.id
        );
        if (
          newContractData.value.id == 0 &&
          newContractData.value.supplier_create_id
        ) {
          let supplier = newVal.find(
            (x) => x.id == newContractData.value.supplier_create_id
          );
          newContractData.value.supplier_name_en = supplier.company_name_en;
          newContractData.value.supplier_name_cn = supplier.company_name_cn;
          newContractData.value.moneylender_number =
            supplier.moneylender_number;
          newContractData.value.supplier_address = supplier.address;
        }
      }
    );

    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetContractData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newContractData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
</style>
