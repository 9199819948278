<template>
  <b-card no-body class="card-client">
    <b-card-header>
      <b-card-title class="font-weight-bolder w-100 mb-2">{{ lineData.title }}</b-card-title>
      <b-card-text class="mb-0 color0 font-weight-bolder chart-result">
        {{ lineData.num }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="client-body">
      <vue-apex-charts
        id="budget-chart"
        height="50"
        :options="chartOptions"
        :series="lineData.series"
        :key="lineData.num"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import VueApexCharts from "vue-apexcharts";
import store from "@/store";
export default {
  components: {
    VueApexCharts,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
  },
  props: {
    lineData: {
      type: Object,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "area",
          toolbar: { show: false },
          zoom: { enabled: false },
          sparkline: { enabled: true },
          fill: {
            type:'solid'
          }
        },
        colors: [this.lineData.lineColor],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
         xaxis: {
          type: 'category',
          labels: {
            formatter: (val) => {
              if(this.lineData && this.lineData.xaxisC){
                return this.lineData.xaxisC[val-1]
              }
              return 
            }
          }
        },
        tooltip: {
          enabled: true,
        },
      },
    };
  },

};
</script>
<style lang="scss" scoped>
.card .card-header .card-title {
  overflow: hidden;
  white-space: nowrap;
}
.chart-result{
  font-size:18px;
}
</style>