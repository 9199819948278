<template>
    <div>
        <!-- Header: Personal Info -->
        <div class="d-flex">
            <feather-icon icon="UserIcon" size="19" />
            <h4 class="mb-0 ml-50">員工資料</h4>
        </div>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <!-- Form: Personal Info Form -->
            <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
                <b-row>
                    <!-- Field: Staff ID -->
                    <b-col cols="12" md="12">
                        <b-form-group label="員工編號" label-for="id">
                            <b-form-input id="id" v-model="userData.id" readonly />
                        </b-form-group>
                    </b-col>    
                    <!-- Field: Name -->
                    <b-col cols="12" md="6">
                        <validation-provider #default="validationContext" name="員工名稱" rules="required">
                            <b-form-group label="員工名稱" label-for="name">
                                <b-form-input id="name" 
                                v-model="userData.name" 
                                placeholder="輸入員工名稱"
                                :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!-- Field: Email Address -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="電郵地址"
                  rules="required"
                >
                <b-form-group label="電郵 地址" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    placeholder="輸入電郵地址"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>
    
            <!-- Header: Personal Info 
          <div class="d-flex mt-2">
            <feather-icon icon="UserIcon" size="19" />
            <h4 class="mb-0 ml-50">Personal Information</h4>
          </div>
          -->
            <!-- Form: Personal Info Form -->
            <b-row class="mt-1">
              <hr class="mb-1"/>
              <b-col cols="12" md="12" v-if="userData.id == 0">
                <h4 class="mb-2">密碼</h4>
              </b-col>
              <!-- Field: Password -->
              <b-col cols="12" md="6" v-if="userData.id == 0">
                <validation-provider
                  #default="validationContext"
                  name="密碼"
                  rules="required|min:8"
                  vid="password"
                >
                  <b-form-group
                    class="required"
                    label="密碼"
                    label-for="password"
                  >
                    <b-form-input
                      id="password"
                      type="password"
                      :state="getValidationState(validationContext)"
                      v-model="userData.password"
                      placeholder="輸入密碼"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Password Confirm -->
              <b-col cols="12" md="6" v-if="userData.id == 0">
                <validation-provider
                  #default="validationContext"
                  vid="password_confirmation"
                  name="確認密碼"
                  rules="required|min:8|confirmed:password"
                >
                  <b-form-group
                    class="required"
                    label="確認密碼"
                    label-for="password_confirmation"
                  >
                    <b-form-input
                      id="password_confirmation"
                      type="password"
                      :state="getValidationState(validationContext)"
                      v-model="userData.password_confirmation"
                      placeholder="輸入確認密碼"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" v-if="userData.id == 0 || true">
                <validation-provider
                  #default="validationContext"
                  name="職位"
                  rules="required"
                >
                  <b-form-group label-for="user-role" label="職位">
                    <v-select
                      v-model="userData.roles"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="roleOptions"
                      :clearable="false"
                      label="name"
                      placeholder="輸入職位"
              
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">

                  <b-form-group label-for="user-role" label="訪問權限(IP)">
                    <b-form-input
                      id="ip_whitelist"
                      type="text"
                      v-model="userData.ip_whitelist"
                      placeholder="多個IP使用(,)分隔"
                    />
                  </b-form-group>
 
              </b-col>
            </b-row>
            <hr />
    
            <b-row>
              <b-col cols="12" md="6">
            
                  <b-form-group label-for="is_active" label="狀態">
                    <b-form-checkbox
                      checked="true"
                      v-model="userData.is_active"
                      class="custom-control-success"
                      name="check-button"
                      switch
                      inline
                    />
                    {{ (userData.is_active)?'啓用':'禁用' }}
                  </b-form-group>
         
              </b-col>
            </b-row>
            <!-- Button: Submit & Reset Button.-->
            <b-row class="mt-2">
              <b-col>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  type="submit"
                >
                  提交
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="resetData"
                >
                  重置
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                 
                  variant="outline-secondary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="hide"
                >
                  取消
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
</template>

<script>
import {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
    directives: {
        Ripple,
    },
    components: {
        BRow,
        BCol,
        BForm,
        BFormGroup,
        flatPickr,
        BFormInput,
        vSelect,
        BFormRadioGroup,
        BFormCheckboxGroup,
        BButton,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
        BFormInvalidFeedback,
    },
    methods: {
        hide() {
            this.$router.replace("/users/list");
        },
        onSubmit() {
            this.$swal({
                title: "你確定嗎?",
                showCancelButton: true,
                confirmButtonText: "提交",
                cancelButtonText: "取消",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    /*
                    console.log(typeof this.userData.teams)
                    if(typeof this.userData.teams == 'object')
                    {
                      this.userData.team_id = this.userData.teams[0].id
                    }else{
                      this.userData.team_id = this.userData.teams
                    }
                    */
                    var name = this.userData.roles.name
                    if (!name) name = this.userData.roles[0].name

                    this.userData.role_name = name

                  if(this.userData.hasOwnProperty('roles'))
                    delete this.userData['roles']


                    store
                        .dispatch("app-user/updateUser", this.userData)
                        .then((response) => {
                            this.$swal({
                                text: response.data.message,
                                icon: "success",
                                confirmButtonText: "確定",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                showClass: {
                                    popup: "animate__animated animate__bounceIn",
                                },
                                buttonsStyling: false,
                            });
                            this.$emit("refetch-data");
                        })
                        .catch((error) => {
                            this.$swal({
                                text: JSON.stringify(error.response.data.message),
                                icon: "error",
                                confirmButtonText: "確定",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                showClass: {
                                    popup: "animate__animated animate__bounceIn",
                                },
                                buttonsStyling: false,
                            });
                        });
                }
            });
        },
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {

        const genderOptions = [
            { text: "Male", value: "m" },
            { text: "Female", value: "f" },
        ];

        const contactOptionsOptions = ["Email", "Message", "Phone"];

        const resetData = () => {
            emit("refetch-data");
        };
        const resetuserData = () => {
            props.clientData = JSON.parse(JSON.stringify({}));
        };
        const { refFormObserver, getValidationState, resetForm } =
        formValidation(resetuserData);

        const roleOptions = ref([]);

        const fetchRoleOptions = () => {
            store
                .dispatch("app-user/fetchRoles")
                .then((response) => {
                    roleOptions.value = response.data.roles;
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        teamOptions.value = undefined;
                    }
                });
        };

        onMounted(() => {
            fetchRoleOptions();
        });

        return {
            genderOptions,
            contactOptionsOptions,
            roleOptions,
            resetData,
            refFormObserver,
            getValidationState,
            resetForm,
            resetuserData,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.teamleader_selected {
    background: #110f6a;
    padding: 3px 10px;
    border-radius: 3px;
    color: white;
}

.tab-content {
    padding-inline: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}

</style>
