<template>
  <b-card v-if="recordData" no-body class="card-statistics p-1">
    <div class="d-flex justify-content-between pt-1 px-2">
      <h4 class="font-weight-bolder">財務記錄</h4>
      
      <div class="form-col-select" style="width:120px;">
        <v-select
          v-model="period_id"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="periodOptions"
          :clearable="false"
          label="period"
          :reduce="(option) => option.id"
          @input="(val) => this.$emit('refetch-data',period_id)"
        />
        <feather-icon size="18" icon="ChevronDownIcon" />
      </div>
      <!-- <b-card-text class="update-text font-small-2 mr-25 mb-0">
        1天前更新
      </b-card-text> -->
    </div>
    <b-card-body class="">  
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
      <b-row class="info-wrapper mt-2">
        <b-col
          v-for="(item, index) in recordData"
          :key="'course' + index"
          xl="3"
          :sm="6"
          :class="`mb-2 ${item.customClass}`"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar :style="'background-color:' + item.color" size="48">
                <font-awesome-icon :icon="`fas fa-${item.icon}`" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 color0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BOverlay,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BOverlay,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    vSelect
  },
  data() {
    return {
      period_id: -1,
    };
  },
  methods:{
  },
  props: {
    loading:{
      type: Boolean,
      required:true
    },
    recordData: {
      type: Array,
      required:true
    },
    periodOptions: {
      type: Array,
      required:true
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

.b-avatar-custom svg{
  // color:$primary-color-4;
  width:26px;
  height:26px;
}
.update-text {
  color: #828282;
}
@media screen and (max-width: 1200px) {

}
</style>