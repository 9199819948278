<template>
  <div class="p-2 bgCommon">
    <modal-loan-edit
      :loan-data="loanData"
      @refetch-data="refetchData"
    ></modal-loan-edit>

    <b-card class="mb-2">
      <div class="d-flex flex-wrap align-items-center">
        <h4 class="font-weight-bolder w-100 text-nowrap mb-0">過瀘</h4>
        <b-row class="w-100 mt-2">
          <!-- client_id-->
          <!-- <b-col cols="12" md="6" xl="4">
                <b-form-group
                  label="客戶："
                  :label-for="`loan_client_id`"
                  label-cols-md="2"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="filter_client_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clientsOptions"
                      :filter="clientSearch"
                      label="name_cn"
                      :reduce="(option) => option.id"
                      placeholder="輸入客戶"
                    >
                      <template #option="data">
                        {{ data.name_cn }} - {{ data.id_number }}
                      </template>
                      <template #selected-option="data">
                        {{ data.name_cn }} - {{ data.id_number }}
                      </template>
                    </v-select>
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                
                </b-form-group>
            </b-col> -->

          <!-- supplier_id-->
          <b-col cols="12" md="6" xl="6">
            <b-form-group
              label="銀行/財務機構："
              :label-for="`loan_supplier_id`"
              label-cols-md="3"
              class="text-nowrap"
            >
              <div class="form-col-select">
                <v-select
                  v-model="filter_supplier_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="loanSupplierOptions"
                  label="company_name_cn"
                  :reduce="(option) => option.id"
                  placeholder="輸入銀行/財務機構"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col>
          <!-- loan_status_id -->
          <b-col cols="12" md="6" xl="6">
            <b-form-group
              label="還款狀態："
              :label-for="`loan_status_id`"
              label-cols-md="2"
              class="text-nowrap"
            >
              <div class="form-col-select">
                <v-select
                  v-model="filter_loan_status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="loanStatusOptions"
                  label="name"
                  :reduce="(option) => option.id"
                  placeholder="輸入還款狀態"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <div
      class="d-flex flex-wrap flex-md-nowrap mt-2 mb-1 align-items-center justify-content-between"
    >
      <div class="d-flex flex-wrap flex-sm-nowrap align-items-center">
        <!-- <h3 class="font-weight-bolder text-nowrap mb-0">
        <feather-icon size="18" icon="CreditCardIcon" />
        貸款紀錄
      </h3> -->
        <div>
          <span class="title-size text-nowrap">貸款紀錄</span>
        </div>
        <div class="form-col-select ml-2">
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block"
          />
          <feather-icon size="18" icon="ChevronDownIcon" />
        </div>
        <div
          class="d-flex align-items-center mobile-w100 ml-0 ml-sm-1 mt-1 mt-sm-0"
        >
          <div class="search-primary">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="搜尋貸款"
            />
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-wrap flex-lg-nowrap mt-1 mt-md-0"
        v-if="ability.can('create', 'loan')"
      >

       <b-button
          variant="primary"
          class="mobile-w100 mr-0 mr-sm-1 mt-1 mt-md-0"
          :to="{ name: 'searchs-multi-create' }"
        >
          <span class="text-nowrap">批量新增搜尋記錄</span>
        </b-button>

       <b-button
          variant="primary"
          class="mobile-w100 mr-0 mr-sm-1 mt-1 mt-md-0"
          :to="{ name: 'rejects-multi-create' }"
        >
          <span class="text-nowrap">批量新增拒絕記錄</span>
        </b-button>


       <b-button
          variant="primary"
          class="mobile-w100 mr-0 mr-sm-1 mt-1 mt-md-0"
          :to="{ name: 'accounts-multi-create' }"
        >
          <span class="text-nowrap">批量新增帳務記錄</span>
        </b-button>

        <b-button
          variant="primary"
          class="mobile-w100 mr-0 mr-sm-1 mt-1 mt-md-0"
          :to="{ name: 'loans-multi-create' }"
        >
          <span class="text-nowrap">批量新增貸款</span>
        </b-button>
        
        <b-button
          variant="primary"
          class="mobile-w100 mt-1 mt-md-0"
          :to="{ name: 'loans-create' }"
        >
          <span class="text-nowrap">新增貸款</span>
        </b-button>
      </div>
    </div>
    <b-card no-body class="card-statistics">
      <b-card-body class="p-2">
        <b-table
          ref="refLoanListTable"
          class="position-relative"
          :items="fetchLoans"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="未找到匹配的記錄"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: create time -->
          <template #cell(created_at)="data">
            <div class="d-flex">
              {{ getCorrectDateTime(data.item.created_at) }}
            </div>
          </template>

          <!-- Column: is_monthly -->
          <template #cell(is_monthly)="data">
            {{ data.item.is_monthly ? "月供" : "半月供" }}
          </template>

          <!-- Column: loan_status -->
          <template #cell(loan_status)="data">
            <!-- <a href="javascript:void(0)">{{data.item.loan_status.name}}</a> -->
            {{ data.item.loan_status && data.item.loan_status.name }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              :class="`action-trigger-btn ${
                loansList.length <= 3 ? 'hor' : 'ver'
              }`"
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content class="">
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <!-- <b-dropdown-item
            :to="{ name: 'loans-detail', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">詳細資料</span>
            </b-dropdown-item> -->

              <b-dropdown-item
                v-if="ability.can('update', 'loan')"
                @click="triggerEditModal(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">修改資料</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="ability.can('delete', 'loan')"
                @click="removeItem(index, data.item)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">刪除記錄</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalLoans"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useLoansList from "./useLoansList";
import loanStoreModule from "../loanStoreModule";
import ModalLoanEdit from "../modal/ModalLoanEdit.vue";

export default {
  components: {
    ModalLoanEdit,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      loanData: {},
    };
  },
  methods: {
    removeItem(index, item) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-loan/deleteClientLoan", { id: item.id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    triggerEditModal(item) {
      this.loanData = item;
      this.$bvModal.show("modal-loan-edit");
    },
    clientSearch(options, search) {
      var returnOptions = options.filter((item) => {
        return (
          item.name_cn.toLowerCase().includes(search.toLowerCase()) ||
          item.id_number.toLowerCase().includes(search.toLowerCase())
        );
      });
      return returnOptions;
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-loan";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, loanStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchLoans,
      tableColumns,
      perPage,
      currentPage,
      totalLoans,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLoanListTable,
      refetchData,
      loansList,
      ability,
      loanStatusOptions,
      loanSupplierOptions,
      clientsOptions,
      refetchOption,
      filter_client_id,
      filter_supplier_id,
      filter_loan_status,
    } = useLoansList();

    return {
      fetchLoans,
      tableColumns,
      perPage,
      currentPage,
      totalLoans,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLoanListTable,
      refetchData,
      loansList,
      ability,
      loanStatusOptions,
      loanSupplierOptions,
      clientsOptions,
      refetchOption,
      filter_client_id,
      filter_supplier_id,
      filter_loan_status,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search-primary {
  width: calc(100% - 500px);
}
.top-button-list {
  // width: 500px;
  margin: 0;
  .btn {
    width: 150px;
    margin: 0 auto;
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .search-primary {
    width: calc(100% - 220px);
  }
  .top-button-list {
    margin-left: auto;
    margin-right: 20px;
    .btn {
      width: 150px;
      margin: 0 auto;
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .search-primary {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .top-button-list {
    margin: 0;
    width: 100%;
  }
}
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
</style>
    