import { $themeBreakpoints } from '@themeConfig'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    fetchOptionList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/options`, {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error));
      })
    },
    fetchDashboardData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/info', {params:{"period_id" : queryParams}})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchDashboardSearchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/search_record/list', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchDashboardSearchCount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/info/searchcount', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchNewestDashboardSearchRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/search_record/', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchDashboardLogData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/supplier_log/list', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    deleteLog(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/supplier_log/", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserAuth(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axiosIns.get('/auth', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error))
      })
    }
  },
}
