<template>
  <layout-vertical>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
    <div
      slot="vertical-menu-header"
      class="d-flex align-items-center justify-content-center h-100"
    >
      <div class="nav_div">
        <div class="nav_logo">
          <!-- <img :src="appLogoImage" /> -->

          <b-navbar-nav class="nav">
            <b-nav-item-dropdown
              rightx
              toggle-class="dropdown-user-link"
              class="dropdown-user"
            >
              <template #button-content>
                <div class="text-center nav-user-info">
                  <div class="nav-user-info_logo-wrapper">
                    <b-avatar
                      variant="light-primary"
                      badge
                      :src="require('@/assets/images/avatars/19.png')"
                      class="badge-minimal"
                      badge-variant="success"
                    />
                  </div>
                  <div class="nav-user-info_name">
                    <p class="mb-0">
                      {{ userName }}
                    </p>
                  </div>
                </div>
              </template>

              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="handleEditPassword"
              >
                <feather-icon size="16" icon="EditIcon" class="mr-50" />
                <span>改變密碼</span>
              </b-dropdown-item>

              <b-dropdown-divider />

              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="handleLogout"
              >
                <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                <span>登出</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </div>
        <div class="nav_brand"></div>
        <div class="bookmark-wrapper colorWhite align-items-center">
          {{
            new Date().getDate() +
            " " +
            monthNames[new Date().getMonth()] +
            " " +
            new Date().getFullYear() +
            " " +
            `( ${dayNames[new Date().getDay()]} )`
          }}
        </div>
      </div>
    </div>

    <div slot="footer">
      <p class="mb-0">
        &copy; {{ companyName }} {{ new Date().getFullYear() }}
      </p>
      <edit-password />
    </div>
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import { getUserData } from "@/auth/utils";
import Navbar from "../components/Navbar.vue";
import { $themeConfig } from "@themeConfig";
import EditPassword from "../components/EditPassword.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import axiosIns from "@/libs/axios";
import store from "@/store";

import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // AppCustomizer,
    LayoutVertical,
    Navbar,

    EditPassword,
    FeatherIcon,
  },
  data() {
    return {
      userName: getUserData().name,
      // showCustomizer: $themeConfig.layout.customizer,
      appName: $themeConfig.app.appName,
      appLogoImage: $themeConfig.app.appLogoImage,
      companyName: $themeConfig.app.companyName,
       monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dayNames: ["SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"],
    };
  },
  methods: {
    handleEditPassword() {
      this.$root.$emit("bv::show::modal", "modal-edit-password", "#btnShow");
    },
    handleLogout() {
      //Request server to void the token
      axiosIns.get("/logout");
      localStorage.clear();
      //Reqired to login page
      //Show the success message
      this.$router.replace("/login").then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully logged out!`,
          },
        });
      });
    },
  },
  computed: {},
  mounted() {
    //console.log("mounted");
    store.dispatch("app/fetchUserAuth").then((response) => {
      //console.log(response.data.user.ability)
      let ability =
        response &&
        response.data &&
        response.data.user &&
        response.data.user.ability;
      if (ability) {
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        this.$ability.update(ability);
      }
    });
  },
};
</script>

<style>
#menu_title {
  font-size: 0.9rem !important;
}
</style>
