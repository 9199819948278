<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="pt-sm-3">
        <div class="mb-1">
          <b-row
            v-for="(account, index) in accountNewData"
            :key="`accounts_${index}`"
            class="pb-2 mb-3"
          >
            <validation-observer
              class="w-100"
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                class="w-100"
                @submit.prevent="handleSubmit(onSubmit(index, account))"
              >
                <b-col cols="12">
                  <div class="d-flex px-1 mb-1">
                    <h4
                      :class="account.record_status_id == 2 ? 'redColor' : ''"
                    >
                      帳務記錄 {{ index + 1 }}
                      {{ account.record_status_id == 2 ? "已刪除" : "" }}
                    </h4>
                  </div>
                  <div
                    class="d-flex border rounded"
                    :class="account.record_status_id == 2 ? 'redBorder' : ''"
                  >
                    <b-row class="flex-grow-1 p-2">
                      <!-- supplier_id -->
                      <b-col cols="12">
                        <validation-provider
                          #default="validationContext"
                          name="銀行/財務機構"
                          :vid="`v-account_supplier_id_${index}`"
                          rules="required"
                        >
                          <b-form-group
                            label="銀行/財務機構："
                            :label-for="`account_supplier_id_${index}`"
                            label-cols-md="3"
                            class="required"
                          >
                            <div class="form-col-select">
                              <v-select
                                v-model="account.supplier_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="supplierOptions"
                                :clearable="false"
                                label="company_name_cn"
                                :reduce="(option) => option.id"
                                :disabled="account.record_status_id == 2"
                                placeholder="輸入銀行/財務機構"
                              />
                              <feather-icon size="18" icon="ChevronDownIcon" />
                            </div>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- account_option_id -->
                      <b-col cols="12">
                        <validation-provider
                          #default="validationContext"
                          :vid="`v-account_option_id_${index}`"
                          name="帳務狀態"
                          rules="required"
                        >
                          <b-form-group
                            label="帳務狀態："
                            label-for="account_option_id"
                            label-cols-md="3"
                            class="required"
                          >
                            <b-form-radio-group
                              style="margin-top: 10px"
                              v-model="account.account_option_id"
                              :name="'account_option_id' + index"
                              :disabled="account.record_status_id == 2"
                              stacked
                            >
                              <b-form-radio
                                v-for="option of accountOptions"
                                :key="'account_option_id' + option.id"
                                :value="option.id"
                                class="mb-1"
                                >{{ option.name }}</b-form-radio
                              >
                            </b-form-radio-group>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- date -->
                      <b-col cols="12">
                        <b-form-group
                          label="日期："
                          label-for="account_date"
                          label-cols-md="3"
                        >
                          <flat-pickr
                            id="account_date"
                            v-model="account.record_date"
                            :disabled="account.record_status_id == 2"
                            class="form-control"
                            :config="{
                              allowInput: true,
                              dateFormat: 'Y-m-d',
                              maxDate: maxDate,
                            }"
                            placeholder="輸入日期"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- remark -->
                      <b-col cols="12">
                        <b-form-group
                          label="備註："
                          label-for="account_remark"
                          label-cols-md="3"
                        >
                          <b-form-input
                            :disabled="account.record_status_id == 2"
                            id="account_remark"
                            v-model="account.remark"
                            placeholder="輸入備註"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        class="d-flex mt-1 justify-content-end"
                      >
                        <b-button
                          v-if="account.record_status_id != 2"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="mr-sm-2 mobile-w100 d-flex align-items-center sort_delete"
                          @click="removeItem(index, account, false)"
                        >
                          <feather-icon
                            size="16"
                            class="mr-50"
                            icon="XIcon"
                          />
                          刪除
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          class="mr-sm-2 mobile-w100 d-flex align-items-center"
                          @click="removeItem(index, account, true)"
                        >
                          <feather-icon
                            size="16"
                            class="mr-50"
                            icon="XOctagonIcon"
                          />
                          永久刪除
                        </b-button>
                        <b-button
                          v-if="account.record_status_id != 2"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mr-sm-2 mobile-w100 d-flex align-items-center"
                          type="submit"
                        >
                          <feather-icon
                            size="16"
                            class="mr-50"
                            icon="CheckCircleIcon"
                          />
                          提交
                        </b-button>
                      </b-col>
                    </b-row>
                    <div
                      class="d-flex flex-column justify-content-between border-left py-50 px-25"
                    >
                      <feather-icon
                        size="16"
                        icon="PlusIcon"
                        class="cursor-pointer text-success"
                        @click="addItem"
                      />
                      <!-- <feather-icon
                        v-if="account.record_status_id != 2"
                        size="16"
                        icon="DeleteIcon"
                        class="cursor-pointer text-danger"
                        @click="removeItem(index, account, false)"
                      />

                      <feather-icon
                        size="15"
                        icon="XOctagonIcon"
                        class="cursor-pointer text-danger"
                        @click="removeItem(index, account, true)"
                      /> -->
                    </div>
                  </div>
                </b-col>
              </b-form>
            </validation-observer>
          </b-row>
        </div>

        <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
            class="mt-1 mt-sm-0 mobile-w100"
          >
            取消
          </b-button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTable,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, watch, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import router from "@/router";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { maxDate } from "@/libs/helper";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormRadio,
    BTable,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      loading: false,
      tableColumns: [
        // { key: "id", label: "ID "},
        { key: "created_at", label: "修改時間", sortable: true },
        { key: "creator", label: "修改人" },
        { key: "content", label: "修改主題" },
        { key: "changedData", label: "修改內容" },
      ],
      statusOptions: [
        { id: 0, name: "正常" },
        { id: 1, name: "修改" },
        { id: 2, name: "取消" },
      ],
    };
  },
  directives: {
    Ripple,
  },
  props: {
    accountData: {
      type: Array,
      required: true,
    },
    supplierOptions: {
      type: Array,
      required: true,
    },
    // accountLogs: {
    //   type: Array,
    //   required: true,
    // },
  },
  methods: {
    hide() {
      this.$router.replace("/clients");
    },
    onSubmit(index, item) {
      var url;

      if (!item.supplier_id || !item.account_option_id) return;

      if (item.id > 0) {
        url = "editClientAccount";
      } else {
        url = "addClientAccount";
      }
      this.$swal({
        title: `你確定嗎？`,
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;

          this.$store
            .dispatch("app-client/" + url, item)
            .then((response) => {
              this.loading = false;
              this.$emit("refetch-data");
              this.$swal({
                text: response.data.message,
                confirmButtonText: "確定",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    addItem() {
      this.accountNewData.push(
        JSON.parse(
          JSON.stringify({
            id: 0,
            client_id: this.$route.params.id,
            record_date: maxDate,
          })
        )
      );
    },
    removeItem(index, item, force_delete) {
      let record = this.accountNewData && this.accountNewData.length;
      let msg = force_delete
        ? "您的行動是最終的，您將永久刪除這記錄。"
        : "您的行動會更換此記錄為刪除狀態，你將無法修改這記錄但您還可以檢索。";

        if (item.id > 0) {
          this.$swal({
            title: "你確定嗎?",
            text: msg,
            showCancelButton: true,
            confirmButtonText: "刪除",
            cancelButtonText: "取消",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              store
                .dispatch("app-client/delectClientAccount", {
                  id: item.id,
                  force_delete: force_delete,
                })
                .then((response) => {
                  this.$swal({
                    text: response.data.message,
                    icon: "success",
                    confirmButtonText: "確定",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                  this.$emit("refetch-data");

                  if(record <= 1 && force_delete){
                    this.addItem();
                  }
                })
                .catch((error) => {
                  this.$swal({
                    text: JSON.stringify(error.response.data.message),
                    icon: "error",
                    confirmButtonText: "確定",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                });
            }
          });
        } else {
          if(record > 1){
            this.accountNewData.splice(index, 1);
          }else{
            this.makeToast("danger", "Warning", "請至少留有一個表單。");
          }
        }
    },
  },
  computed: {},
  setup(props) {
    const accountNewData = ref([]);
    const resetAccountData = () => {
      accountNewData = JSON.parse(JSON.stringify([{}]));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetAccountData);

    const accountOptions = ref([]);

    onMounted(() => {
      refetchOption();
    });

    watch(
      () => props.accountData,
      (newVal, oldVal) => {
        if (newVal.length > 0) {
          const arr = newVal.map((ele) => {
            if (!ele.record_date) {
              ele = { ...ele, record_date: maxDate };
            } else {
              ele = { ...ele };
            }
            return ele;
          });
          accountNewData.value = [...arr];
        } else {
          accountNewData.value = [
            JSON.parse(
              JSON.stringify({
                id: 0,
                client_id: router.currentRoute.params.id,
                record_date: maxDate,
              })
            ),
          ];
        }
      },
      { immediate: true }
    );

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          account_option: true,
        })
        .then((response) => {
          accountOptions.value = response.data.account_options;
        });
    };
    return {
      accountNewData,
      accountOptions,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.sort_delete {
  border-color: #d99899 !important;
  background-color: #d99899 !important;
}
</style>
