<template>
    <div>
      <modal-edit-approve v-if="approveData" @refetch-data="refetchData" :approveData="approveData" :imageUrl="imageUrl" />
     
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
  
            <div
              class="d-flex flex-wrap justify-content-between flex-lg-nowrap align-items-center"
            >
              <div class="d-flex">
                <h3 class="d-flex align-items-center font-weight-bolder text-nowrap mb-0">
                  <feather-icon class="mr-50" size="18" icon="FileTextIcon" />
                  審批放債人號碼
                </h3>
                <div class="form-col-select ml-2">
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block"
                  />
                  <feather-icon size="18" icon="ChevronDownIcon" />
                </div>
                <div class="search-primary ml-2">
                  <feather-icon size="16" icon="SearchIcon" />
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="搜尋..."
                  />
                </div>
              </div>
            </div>
          </div>
          <b-table
            ref="refApproveListTable"
            class="position-relative"
            :items="fetchApproves"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="未找到匹配的記錄"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(supplier)="data">
              {{data.item.supplier && (data.item.supplier.company_name_cn || data.item.supplier.company_name_en)}}
            </template>
          <!-- Column: handled -->
            <template #cell(handled)="data">
              <feather-icon
                v-if="data.item.handled"
                icon="CheckIcon"
                size="18"
                class="mr-50 text-success"
              />
            </template>
            <template #cell(user)="data">
              {{data.item.user && data.item.user.name}}
            </template>
          <!-- Column: approved -->
            <template #cell(approved)="data">
              <feather-icon
                v-if="data.item.approved"
                icon="CheckIcon"
                size="18"
                class="mr-50 text-success"
              />
            </template>
  
            <template #cell(actions)="data">
              <b-dropdown
                :class="`action-trigger-btn ${
                  approvesList.length <= 3 ? 'hor' : 'ver'
                }`"
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="EyeIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
  
                <b-dropdown-item 
                 @click="triggerEditApprove(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">審批</span>
                </b-dropdown-item>  

                <b-dropdown-item @click="removeItem(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">刪除</span>
                </b-dropdown-item>
  
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                  {{ dataMeta.to }}個記錄</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalApproves"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
    </div>
  </template>
      
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import store from "@/store";
  import { ref, onUnmounted } from "@vue/composition-api";
  import useApprovesList from "./useApprovesList";
  import approveStoreModule from "../supplierStoreModule";
  import ModalEditApprove from "../modal/ModalEditApprove.vue";
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BOverlay,
      vSelect,
      ModalEditApprove,
    },
    data() {
      return {
        approveData: {},
        imageUrl:"",
        title: "",
      };
    },
    methods: {
      triggerEditApprove(item){
        this.imageUrl = item.real_image_path;
        this.approveData={
          id : item.id,
          moneylender_number : item.moneylender_number,
          remark : item.remark,
          approved : item.approved,
        }
        this.$bvModal.show('modal-edit-approve')
      },
      removeItem(id) {
        // check number of record
        this.$swal({
          title: "你確定嗎?",
          text: "您的決定是最終決定，您將無法取回這記錄。",
          showCancelButton: true,
          confirmButtonText: "刪除",
          cancelButtonText: "取消",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch("app-approve/deleteApprove", { id })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.refetchData();
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      },
    },
    setup() {
      const USER_APP_STORE_MODULE_NAME = "app-approve";
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.registerModule(USER_APP_STORE_MODULE_NAME, approveStoreModule);
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });
  
      const {
        fetchApproves,
        tableColumns,
        perPage,
        currentPage,
        totalApproves,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refApproveListTable,
        refetchData,
        approvesList,
        ability,

      } = useApprovesList();
  
      return {
        fetchApproves,
        tableColumns,
        perPage,
        currentPage,
        totalApproves,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refApproveListTable,
        refetchData,
        approvesList,
        ability,
        
      };
    },
  };
  </script>
      
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .search-primary {
    width: calc(100% - 500px);
  }
  .top-button-list {
    // width: 500px;
    margin: 0;
    .btn {
      width: 150px;
      margin: 0 auto;
      display: block;
    }
  }
  @media screen and (max-width: 991px) {
    .search-primary {
      width: calc(100% - 220px);
    }
    .top-button-list {
      margin-left: auto;
      margin-right: 20px;
      .btn {
        width: 150px;
        margin: 0 auto;
        display: block;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .search-primary {
      width: 100%;
    }
  }
  @media screen and (max-width: 575px) {
    .top-button-list {
      margin: 0;
      width: 100%;
    }
  }
  </style>
      
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@/assets/scss/variables/_variables.scss";
  </style>
      