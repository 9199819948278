<template>
  <div>
    <modal-PDF
      v-if="pdfData"
      :getPdfData="pdfData"
      :number_of_search="number_of_search"
      :number_of_loan="number_of_loan"
    ></modal-PDF>
    <b-row>
      <b-col xl="4" md="6">
        <b-card class="search-count-table">
          <b-overlay
            :show="loading2"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-table
              ref="refSearchCountListTable"
              sticky-header 
              class="position-relative"
              :items="fetchSearchCounts"
              responsive
              :fields="searchCountTableColumn"
              primary-key="id"
              :sort-by.sync="searchCountSortBy"
              show-empty
              empty-text="未找到匹配的記錄"
              :sort-desc.sync="isSearchCountSortDirDesc"
            >
            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col xl="8" md="6">
        <b-card
          v-if="
            (ability.can('create', 'client') ||
              ability.can('create', 'supplier') ||
              ability.can('create', 'loan')) &&
            supplier_id == null
          "
        >
          <div
            class="d-flex flex-wrap flex-md-nowrap justify-content-end align-items-center"
          >
            <b-button
              variant="primary"
              v-if="ability.can('create', 'client')"
              class="mobile-w100 mr-sm-2"
              :to="{ name: 'clients-create' }"
            >
              <span class="text-nowrap">新增客戶</span>
            </b-button>
            <b-button
              variant="primary"
              v-if="ability.can('create', 'supplier')"
              class="mobile-w100 mr-sm-2 mt-2 mt-sm-0"
              :to="{ name: 'suppliers-create' }"
            >
              <span class="text-nowrap">新增財務公司</span>
            </b-button>

            <b-button
              variant="primary"
              v-if="ability.can('create', 'loan')"
              class="mobile-w100 mr-sm-1 mt-2 mt-sm-0"
              :to="{ name: 'loans-create' }"
            >
              <span class="text-nowrap">新增貸款</span>
            </b-button>
          </div>
        </b-card>
        <b-card v-if="supplier_id == null">
          <div class="d-flex flex-wrap flex-sm-nowrap">
            <div class="d-flex mb-2 mb-md-0 align-items-center">
              <!-- <div class="mx-1">
                <font-awesome-icon icon="fa-solid fa-address-card" />
              </div> -->
              <span class="text-nowrap font-weight-bolder pr-3"
                >身分證號碼:</span
              >
            </div>
            <div class="d-flex flex-wrap flex-sm-nowrap w-100">
              <b-form-input
                v-model="searchQueryTemp"
                v-on:keyup.enter="changeSearch"
                class="mr-0 mr-sm-2 mt-1 m-sm-0"
                type="text"
                placeholder="輸入身分證號碼 e.g. A123456(7) 或 A1234567"
              ></b-form-input>
              <b-button
                class="bgColor0 text-nowrap mt-1 m-sm-0"
                @click="changeSearch"
              >
                <feather-icon size="16" icon="SearchIcon"></feather-icon>
                搜尋
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div class="d-flex flex-wrap justify-content-between align-items-center">
      <div
        class="d-flex flex-wrap mb-1 justify-content-start align-items-center"
      >
        <!-- <feather-icon size="16" icon="SearchIcon"></feather-icon> -->
        <span class="title-size">過往搜尋記錄</span>
        <div class="form-col-select ml-2">
          <v-select
            style="min-width: 90px"
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block"
          />
          <feather-icon size="18" icon="ChevronDownIcon" />
        </div>
      </div>
      <div class="row w-100 justify-content-end" style="max-width: 800px">
        <div
          v-if="in_dashbord"
          class="col-12 col-md-6 col-lg-6 mb-1 d-flex align-items-center"
        >
          <el-date-picker
            class="w-100"
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
        </div>
        <div v-if="in_dashbord" class="col-12 col-md-6 col-lg-3 mb-1">
          <v-select
            v-model="companyFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="companyOptions"
            label="name"
            :clearable="false"
            :reduce="(option) => option.id"
            placeholder="選擇公司／街客"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-1 d-flex align-items-center">
          搜尋次數： {{ totalSearchs }}
        </div>
      </div>
    </div>

    <b-card no-body class="card-statistics p-sm-1">
      <b-card-body class="p-2">
        <b-overlay
          :show="loading2"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-table
            ref="refSearchListTable"
            class="position-relative"
            :items="fetchSearchs"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="未找到匹配的記錄"
            :sort-desc.sync="isSortDirDesc"
          >
            <!-- Column: search_key_words -->
            <template #cell(search_key_words)="data">
              <div class="d-flex align-items-center">
                <span class="mr-1 text-nowrap">
                  <feather-icon
                    class="mr-1"
                    size="16"
                    icon="SearchIcon"
                  ></feather-icon>
                  <span>搜尋：</span>
                </span>

                <span>
                  {{ data.item.search_key_words }}
                </span>
              </div>
            </template>

            <!-- Column: create time -->
            <template #cell(created_at)="data">
              {{ getCorrectDateTime(data.item.created_at) }}
            </template>

            <!-- Column: Download -->
            <template #cell(actions)="data">
              <a
                v-if="!data.item.is_error"
                href="javascript:void(0)"
                download
                @click="triggerModalSearchInfo(data.item.id)"
              >
                <font-awesome-icon
                  class="color0"
                  style="font-size: 20px"
                  icon="fas fa-file"
                />
              </a>
              <div
                v-else
                class="error-exclamation"
                v-b-popover.hover.top="data.item.error_message"
                title="錯誤！"
              >
                <font-awesome-icon icon="fa-solid fa-exclamation" />
              </div>
            </template>
          </b-table>
        </b-overlay>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalSearchs"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>

    <div
      class="d-flex flex-wrap mb-1 justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center">
        <!-- <feather-icon size="16" icon="SearchIcon"></feather-icon> -->
        <span class="title-size">修改記錄</span>
        <div class="form-col-select ml-2">
          <v-select
            style="min-width: 90px"
            v-model="perLogPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block"
          />
          <feather-icon size="18" icon="ChevronDownIcon" />
        </div>
      </div>
      <div class="search-primary mt-1 mt-sm-0" v-if="supplier_id == null">
        <feather-icon size="16" icon="SearchIcon" />
        <b-form-input
          v-model="searchLogQuery"
          class="d-inline-block"
          placeholder="搜尋修改記錄"
        />
      </div>
    </div>
    <b-card no-body class="card-statistics p-sm-1">
      <b-card-body class="p-2">
        <b-overlay
          :show="loading2"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-table
            ref="refLogListTable"
            class="position-relative"
            :items="fetchLogs"
            responsive
            :fields="logTableColumns"
            primary-key="id"
            :sort-by.sync="logSortBy"
            show-empty
            empty-text="未找到匹配的記錄"
            :sort-desc.sync="isLogSortDirDesc"
          >
            <!-- Column: create time -->
            <template #cell(created_at)="data">
              {{ getCorrectDateTime(data.item.created_at) }}
            </template>

            <template #cell(creator)="data">
              {{ data.item.creator.contact_name }}
            </template>
            <!-- <template #cell(changedData)="data">
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).name_cn"
              >
                <span class="title">name_cn</span>
                <span class="desc">
                  {{ JSON.parse(data.item.changedData).name_cn.old_value }} >
                  {{
                    JSON.parse(data.item.changedData).name_cn.new_value
                  }}</span
                >
              </div>
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).name_en"
              >
                <span class="title">name_en</span>
                <span class="desc">
                  {{ JSON.parse(data.item.changedData).name_en.old_value }} >
                  {{
                    JSON.parse(data.item.changedData).name_en.new_value
                  }}</span
                >
              </div>
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).loan_date"
              >
                <span class="title">loan_date</span>
                <span class="desc">
                  {{ JSON.parse(data.item.changedData).loan_date.old_value }} >
                  {{
                    JSON.parse(data.item.changedData).loan_date.new_value
                  }}</span
                >
              </div>
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).amount"
              >
                <span class="title">amount</span>
                <span class="desc">
                  {{ JSON.parse(data.item.changedData).amount.old_value }} >
                  {{ JSON.parse(data.item.changedData).amount.new_value }}</span
                >
              </div>
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).period"
              >
                <span class="title">period</span>
                <span class="desc">
                  {{ JSON.parse(data.item.changedData).period.old_value }} >
                  {{ JSON.parse(data.item.changedData).period.new_value }}</span
                >
              </div>
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).is_monthly"
              >
                <span class="title">is_monthly</span>
                <span class="desc">
                  {{ JSON.parse(data.item.changedData).is_monthly.old_value }} >
                  {{
                    JSON.parse(data.item.changedData).is_monthly.new_value
                  }}</span
                >
              </div>
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).first_pay_date"
              >
                <span class="title">first_pay_date</span>
                <span class="desc">
                  {{
                    JSON.parse(data.item.changedData).first_pay_date.old_value
                  }}
                  >
                  {{
                    JSON.parse(data.item.changedData).first_pay_date.new_value
                  }}</span
                >
              </div>
            </template> -->
            <template #cell(changedData)="data">
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).loan_date"
              >
                <span class="title">貸款日期：</span>
                <span class="desc"
                  >{{ JSON.parse(data.item.changedData).loan_date.old_value }} >
                  {{
                    JSON.parse(data.item.changedData).loan_date.new_value
                  }}</span
                >
              </div>
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).amount"
              >
                <span class="title">貸款本金：</span>
                <span class="desc"
                  >{{ JSON.parse(data.item.changedData).amount.old_value }} >
                  {{ JSON.parse(data.item.changedData).amount.new_value }}</span
                >
              </div>
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).period"
              >
                <span class="title">總期數：</span>
                <span class="desc"
                  >{{ JSON.parse(data.item.changedData).period.old_value }} >
                  {{ JSON.parse(data.item.changedData).period.new_value }}</span
                >
              </div>
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).is_monthly"
              >
                <span class="title">供款週期：</span>
                <span class="desc"
                  >{{
                    JSON.parse(data.item.changedData).is_monthly.old_value
                      ? "月供"
                      : "半月供"
                  }}
                  >
                  {{
                    JSON.parse(data.item.changedData).is_monthly.new_value
                      ? "月供"
                      : "半月供"
                  }}</span
                >
              </div>
              <div
                class="d-flex logs-flex"
                v-if="JSON.parse(data.item.changedData).first_pay_date"
              >
                <span class="title">首次供款日期：</span>
                <span class="desc"
                  >{{
                    JSON.parse(data.item.changedData).first_pay_date.old_value
                  }}
                  >
                  {{
                    JSON.parse(data.item.changedData).first_pay_date.new_value
                  }}</span
                >
              </div>
            </template>
            <template #cell(delete)="data">
              <a
                v-if="ability.can('delete', 'loan')"
                href="javascript:void(0)"
                @click="deleteLog(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </a>
            </template>
          </b-table>
        </b-overlay>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ logDataMeta.of }}個記錄中，正在顯示
                {{ logDataMeta.from }} 至 {{ logDataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentLogPage"
                :total-rows="totalLog"
                :per-page="perLogPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BTable,
  BPagination,
  BOverlay,
  VBPopover,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import ModalPDF from "./modal/ModalPDF";
import useSearchsList from "./useSearchsList";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BTable,
    BPagination,
    BOverlay,
    vSelect,
    ModalPDF,
    flatPickr,
  },
  directives: {
    "b-popover": VBPopover,
  },
  data() {
    return {
      searchQueryTemp: "",
      pdfData: {},
      loading2: false,
      dateRange: [],

      number_of_search: 0,
      number_of_loan: 0,
    };
  },
  props: {
    supplier_id: {
      type: Number,
    },
    date_from: {
      // type: Date,
    },
    date_to: {
      // type: Date
    },
    in_dashbord: {},
  },
  watch: {
    supplier_id: {
      handler() {
        this.supplierFilter = this.supplier_id;
      },
    },
    date_from: {
      handler() {
        console.log("this.date_from", this.date_from);
        console.log("new Date(this.date_from)", new Date(this.date_from));
        this.dateFromFilter = this.getFormatedDate(
          this.date_from ? new Date(this.date_from) : new Date()
        );
        console.log("date_from this.dateFromFilter ", this.dateFromFilter);
      },
    },
    date_to: {
      handler() {
        console.log("this.date_to", this.date_to);
        console.log("new Date(this.date_to)", new Date(this.date_to));
        this.dateToFilter = this.getFormatedDate(
          this.date_to ? new Date(this.date_to) : new Date()
        );
      },
    },
    dateRange: {
      handler() {
        [this.dateFromFilter, this.dateToFilter] = this.dateRange;
        if (typeof this.dateFromFilter === "object") {
          this.dateFromFilter = this.getFormatedDate(this.dateFromFilter);
          this.dateToFilter = this.getFormatedDate(this.dateToFilter);
        }
      },
    },
  },
  methods: {
    changeSearch() {
      this.searchQuery = this.searchQueryTemp;
    },
    setZeroTime(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    },
    getFormatedDate(date) {
      const mm = date.getMonth() + 1; // getMonth() is zero-based
      const dd = date.getDate();
      // const hh = date.getHours();
      // const ii = date.getMinutes();

      return [
        date.getFullYear(),
        (mm > 9 ? "" : "0") + mm,
        (dd > 9 ? "" : "0") + dd,
      ].join("-");
      // + ' ' + [
      //   (hh > 9 ? "" : "0") + hh,
      //   (ii > 9 ? "" : "0") + ii,
      //   ].join(":");
    },
    // id
    triggerModalSearchInfo(id) {
      this.loading2 = true;
      // this.pdfData = item;
      this.$store
        .dispatch("app/fetchNewestDashboardSearchRecord", { id: id })
        .then((response) => {
          this.loading2 = false;
          this.number_of_loan = response.data.number_of_loan;
          this.number_of_search = response.data.number_of_search;
          this.pdfData = response.data.search_record;
          this.$bvModal.show("modal-PDF");
        })
        .catch((error) => {
          this.loading2 = false;
          console.log(error);
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    deleteLog(id) {
      console.log(id);
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app/deleteLog", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchLogData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  created() {
    this.dateFromFilter = this.getFormatedDate(
      this.date_from ? new Date(this.date_from) : new Date()
    );
    this.dateToFilter = this.getFormatedDate(
      this.date_to ? new Date(this.date_to) : new Date()
    );
    this.dateRange = [this.dateFromFilter, this.dateToFilter];
  },
  setup() {
    const {
      fetchSearchs,
      tableColumns,
      perPage,
      currentPage,
      totalSearchs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSearchListTable,
      refetchData,
      ability,

      fetchLogs,
      logTableColumns,
      perLogPage,
      currentLogPage,
      totalLog,
      logDataMeta,
      searchLogQuery,
      logSortBy,
      isLogSortDirDesc,
      refLogListTable,
      refetchLogData,
      companyFilter,
      companyOptions,
      supplierFilter,
      dateFromFilter,
      dateToFilter,

      refSearchCountListTable,
      searchCountTableColumn,
      searchCountSortBy,
      isSearchCountSortDirDesc,
      fetchSearchCounts,
    } = useSearchsList();

    return {
      // Sidebar
      fetchSearchs,
      tableColumns,
      perPage,
      currentPage,
      totalSearchs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSearchListTable,
      refetchData,
      ability,

      fetchLogs,
      logTableColumns,
      perLogPage,
      currentLogPage,
      totalLog,
      logDataMeta,
      searchLogQuery,
      logSortBy,
      isLogSortDirDesc,
      refLogListTable,
      refetchLogData,
      companyFilter,
      companyOptions,
      supplierFilter,
      dateFromFilter,
      dateToFilter,

      refSearchCountListTable,
      searchCountTableColumn,
      searchCountSortBy,
      isSearchCountSortDirDesc,
      fetchSearchCounts,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.search-count-table{
  height:185px;
    overflow:hidden;
    .sticky-header{
      max-height: 145px;
    }
  // .card-body{
  //   overflow:hidden
  // }
}
</style>