export default [
    {
        path: "/lucky/wheel",
        name: "wheel-award-list",
        component: () => import("@/views/lucky/wheel/Awards-list/AwardsList.vue"),
        meta:{
            action: "read",
            resource: "Auth",
        }
    },
    {
        path: "/lucky/wheel/record",
        name: "wheel-get-award-record",
        component: () => import("@/views/lucky/wheel/Records-list/RecordsList.vue"),
        meta:{
            action: "read",
            resource: "Auth",
        }
    },
   
    // {
    //     path: "/loans/edit/:id",
    //     name: "loans-edit",
    //     component: () => import("@/views/loan/loans-edit/LoanEdit.vue"),
    //     meta:{
    //         action:"read",
    //         resource:"Auth"
    //     }
    // },
    // {
    //     path: "/loans/detail/:id",
    //     name: "loans-detail",
    //     component: () => import("@/views/loan/loans-detail/LoansDetail.vue"),
    //     meta:{
    //         action:"read",
    //         resource:"Auth"
    //     }
    // }


]