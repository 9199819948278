<template>
  <div class="">
    <b-card no-body class="mb-0">
      <b-card-body class="bgCommon">
        <div
          class="
            d-flex
            flex-wrap flex-lg-nowrap
            justify-content-between
            align-items-center
          "
        >
          <div class="d-flex flex-wrap flex-lg-nowrap align-items-center">
            <h3 class="font-weight-bolder text-nowrap mb-0">
              <feather-icon size="18" icon="CreditCardIcon" />
              批量新增客戶家庭成員
            </h3>
            <div class="form-col-select ml-2">
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
              />
              <feather-icon size="18" icon="ChevronDownIcon" />
            </div>
            <div class="search-primary inMulti">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜尋客戶家庭成員"
              />
            </div>
          </div>
          <div>
            <font-awesome-icon
              class="refresh-icon mr-1"
              @click="refetchData"
              icon="fas fa-redo"
            />
          </div>
        </div>
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <div class="position-relative">
            <b-table
              ref="refClientHomeMemberSubmissionListTable"
              class="position-relative multi-table"
              :items="fetchClientsHomeMemberSubmission"
              :fields="tableColumns"
              responsive
              primary-key="id"
              show-empty
              empty-text="未找到匹配的記錄"
            >
              <!-- Column: total_records -->
              <template #cell(total_records)="data">
                <div class="d-flex">
                  {{
                    `${Math.floor(
                      (data.item.total_handled / data.item.total_records) * 100
                    )}% (${data.item.total_handled}/${data.item.total_records})`
                  }}
                </div>
              </template>
              <!-- Column: create time -->
              <template #cell(created_at)="data">
                <div class="d-flex">
                  {{ getCorrectDateTime(data.item.created_at) }}
                </div>
              </template>

              <!-- Column: Download -->
              <template #cell(actions)="data">
                <a
                  v-if="data.item.failed"
                  href="javascript:void(0)"
                  download
                  @click="exportSubmissionExcel(data.item.id)"
                >
                  <font-awesome-icon
                    class="color1"
                    style="font-size: 20px"
                    icon="fas fa-file"
                  />
                </a>
                <div v-else>- -</div>
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="
                    d-flex
                    align-items-center
                    justify-content-center justify-content-sm-start
                  "
                >
                  <span class="text-muted"
                    >在{{ dataMeta.of }}個記錄中，正在顯示
                    {{ dataMeta.from }} 至 {{ dataMeta.to }}個記錄</span
                  >
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="
                    d-flex
                    align-items-center
                    justify-content-center justify-content-sm-end
                  "
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalClient"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="d-flex mt-2 flex-wrap justify-content-between">
            <div class="d-flex mb-2 align-items-center">
              <span class="mx-auto">或</span>
              <b-button
                class="btn-primary-green btn-trigger-export ml-1 mobile-w100"
                @click="$refs.importFile.$el.childNodes[0].click()"
              >
                <feather-icon icon="FileTextIcon" class="mr-1" size="16" />
                上傳Excel檔案
              </b-button>
              <b-form-file
                style="display: none"
                type="file"
                @change="importExcel"
                id="upload"
                name=""
                ref="importFile"
              >
              </b-form-file>
            </div>

            <div class="mobile-w100">
              <!-- :fields="download excel -->
              <b-button
                @click="exporTemplateExcel"
                class="
                  btn btn-primary-green
                  text-white
                  btn-trigger-export
                  mr-sm-2
                  mobile-w100
                "
              >
                <feather-icon icon="FileTextIcon" class="mr-1" size="16" />
                導出表單模板
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="hide"
                variant="danger"
                class="custom-cancel mt-1 mt-sm-0 mobile-w100"
              >
                取消
              </b-button>
            </div>
          </div>
        </b-overlay>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
  BFormFile,
  BTable,
  BPagination,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import "animate.css";
import { required } from "@validations";
import useClientsHomeMemberSubmissionList from "./useClientsHomeMemberSubmissionList";
import clientStoreModule from "../clientStoreModule";
import * as xlsx from "xlsx";

export default {
  data() {
    return {
      required,
      loading: false, // Whether the table is loading
      clientMemberData_fields: {
        對應客戶身份證號碼: "id_number",
        家庭成員中文姓名: "name",
        關係: "relationship",
        手提電話: "mobile",
        職業: "position",
        年齡: "age",
        地址: "address",
        地址類型: "address_type",
        備註: "remark",
      },
      file_name:null
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BFormFile,
    BTable,
    BPagination,
    vSelect,
  },
  methods: {
    hide() {
      this.$router.replace("/clients");
    },
    getHeader(sheet) {
      const XLSX = xlsx;
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]); // worksheet['!ref'] Is the valid range of the worksheet

      let C;
      /* Get cell value start in the first row */
      const R = range.s.r; //Line / / column C
      let i = 0;
      for (C = range.s.c; C <= range.e.c; ++C) {
        var cell =
          sheet[
            XLSX.utils.encode_cell({ c: C, r: R })
          ]; /* Get the cell value based on the address  find the cell in the first row */
        var hdr = "UNKNOWN" + C; // replace with your desired default
        // XLSX.utils.format_cell Generate cell text value
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        if (hdr.indexOf("UNKNOWN") > -1) {
          if (!i) {
            hdr = "__EMPTY";
          } else {
            hdr = "__EMPTY_" + i;
          }
          i++;
        }
        headers.push(hdr);
      }

      return headers;
    },
    importExcel(e) {
      const files = e.target.files;
      this.file_name = files[0].name;

      function areEqual(array1, array2) {
        if (array1.length === array2.length) {
          return array1.every((element, index) => {
            if (element === array2[index]) {
              return true;
            }

            return false;
          });
        }
        return false;
      }

      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert("上傳格式不正確。請上傳xlsx格式");
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary",
          });

          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets

          const a = workbook.Sheets[workbook.SheetNames[0]];
          console.log("sh", a);
          const headers = this.getHeader(a);

          // if (!areEqual(headers, Object.keys(this.clientMemberData_fields))) {
          //   this.$bvToast.toast(
          //     `請使用我們的模板，您可以點擊“導出表單模板”按鈕下載模板`,
          //     {
          //       title: "Excel 文件格式未運行!",
          //       variant: "danger",
          //       autoHideDelay: 5000,
          //     }
          //   );
          //   return;
          // }

          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: headers,
          }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          // console.log("Read results", excellist); // At this point, you get an array containing objects that need to be processed

          this.setTable(headers, excellist);
        } catch (e) {
          return alert("Read failure!");
        }
      };
      fileReader.readAsBinaryString(files[0]);
      var input = document.getElementById("upload");
      input.value = "";
    },

    setTable(headers, excellist) {
      const checkList = []; // set check table content data

      const renderCheckList = (list, newList) => {
        list.forEach((item, index) => {
          const newObj = {};
          if (index > 0) {
            Object.keys(item).forEach((key) => {
              newObj[this.clientMemberData_fields[key]] = item[key];
            });
            newList.push(newObj);
          }
        });
      };
      renderCheckList(excellist, checkList);

      if (checkList.length <= 0) {
        this.$bvToast.toast(`您沒有在excel輸入任何內容`, {
          title: "請輸入內容!",
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }

      this.loading = true;
      store
        .dispatch("app-client/checkImportClientData", {'data':checkList, file_name : this.file_name})
        .then((response) => {
          this.loading = false;

          this.$swal({
            text: response.data.message,
            confirmButtonText: "確定",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    exportApiExport() {
      store.dispatch("app-client/exportClientData").then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Client Template"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
    exportSubmissionExcel(id) {
      store
        .dispatch("app-client/exportSubmissionData", { id })
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "Client Submission List"); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
  },
  mounted() {},
  setup(props) {
    const CLIENT_APP_STORE_MODULE_NAME = "app-client";

    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });

    const {
      fetchClientsHomeMemberSubmission,
      tableColumns,
      perPage,
      currentPage,
      totalClientsHomeMember,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientHomeMemberSubmissionListTable,
      refetchData,
      clientsHomeMemberSubmissionList,
      ability,
    } = useClientsHomeMemberSubmissionList();

    return {
      fetchClientsHomeMemberSubmission,
      tableColumns,
      perPage,
      currentPage,
      totalClientsHomeMember,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientHomeMemberSubmissionListTable,
      refetchData,
      clientsHomeMemberSubmissionList,
      ability,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
</style>
