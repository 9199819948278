<template>
  <section id="dashboard" class="bgCommon">
    <div class="p-2 p-sm-2">
      <b-row class="match-height">
        <b-col xl="4" md="6">
          <linechart :lineData="dataClient" />
        </b-col>
        <b-col xl="8" md="6">
          <finance-record
            :record-data="recordData"
            :loading="loading"
            :periodOptions="periodOptions"
            @refetch-data="fetchDashboardData"
          />
        </b-col>
      </b-row>
      <search-record-list :in_dashbord="true" />
    </div>
  </section>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import financeRecord from "./dash/financeRecord.vue";
import searchRecordList from "./dash/searchRecordList.vue";
import linechart from "./dash/linechart.vue";
import VueApexCharts from "vue-apexcharts";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    financeRecord,
    searchRecordList,
    linechart,
    FeatherIcon,
  },
  data() {
    return {
      loading: false,
      recordData: [
        {
          customClass: "",
          icon: "user-group",
          color: "#469C2C66",
          title: "",
          subtitle: "總客戶",
        },
        {
          customClass: "",
          icon: "clock",
          color: "#333333",
          title: "",
          subtitle: "總借貸記錄",
        },
        {
          customClass: "",
          icon: "money-bill-wave",
          color: "#333333",
          title: "",
          subtitle: "財務公司",
        },
        {
          customClass: "",
          icon: "file-invoice-dollar",
          color: "#469C2C66",
          title: "",
          subtitle: "總搜尋數",
        },
      ],
      periodOptions: [],
      dataClient: {
        series: [
          {
            name: "Clients",
            data: [0],
          },
        ],
        xaxisC: null,
        title: "客戶數目",
        num: "",
        lineColor: "#22c48b",
      },
    };
  },
  methods: {
    fetchDashboardData(period) {
      this.loading = true;
      store
        .dispatch("app/fetchDashboardData", period)
        .then((response) => {
          this.loading = false;
          const {
            total_number_of_clients,
            clients,
            number_of_clients,
            number_of_loans,
            number_of_suppliers,
            number_of_search_records,
            periodOptions,
          } = response.data;
          // Client
          this.dataClient.series[0].data = Object.values(clients);
          this.dataClient.num = total_number_of_clients;
          this.periodOptions = [
            ...periodOptions,
            ...[{ id: -1, period: "全部" }],
          ];
          this.dataClient.xaxisC = Object.keys(clients);
          this.dataClient.xaxisC = this.dataClient.xaxisC.map((item) => item);

          // recordData
          this.recordData[0].title = number_of_clients;
          this.recordData[1].title = number_of_loans;
          this.recordData[2].title = number_of_suppliers;
          this.recordData[3].title = number_of_search_records;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  created() {
    this.fetchDashboardData();
  },
};
</script>

<style lang="scss" scoped></style>