import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRecordList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/paypal/transaction/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  }
};
