import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useSuppliersList() {
  // Use toast
  const toast = useToast();

  const refSupplierListTable = ref(null);

  //Filter
  const companyFilter = ref(1);

  // Table Handlers
  const tableColumns = [
    // { key: "id", label: "ID", sortable: true },
    { key: "total_coins", label: "貨幣餘額", sortable: true },
    { key: "uploadrate", label: "上傳率", sortable: true },
    { key: "total_points", label: "積分", sortable: true },
    { key: "coin_records", label: "交易記錄" },
    { key: "company_name_cn", stickyColumn: true, label: "財務名稱(中文) ", sortable: true },
    { key: "company_name_en", label: "財務名稱(英文) ", sortable: true },
    { key: "ci_number", label: "公司註冊證書號碼(CI)", sortable: true },
    { key: "address", label: "地址" },
    { key: "contact_name", label: "聯絡人姓名", sortable: true },
    { key: "contact_phone", label: "聯絡人電話" },
    { key: "childs", label: "旗下帳戶" },
    { key: "is_active", label: "狀態" },
    { key: "actions" , label: "操作"},
    // { key: "mobile", label: "手提電話" },
    // { key: "email", label: "電郵地址" },
    // { key: "moneylender_number", label: "放債人號碼", sortable: true },
  ];
  const perPage = ref(10);
  const totalSuppliers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const suppliersList = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refSupplierListTable.value
      ? refSupplierListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSuppliers.value,
    };
  });

  const refetchData = () => {
    refSupplierListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, companyFilter],
    () => {
      refetchData();
    }
  );

  const fetchSuppliers = (ctx, callback) => {
    store
      .dispatch("app-supplier/fetchSuppliers", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        company_bit: companyFilter.value
      })
      .then((response) => {
        const { suppliers, total } = response.data;
        callback(suppliers);
        suppliersList.value = suppliers
        totalSuppliers.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching supplier list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const companyOptions = [
    {id: 1, name: '公司'},
    {id: 0, name: '街客'},
    {id: null, name: '全部'}
  ]

  return {
    fetchSuppliers,
    tableColumns,
    perPage,
    currentPage,
    totalSuppliers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSupplierListTable,
    ability,
    suppliersList,
    refetchData,
    companyFilter,
    companyOptions
  };
}
