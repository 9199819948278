<template>
  <component :is="contractData === undefined ? 'div' : 'b-card'">

    <b-alert variant="danger" :show="contractData === undefined">
      <h4 class="alert-heading">獲取合約數據時出錯</h4>
      <div class="alert-body">
        找不到相關合約記錄。請查看
        <b-link class="alert-link" :to="{ name: 'contracts-list' }">
          合約清單
        </b-link>
      </div>
    </b-alert>
    <contract-edit-tab-information
      v-if="contractData"
      :supplier-options="supplierOptions"
      :loan-type-options="loanTypeOptions"
      :interest-type-options="interestTypeOptions"
      :gender-options="genderOptions"
      :contract-data="contractData"
      :payment-method-options="paymentMethodOptions"
      :allow-to-leave.sync="allowToLeave"
      @refetch-data="refetchData"
      class="p-sm-2"
    />

    <!-- @refetch-data="refetchData" -->
  </component>
</template>

<script>
import { BCard, BAlert, BLink, BTabs, BTab } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import contractStoreModule from "../contractStoreModule";
import ContractEditTabInformation from "./ContractEditTabInformation.vue";
import loanStoreModule from "@/views/loan/loanStoreModule";

export default {

  components: {
    ContractEditTabInformation,
    BCard,
    BAlert,
    BTabs,
    BTab,
    BLink,
  },
  beforeRouteLeave(to, from, next) {
    // show your modal or prompt here
    // call next() to allow the user to leave
    // or call next(false) to prevent the user from leaving
    if(!this.allowToLeave){
       this.$swal({
        title: "確定離開頁面?",
        text: "你不能再獲得所有未保存的數據！",
        showCancelButton: true,
        confirmButtonText: "離開",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
            next();
        }
      })
    }else{
      next();
    }
   
  },
  setup() {
    const contractData = ref({ contracts: [{ id: "0" }] });
    const allowToLeave = ref(false)
    const CONTRACT_APP_STORE_MODULE_NAME = "app-contract";
    const LOAN_APP_STORE_MODULE_NAME = "app-loan";

    const testContract = {
      id: 0,
      contract_number: "",
      loan_type_id: 1,
      supplier_create_id: null,
      supplier_name_cn: null,
      supplier_name_en: null,
      moneylender_number: null,
      supplier_address: null,
      name_cn: "nick",
      name_en: "nick",
      id_number: "Y1002323",
      br_number: null,
      loan_date: "2023-01-01",
      amount: 10000,
      interest_type_id: 1,
      period: 12,
      is_monthly: 1,
      first_pay_date: null,
      annual_interest_rate: 47,
      pay_date: null,
      schedules: [],
      loan_information: {
        application_date: null,
        loan_purpose: "loan_purpose",
        customer_source: "customer_source",
        date_of_birth: null,
        mobile: "26612345",
        tel: "26612345",
        address: "address",
        gender_id: 1,
        rent_or_mortagage: 10000,
        living_expenses: 10000,
        company_name: "company_name",
        company_address: "company_address",
        position: "position",
        work_age: 1,
        payment_method: "payment_method",
        monthly_salary: 10000,
        pt_company_name: "pt_company_name",
        pt_company_address: "pt_company_address",
        pt_position: "pt_position",
        pt_work_age: 1,
        pt_payment_method: "pt_payment_method",
        pt_monthly_salary: 10000,
        histories: [
          {
            id: 0,
            supplier_name: "supplier_name",
            amount: 10000,
            payment_amount: 10000,
            remain_period: 0,
            average_amount: null,
            total_amount: null,
          },
        ],
      },
    };
    const contract = {
      id: 0,
      contract_number: "",
      loan_type_id: null,
      supplier_create_id: null,
      supplier_name_cn: null,
      supplier_name_en: null,
      moneylender_number: null,
      supplier_address: null,
      contact_name: null,
      name_cn: null,
      name_en: null,
      id_number: null,
      br_number: null,
      //get today date
      loan_date: new Date().toISOString().slice(0, 10),
      amount: null,
      interest_type_id: null,
      period: null,
      is_monthly: 1,
      first_pay_date: null,
      annual_interest_rate: null,
      pay_date: null,
      loan_information: {
        application_date: null,
        loan_purpose: null,
        customer_source: null,
        date_of_birth: null,
        gender_id: null,
        mobile: null,
        tel: null,
        address: null,
        rent_or_mortagage: null,
        living_expenses: null,
        company_name: null,
        company_address: null,
        position: null,
        work_age: null,
        payment_method: null,
        pt_company_name: null,
        pt_company_address: null,
        pt_position: null,
        pt_work_age: null,
        pt_payment_method: null,
        monthly_salary: null,
        pt_monthly_salary: null,
        histories: [],
        schedules: [],
        average_amount: null,
        total_amount: null,
      },
    };

    const isInitFinished = ref(false);

    if (!store.hasModule(CONTRACT_APP_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_APP_STORE_MODULE_NAME, contractStoreModule);
    if (!store.hasModule(LOAN_APP_STORE_MODULE_NAME))
      store.registerModule(LOAN_APP_STORE_MODULE_NAME, loanStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_APP_STORE_MODULE_NAME);
      if (store.hasModule(LOAN_APP_STORE_MODULE_NAME))
        store.unregisterModule(LOAN_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "contracts-create") {
        console.log("create");
        contractData.value = contract;
      } else {
        console.log("edit");
        store
          .dispatch("app-contract/fetchContract", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            contractData.value = response.data.contract;
           
            if (router.currentRoute.name == "contracts-copy") {
              contractData.value.id = 0;
              contractData.value.supplier_create_id = null;
              contractData.value.loan_information.id = 0;
              contractData.value.amount = null
              contractData.value.period = null
              contractData.value.pay_date = null
              contractData.value.payment_method_id = null
              contractData.value.interest_type_id = null

              contractData.value.first_pay_date = null
              contractData.value.annual_interest_rate = null
              contractData.value.average_amount = null
              contractData.value.total_amount = null
              contractData.value.total_interest = null
              //make the loan_information.histories array id to 0
              contractData.value.loan_information.histories.forEach(
                (history) => {
                  history.id = 0;
                }
              );
            }
          })
          .catch((error) => {
            console.log("error when fetching contract", error);
            if (error.response.status === 404) {
              contractData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    const supplierOptions = ref([]);
    const loanTypeOptions = ref([]);
    const interestTypeOptions = ref([]);
    const genderOptions = ref([]);
    const paymentMethodOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          supplier: true,
          loan_type: true,
          interest_type: true,
          gender: true,
          payment_method: true,
        })
        .then((response) => {
          supplierOptions.value = response.data.suppliers;
          loanTypeOptions.value = response.data.loan_types;
          interestTypeOptions.value = response.data.interest_types;
          genderOptions.value = response.data.genders;
          paymentMethodOptions.value = response.data.payment_methods;
        });
    };

    return {
      contractData,
      supplierOptions,
      loanTypeOptions,
      interestTypeOptions,
      genderOptions,
      refetchData,
      refetchOption,
      paymentMethodOptions,
      allowToLeave,
    };
  },
};
</script>
