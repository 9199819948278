<template>
  <div>
    <h4 class="d-sm-none">個人資料</h4>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="pt-sm-3" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- name_cn -->
            <b-col cols="12" md="6">
              <b-form-group
                label="中文姓名："
                label-for="name_cn"
                label-cols-md="3"
                :class="isNameRequired ? 'required' : ''"
              >
                <b-form-input
                  id="name_cn"
                  v-model="clientData.name_cn"
                  placeholder="輸入中文姓名"
                />
                <div class="error-text" v-if="!isNameRequired">
                  中文名稱 或 英文名稱 只需輸入其中一項
                </div>
              </b-form-group>
            </b-col>

            <!-- name_en -->
            <b-col cols="12" md="6">
              <b-form-group
                label="英文姓名："
                label-for="name_en"
                label-cols-md="3"
                :class="isNameRequired ? 'required' : ''"
              >
                <b-form-input
                  id="name_en"
                  v-model="clientData.name_en"
                  placeholder="輸入英文姓名"
                />
                <div class="error-text" v-if="!isNameRequired">
                  中文名稱 或 英文名稱 只需輸入其中一項
                </div>
              </b-form-group>
            </b-col>
            <hr />
            <!-- gender -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="性別"
                rules="required"
              >
                <b-form-group
                  label="性別"
                  label-for="gender"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-radio-group
                    v-slot="{ ariaDescribedby }"
                    style="margin-top: 10px"
                    v-model="clientData.gender_id"
                    name="gender"
                  >
                    <b-form-radio
                      name="radio-options"
                      :aria-describedby="ariaDescribedby"
                      v-for="gender of genderOptions"
                      :key="gender.gender + gender.id"
                      :value="gender.id"
                      >{{ gender.gender }}</b-form-radio
                    >
                  </b-form-radio-group>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- id_number -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="身份證號碼"
                rules="required"
              >
                <b-form-group
                  label="身份證號碼："
                  label-for="id_number"
                  label-cols-md="3"
                  class="required"
                >
                  <!-- 
                <masked-input class="form-mark-input"
                    mask="*******\(*\)" 
                    id="id_number" 
                    v-model="clientData.id_number"  
                    
                    placeholder="輸入身份證號碼" /> -->

                  <b-form-input
                    id="id_number"
                    v-model="clientData.id_number"
                    @input="IsHKID(clientData.id_number)"
                    :state="getValidationState(validationContext)"
                    placeholder="輸入身份證號碼"
                  />
                  <b-form-invalid-feedback :force-show="true">
                    {{ idError ? idError : validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr />
            <!-- date_of_birth -->
            <b-col cols="12" md="6">
              <b-form-group
                label="出生日期："
                label-for="date_of_birth"
                label-cols-md="3"
              >
                <flat-pickr
                  v-model="clientData.date_of_birth"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    maxDate: 'today',
                    dateFormat: 'Y-m-d',
                    maxDate:maxDate
                  }"
                  placeholder="輸入YYYY-MM-DD"
                />
              </b-form-group>
            </b-col>
            <!-- age -->
            <b-col cols="12" md="6">
              <b-form-group label="年齡：" label-for="c_age" label-cols-md="3">
                <div style="margin-top: 8px">{{ calAge }}</div>
                <!-- <b-form-input
                  id="c_age"
                  type="number"
                  v-model="clientData.age"
                  placeholder="輸入年齡"
                /> -->
              </b-form-group>
            </b-col>
            <hr />
            <!-- mobile -->
            <b-col cols="12" md="6">
              <b-form-group
                label="手提電話："
                label-for="c_mobile"
                label-cols-md="3"
              >
                <b-form-input
                  id="c_mobile"
                  v-model="clientData.mobile"
                  placeholder="輸入手提電話"
                />
              </b-form-group>
            </b-col>
            <!-- tel -->
            <b-col cols="12" md="6">
              <b-form-group
                label="住宅電話："
                label-for="c_tel"
                label-cols-md="3"
              >
                <b-form-input
                  id="c_tel"
                  v-model="clientData.tel"
                  placeholder="輸入住宅電話"
                />
              </b-form-group>
            </b-col>
            <hr />
            <!-- address -->
            <b-col cols="12" md="12">
              <div
                class="d-flex flex-wrap flex-md-nowrap align-items-center full_input"
              >
                <label>住宅地址：</label>
                <div class="input_wrap">
                  <b-form-input
                    id="c_address"
                    v-model="clientData.address"
                    placeholder="輸入住宅地址"
                  />
                </div>
              </div>
            </b-col>
            <hr />
            <!-- company_name_cn -->
            <b-col cols="12" md="6">
              <b-form-group
                label="公司名稱（中文）："
                label-for="company_name_cn"
                label-cols-md="3"
              >
                <b-form-input
                  id="company_name_cn"
                  v-model="clientData.company_name_cn"
                  placeholder="輸入公司名稱（中文）"
                />
              </b-form-group>
            </b-col>

            <!-- company_name_en -->
            <b-col cols="12" md="6">
              <b-form-group
                label="公司名稱（英文）："
                label-for="company_name_en"
                label-cols-md="3"
              >
                <b-form-input
                  id="company_name_en"
                  v-model="clientData.company_name_en"
                  placeholder="輸入公司名稱（英文）"
                />
              </b-form-group>
            </b-col>
            <hr />
            <!-- company_phone -->
            <b-col cols="12" md="6">
              <b-form-group
                label="公司電話："
                label-for="company_phone"
                label-cols-md="3"
              >
                <b-form-input
                  id="company_phone"
                  v-model="clientData.company_phone"
                  placeholder="輸入公司電話"
                />
              </b-form-group>
            </b-col>
            <hr />
            <!-- company_address -->
            <b-col cols="12">
              <div
                class="d-flex flex-wrap flex-md-nowrap align-items-center full_input"
              >
                <label>公司地址：</label>
                <div class="input_wrap">
                  <b-form-input
                    id="company_address"
                    v-model="clientData.company_address"
                    placeholder="輸入公司地址"
                  />
                </div>
              </div>
            </b-col>
            <hr />
            <!-- position -->
            <b-col cols="12" md="6">
              <b-form-group
                label="職位："
                label-for="c_position"
                label-cols-md="3"
              >
                <b-form-input
                  id="c_position"
                  v-model="clientData.position"
                  placeholder="輸入職位"
                />
              </b-form-group>
            </b-col>
            <!-- monthly_salary -->
            <b-col cols="12" md="6">
              <b-form-group
                label="每月薪金："
                label-for="monthly_salary"
                label-cols-md="3"
              >
                <b-form-input
                  id="monthly_salary"
                  v-model="clientData.monthly_salary"
                  type="number"
                  placeholder="輸入每月薪金"
                />
              </b-form-group>
            </b-col>
            <hr />
            <!-- working_time -->
            <b-col cols="12" md="6">
              <b-form-group
                label="工作時間："
                label-for="working_time"
                label-cols-md="3"
              >
                <b-form-input
                  id="working_time"
                  v-model="clientData.working_time"
                  placeholder="輸入工作時間"
                />
              </b-form-group>
            </b-col>
            <!-- work_age -->
            <b-col cols="12" md="6">
              <b-form-group
                label="工齡："
                label-for="work_age"
                label-cols-md="3"
              >
                <b-form-input
                  id="work_age"
                  type="number"
                  v-model="clientData.work_age"
                  placeholder="輸入工齡"
                />
              </b-form-group>
            </b-col>
            <hr />
            <!-- salary_date -->
            <b-col cols="12" md="6">
              <b-form-group
                label="出糧日期："
                label-for="salary_date"
                label-cols-md="3"
              >
                <b-form-input
                  id="salary_date"
                  v-model="clientData.salary_date"
                  placeholder="輸入出糧日期"
                />
              </b-form-group>
            </b-col>

            <!-- payment_method -->
            <b-col cols="12" md="6">
              <b-form-group
                label="出糧方式："
                label-for="payment_method"
                label-cols-md="3"
              >
                <b-form-input
                  id="payment_method"
                  v-model="clientData.payment_method"
                  placeholder="輸入出糧方式"
                />
              </b-form-group>
            </b-col>
            <hr />

            <!-- mpf_company -->
            <b-col cols="12" md="6">
              <b-form-group
                label="強積金公司："
                label-for="mpf_company"
                label-cols-md="3"
              >
                <b-form-input
                  id="mpf_company"
                  v-model="clientData.mpf_company"
                  placeholder="輸入強積金公司"
                />
              </b-form-group>
            </b-col>
            <hr />
            <!-- boss_name -->
            <b-col cols="12" md="6">
              <b-form-group
                label="上司名稱："
                label-for="boss_name"
                label-cols-md="3"
              >
                <b-form-input
                  id="company_name_cn"
                  v-model="clientData.boss_name"
                  placeholder="輸入上司名稱"
                />
              </b-form-group>
            </b-col>
            <!-- boss_phone -->
            <b-col cols="12" md="6">
              <b-form-group
                label="上司電話："
                label-for="boss_phone"
                label-cols-md="3"
              >
                <b-form-input
                  id="boss_phone"
                  v-model="clientData.boss_phone"
                  placeholder="輸入上司電話"
                />
              </b-form-group>
            </b-col>
            <hr />
            <!-- householder -->
            <b-col cols="12" md="6">
              <b-form-group
                label="業主/戶主本人："
                label-for="householder"
                label-cols-md="3"
              >
                <b-form-input
                  id="householder"
                  v-model="clientData.householder"
                  placeholder="輸入業主/戶主本人"
                />
              </b-form-group>
            </b-col>
            <!-- relationship -->
            <b-col cols="12" md="6">
              <b-form-group
                label="與顧客關係："
                label-for="relationship"
                label-cols-md="3"
              >
                <b-form-input
                  id="relationship"
                  v-model="clientData.relationship"
                  placeholder="輸入與顧客關係"
                />
              </b-form-group>
            </b-col>
            <hr />

            <!-- rent -->
            <b-col cols="12" md="6">
              <b-form-group
                label="月供/租金："
                label-for="rent"
                label-cols-md="3"
              >
                <b-form-input
                  id="rent"
                  v-model="clientData.rent"
                  placeholder="輸入月供/租金"
                />
              </b-form-group>
            </b-col>
            <hr />
            <!-- parttime_remark -->
            <b-col cols="12">
              <div
                class="d-flex flex-wrap flex-md-nowrap align-items-center full_input"
              >
                <label>兼職資料：</label>
                <div class="input_wrap">
                  <b-form-input
                    id="parttime_remark"
                    v-model="clientData.parttime_remark"
                    placeholder="輸入兼職資料"
                  />
                </div>
              </div>
            </b-col>
            <hr />
            <!-- parttime_remark -->
            <b-col cols="12">
              <div
                class="d-flex flex-wrap flex-md-nowrap align-items-center full_input"
              >
                <label>客戶備註：</label>
                <div class="input_wrap">
                  <b-form-input
                    id="remark"
                    v-model="clientData.remark"
                    placeholder="輸入客戶備註"
                  />
                </div>
              </div>
            </b-col>
            <hr />

            <!-- supplier -->
            <b-col cols="12" md="6">
              <!-- <validation-provider
                #default="validationContext"
                name="財務公司"
                rules="required"
              > -->
                <b-form-group
                  label="財務公司"
                  label-for="gender"
                  label-cols-md="3"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="clientData.supplier_create_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="supplierOptions"
                      :clearable="true"
                      label="company_name_cn"
                      :reduce="(option) => option.id"
                      placeholder="輸入銀行/財務機構"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                  <!-- <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback> -->
                </b-form-group>
              <!-- </validation-provider> -->
            </b-col>
            <hr />
          </b-row>

          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";

export default {
  data() {
    return {
      required,
      idError: null,
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    BFormRadio,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    hide() {
      this.$router.replace("/clients");
    },
    onSubmit() {
      if(this.clientData.hasOwnProperty('account_records'))
        delete this.clientData['account_records']

      if(this.clientData.hasOwnProperty('loans'))
        delete this.clientData['loans']

      if(this.clientData.hasOwnProperty('members'))
        delete this.clientData['members']

      if(this.clientData.hasOwnProperty('reject_records'))
        delete this.clientData['reject_records']

      if (this.idError || !this.isNameRequired) {
        return;
      }
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          if (this.clientData.id_number.indexOf("(") > 0) {
            this.clientData.id_number =
              this.clientData.id_number.slice(0, 7) +
              this.clientData.id_number.slice(8, 9);
          }
          store
            .dispatch("app-client/addClient", this.clientData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                confirmButtonText: "確定",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.$emit("refetch-data");

              if (this.clientData.id == 0) {
                this.clientData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    IsHKID(str) {
      var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      // basic check length
      if (str.length < 8) {
        this.idError = "身份證號碼" + str + "不正確";
        return false;
      }

      // handling bracket
      if (
        str.charAt(str.length - 3) == "(" &&
        str.charAt(str.length - 1) == ")"
      )
        str = str.substring(0, str.length - 3) + str.charAt(str.length - 2);

      // convert to upper case
      str = str.toUpperCase();

      // regular expression to check pattern and split
      var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;

      var matchArray = str.match(hkidPat);

      // not match, return false
      if (matchArray == null) {
        this.idError = "身份證號碼" + str + "不正確";
        return;
      }

      // the character part, numeric part and check digit part
      var charPart = matchArray[1];
      var numPart = matchArray[2];
      var checkDigit = matchArray[3];

      // calculate the checksum for character part
      var checkSum = 0;
      if (charPart.length == 2) {
        checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
        checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
      } else {
        checkSum += 9 * 36;
        checkSum += 8 * (10 + strValidChars.indexOf(charPart));
      }

      // calculate the checksum for numeric part
      for (var i = 0, j = 7; i < numPart.length; i++, j--)
        checkSum += j * numPart.charAt(i);

      // verify the check digit
      var remaining = checkSum % 11;
      var verify = remaining == 0 ? 0 : 11 - remaining;

      if (verify == checkDigit || (verify == 10 && checkDigit == "A")) {
        this.idError = "";
      } else {
        this.idError = "身份證號碼" + str + "不正確";
      }
    },
  },
  computed: {
    calAge() {
      if (this.clientData && this.clientData.date_of_birth) {
        const dateString = this.clientData.date_of_birth;
        var today = new Date();
        var birthDate = new Date(dateString);

        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        this.clientData.age = age + 1;
        return this.clientData.age;
      }
    },
    isNameRequired() {
      if (!this.clientData.name_cn && !this.clientData.name_en) {
        return false;
      } else {
        return true;
      }
    },
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
    supplierOptions: {
      type: Array,
      required: true,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const resetClientData = () => {
      props.clientData = JSON.parse(JSON.stringify({ id: 0 }));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetClientData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
</style>
