<template>
  <b-modal
    id="modal-edit-approve"
    centered
    title="審批放債人號碼"
    size="lg"
    @ok="handleEdit"
    ok-title="提交"
    cancel-title="取消"
    :ok-disabled="false"
  >
    <div>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-form class="pt-1" @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col cols="12">
                <b-form-group label="放債人牌照文件：">
                  <div class="px-3 py-2">
                    <img :src="imageUrl" class="w-100 border" />
                  </div>
                </b-form-group>
                <hr />
              </b-col>
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  name="放債人號碼"
                  rules="required"
                >
                  <b-form-group
                    label="放債人號碼："
                    label-for="moneylender_number"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      id="moneylender_number"
                      v-model="approveData.moneylender_number"
                      placeholder="輸入放債人號碼"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="備註："
                  label-for="remark"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="remark"
                    v-model="approveData.remark"
                    placeholder="輸入備註"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="審批："
                  label-for="is_active"
                  label-cols-md="3"
                  :class="required"
                >
                  <b-form-checkbox
                    :checked="approveData.approved"
                    switch
                    inline
                    v-model="approveData.approved"
                    value="1"
                    style="margin-top: 5px"
                  >
                    {{ approveData.approved ? "通過" : "不通過" }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BOverlay,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import axiosIns from "@/libs/axios";
import { ref, onMounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    BOverlay,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    };
  },
  props: {
    approveData: {
      type: Object,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-edit-approve", "#btnShow");
    },
    handleEdit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          
          axiosIns.post("/supplier_change_request/", this.approveData)
            .then((response) => {
              this.loading = false;
              console.log("response", response);
              this.$swal({
                text: response.data.message,
                confirmButtonText: "確定",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.$emit("refetch-data");

              this.hideModal()
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    }
  },
  setup() {
    const resetApproveData = () => {
      props.approveData = JSON.parse(JSON.stringify({ id: 0, }));
    };

    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetApproveData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    };
  },
};
</script>

<style></style>
