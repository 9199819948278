import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useSearchList() {
  // Use toast
  const toast = useToast();

  const refSearchListTable = ref(null);
  const refLogListTable = ref(null);
  
// const now = new Date()
// const today = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
  //Filter
  const companyFilter = ref(1);
  const supplierFilter = ref(null)
  const dateFromFilter = ref(null);
  const dateToFilter = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "created_at", label: "搜尋日期", sortable: true },
    { key: "supplier.company_name_cn", stickyColumn: true, label: "財務公司名稱 "},
    // { key: "search_key_words", label: "項目" },
    { key: "client.name_cn", label: "客戶姓名（中文）" },
    { key: "client.name_en", label: "客戶姓名（英文）" },
    { key: "client.id_number", label: "客戶身分證 "},
    { key: "actions", label: "詳情" },
    { key: "user.contact_name", label: "搜尋者" },
  ];
  const logTableColumns = [
    { key: "created_at", label: "修改時間", sortable: true  },
    { key: "supplier.company_name_cn", label: "財務公司名稱",  },
    { key: "creator", label: "修改人",  },
    { key: "client.id_number", label: "修改客戶",  },
    { key: "content", label: "修改主題",  },
    { key: "changedData", label: "修改內容",},
    { key: "delete", label: "刪除",},
  ];
  const perPage = ref(10);
  const totalSearchs = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("created_at");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refSearchListTable.value
      ? refSearchListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSearchs.value,
    };
  });

  const perLogPage = ref(10);
  const totalLog = ref(0);
  const currentLogPage = ref(1);
  const searchLogQuery = ref("");
  const logSortBy = ref("id");
  const isLogSortDirDesc = ref(true);

  const logDataMeta = computed(() => {
    const localItemsCount = refLogListTable.value
      ? refLogListTable.value.localItems.length
      : 0;
    return {
      from: perLogPage.value * (currentLogPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perLogPage.value * (currentLogPage.value - 1) + localItemsCount,
      of: totalLog.value,
    };
  });

  const refSearchCountListTable = ref(null);
  const searchCountTableColumn = [
    { key: "company_name_cn", label: "公司名稱(中文)" },
    { key: "search_records_count", label: "搜尋次數", sortable: true},
  ];
  const searchCountSortBy = ref("id");
  const isSearchCountSortDirDesc = ref(true);

  const refetchData = () => {
    refSearchListTable.value.refresh();
  };
  const refetchLogData = () => {
    refLogListTable.value.refresh();
  };
  const refetchSearchCounts = () => {
    refSearchCountListTable.value.refresh();
  };


  watch(
    [currentPage, perPage, searchQuery, companyFilter, supplierFilter, dateFromFilter, dateToFilter],
    () => {
      refetchData();
    },

  );

  watch(
    [currentLogPage, perLogPage, searchLogQuery, companyFilter, supplierFilter, dateFromFilter, dateToFilter],
    () => {
      refetchLogData();
    }
  );

  watch(
    [companyFilter, dateFromFilter, dateToFilter],
    () => {
      refetchSearchCounts();
    }
  );
  
  
  const getFormatedDate = (date) => {
        const mm = date.getMonth() + 1; // getMonth() is zero-based
        const dd = date.getDate();
        const hh = date.getHours();
        const ii = date.getMinutes();

        return [
          date.getFullYear(),
          (mm > 9 ? "" : "0") + mm,
          (dd > 9 ? "" : "0") + dd,
        ].join("-")
        // Don't delete this code (For UTC)
         + ' ' + [
          (hh > 9 ? "" : "0") + hh,
          (ii > 9 ? "" : "0") + ii,
          ].join(":");
  }

  const getStartTime = (date) => {
    if (date) {
      var start = new Date(date);
      // Don't delete this code (For UTC)
      start.setDate(start.getDate() - 1);
      start.setHours(16);
      start.setMinutes(0);
      start.setSeconds(0); 
      return getFormatedDate(start);
    }
  }

  const getEndTime = (date) => {
    if (date) {
      var end = new Date(date);
      end.setHours(16);
      end.setMinutes(0);
      end.setSeconds(0); 
      return getFormatedDate(end);
    }
  }

  const fetchSearchs = (ctx, callback) => {
    const startTime = dateFromFilter.value ? getStartTime(dateFromFilter.value) : null;
    const endTime = dateToFilter.value ? getEndTime(dateToFilter.value) : null;
    console.log('startTime', startTime)
    store
      .dispatch("app/fetchDashboardSearchData", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        company_bit: companyFilter.value,
        supplier_id: supplierFilter.value,
        date_from: startTime,
        date_to: endTime
      })
      .then((response) => {
        console.log(supplierFilter.value)
        const { search_records, total } = response.data;
        callback(search_records);
        totalSearchs.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching search list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchLogs = (ctx, callback) => {
    const startTime = dateFromFilter.value ? getStartTime(dateFromFilter.value) : null;
    const endTime = dateToFilter.value ? getEndTime(dateToFilter.value) : null;
    store
      .dispatch("app/fetchDashboardLogData", {
        q: searchLogQuery.value,
        perPage: perLogPage.value,
        page: currentLogPage.value,
        sortBy: logSortBy.value,
        sortDesc: isLogSortDirDesc.value,
        company_bit: companyFilter.value,
        supplier_id: supplierFilter.value,
        date_from: startTime,
        date_to: endTime
      })
      .then((response) => {
        const { supplier_logs, total } = response.data;
        callback(supplier_logs);
        totalLog.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching supplier logs list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };


  const fetchSearchCounts = (ctx, callback) => {
    const startTime = dateFromFilter.value
    const endTime = dateToFilter.value

    store
      .dispatch("app/fetchDashboardSearchCount", {
        sortDesc: isSearchCountSortDirDesc.value ? 1 : 0,
        company_bit: companyFilter.value,
        date_from: startTime,
        date_to: endTime
      })
      .then((response) => {
        const { search_count } = response.data;
        callback(search_count);
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching search list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const companyOptions = [
    {id: 1, name: '公司'},
    {id: 0, name: '街客'},
    {id: null, name: '全部'}
  ]

  return {
    fetchSearchs,
    tableColumns,
    perPage,
    currentPage,
    totalSearchs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSearchListTable,
    ability,
    refetchData,
    
    fetchLogs,
    logTableColumns,
    perLogPage,
    currentLogPage,
    totalLog,
    logDataMeta,
    searchLogQuery,
    logSortBy,
    isLogSortDirDesc,
    refLogListTable,
    refetchLogData,

    refSearchCountListTable,
    searchCountTableColumn,
    searchCountSortBy,
    isSearchCountSortDirDesc,
    fetchSearchCounts,

    companyFilter,
    companyOptions,
    supplierFilter, 
    dateFromFilter, 
    dateToFilter
  };
}
