<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <h4 class="d-flex align-items-center">
        <font-awesome-icon icon="fas fa-laptop-house" />
        <span class="ml-1">貸款資料</span>
      </h4>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-3" @submit.prevent="handleSubmit(onSubmit)" key="2">
          <div class="mt-2 mt-sm-3 position-relative">
            <b-row
              v-for="(loan, index) in loanData.loans"
              :key="index"
              ref="row"
              class="pb-2"
            >
              <b-col cols="12">
                <h4 class="ml-1 color4">貸款記錄（{{ index + 1 }}）</h4>
                <div
                  class="d-flex border rounded"
                  :class="
                    loan.message && Object.keys(loan.message).length > 0
                      ? 'redBorder'
                      : ''
                  "
                >
                  <b-row class="flex-grow-1 p-2">
                    <!-- client_id-->
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :vid="`v-loan_client_id_${index}`"
                        name="客戶"
                        rules="required"
                      >
                        <b-form-group
                          label="客戶："
                          :label-for="`loan_client_id_${index}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <div class="form-col-select">
                            <v-select
                              v-model="loan.client_id"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="clientsOptions"
                              :filter="clientSearch"
                              :clearable="false"
                              label="name_cn"
                              :reduce="(option) => option.id"
                              placeholder="輸入客戶"
                            >
                              <template #option="data">
                                {{ data.name_cn }} - {{ data.id_number }}
                              </template>
                              <template #selected-option="data">
                                {{ data.name_cn }} - {{ data.id_number }}
                              </template>
                            </v-select>
                            <feather-icon size="18" icon="ChevronDownIcon" />
                          </div>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- loan_date -->
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :vid="`v-loan_date_${index}`"
                        name="貸款日期"
                        rules="required"
                      >
                        <b-form-group
                          label="貸款日期："
                          :label-for="`loan_date_(${index})`"
                          label-cols-md="3"
                          class="required"
                        >
                          <flat-pickr
                            v-model="loan.loan_date"
                            class="form-control"
                            :config="{
                              allowInput: true,
                              dateFormat: 'Y-m-d', 
                              maxDate:maxDate,
                            }"
                             @input="calcLoanCloseDate(loan)"
                            placeholder="輸入貸款日期"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- supplier_id-->
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        name="銀行/財務機構"
                        :vid="`v-loan_supplier_id_${index}`"
                        rules="required"
                      >
                        <b-form-group
                          label="銀行/財務機構："
                          :label-for="`loan_supplier_id_${index}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <div class="form-col-select">
                            <v-select
                              v-model="loan.supplier_id"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="loanSupplierOptions"
                              :clearable="false"
                              label="company_name_cn"
                              :reduce="(option) => option.id"
                              placeholder="輸入銀行/財務機構"
                            />
                            <feather-icon size="18" icon="ChevronDownIcon" />
                          </div>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- amount -->
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :vid="`v-loan_amount_${index}`"
                        name="貸款本金"
                        rules="required"
                      >
                        <b-form-group
                          label="貸款本金"
                          :label-for="`loan_amount_${index}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <b-form-input
                            :id="`loan_amount_${index}`"
                            v-model="loan.amount"
                            type="number"
                            :state="getValidationState(validationContext)"
                            placeholder="輸入貸款本金"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- first_pay_date -->
                    <b-col cols="12" md="6">
                      <!-- <validation-provider
                        #default="validationContext"
                        :vid="`v-loan_first_pay_date_${index}`"
                        name="首次供款日期"
                        rules="required"
                      > -->
                      <b-form-group
                        label="首次供款日期："
                        :label-for="`loan_first_pay_date_${index}`"
                        label-cols-md="3"
                      >
                      
                        <!-- class="required" -->
                         <flat-pickr
                          v-model="loan.first_pay_date"
                          class="form-control"
                          :config="{ altInput:true,altFormat:'Y-m-d' , allowInput: true, dateFormat: 'Y-m-d' }"
                          placeholder="輸入首次供款日期"
                        />
                       <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback> -->
                        </b-form-group>
                      <!-- </validation-provider> -->
                    </b-col>

                    <!-- contract_end_date -->
                    <b-col cols="12" md="6">
                      <!-- <validation-provider
                        #default="validationContext"
                        :vid="`v-loan_contract_end_date_${index}`"
                        name="合約完結日期"
                        rules="required"
                      > -->
                        <b-form-group
                          label="合約完結日期："
                          :label-for="`loan_contract_end_date_${index}`"
                          label-cols-md="3"
                        >
                          <!-- class="required" -->
                          <flat-pickr
                            v-model="loan.contract_end_date"
                            class="form-control"
                            :config="{ altInput:true,altFormat:'Y-m-d' , allowInput: true, dateFormat: 'Y-m-d' }"
                            placeholder="輸入合約完結日期"
                          />
                          <!-- <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback> -->
                        </b-form-group>
                      <!-- </validation-provider> -->
                    </b-col>

                    <hr />

                    <!-- period -->
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :vid="`v-loan_period__${index}`"
                        name="總期數"
                        rules="required"
                      >
                        <b-form-group
                          label="總期數："
                          :label-for="`loan_period_${index}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <b-form-input
                            :id="`loan_period_${index}`"
                            type="number"
                            v-model="loan.period"
                            :state="getValidationState(validationContext)"
                            @keyup="calcLoanCloseDate(loan)"
                            placeholder="輸入總期數"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- is_monthly -->
                    <b-col cols="12" md="6">
                        <validation-provider
                        #default="validationContext"
                        :vid="`v-loan_is_monthly_${index}`"
                        name="供款週期"
                        rules="required"
                      >
                      <b-form-group
                        label="供款週期："
                        :label-for="`loan_is_monthly_${index}`"
                        label-cols-md="3"
                        class="required"
                      >
                        <div class="form-col-select">
                          <v-select
                            v-model="loan.is_monthly"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="loan_is_monthly"
                            :clearable="false"
                            label="name"
                            :reduce="(option) => option.id"
                            @input="calcLoanCloseDate(loan)"
                            placeholder="輸入供款週期"
                          />
                          <feather-icon size="18" icon="ChevronDownIcon" />
                        </div>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- min_paid -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="每月/最低供款："
                        :label-for="`loan_min_paid_(${index})`"
                        label-cols-md="3"
                      >
                        <b-form-input
                          :id="`loan_min_paid_(${index})`"
                          type="number"
                          v-model="loan.min_paid"
                          placeholder="輸入每月/最低供款"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- remain_period-->
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="尚欠期數："
                        :label-for="`loan_remain_period_${index}`"
                        label-cols-md="3"
                      >
                        <b-form-input
                          :id="`loan_remain_period_${index}`"
                          v-model="loan.remain_period"
                          placeholder="輸入尚欠期數"
                        />
                      </b-form-group>
                    </b-col>
                    <hr />

                    <!-- contract_number -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="合約編號："
                        :label-for="`loan_contract_number_${index}`"
                        label-cols-md="3"
                      >
                        <b-form-input
                          :id="`loan_contract_number_${index}`"
                          v-model="loan.contract_number"
                          placeholder="輸入合約編號"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- application_number -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="申請編號："
                        :label-for="`loan_application_number_${index}`"
                        label-cols-md="3"
                      >
                        <b-form-input
                          :id="`loan_application_number_${index}`"
                          v-model="loan.application_number"
                          placeholder="輸入申請編號"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- annual_interest_rate -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="年利率："
                        :label-for="`loan_annual_interest_rate_${index}`"
                        label-cols-md="3"
                      >
                        <b-form-input
                          :id="`loan_annual_interest_rate_${index}`"
                          type="number"
                          v-model="loan.annual_interest_rate"
                          placeholder="輸入年利率"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- total_interest -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="總利息："
                        :label-for="`loan_total_interest_${index}`"
                        label-cols-md="3"
                      >
                        <b-form-input
                          :id="`loan_total_interest_${index}`"
                          v-model="loan.total_interest"
                          placeholder="輸入總利息"
                        />
                      </b-form-group>
                    </b-col>
                    <hr />

                    <!-- loan_status_id -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="還款狀態："
                        :label-for="`loan_status_id_${index}`"
                        label-cols-md="3"
                      >
                        <div class="form-col-select">
                          <v-select
                            v-model="loan.loan_status_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="loanStatusOptions"
                            label="name"
                            :reduce="(option) => option.id"
                            placeholder="輸入還款狀態"
                          />
                          <feather-icon size="18" icon="ChevronDownIcon" />
                        </div>
                      </b-form-group>
                    </b-col>

                    <!-- admin_status_id -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="狀態："
                        :label-for="`admin_status_id_${index}`"
                        label-cols-md="3"
                      >
                        <div class="form-col-select">
                          <v-select
                            v-model="loan.admin_status_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="adminStatusOptions"
                            label="name"
                            :reduce="(option) => option.id"
                            placeholder="輸入狀態"
                          />
                          <feather-icon size="18" icon="ChevronDownIcon" />
                        </div>
                      </b-form-group>
                    </b-col>
                    <hr />

                    <!-- pay_date -->
                    <b-col cols="12" md="6">
                      <b-form-group label="供款日：" label-cols-md="3">
                        <b-form-input
                          :id="`loan_pay_date_${index}`"
                          v-model="loan.pay_date"
                          placeholder="輸入供款日"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- remark -->
                    <b-col cols="12" md="12">
                      <div
                        class="
                          d-flex
                          flex-wrap flex-md-nowrap
                          align-items-center
                          full_input
                        "
                      >
                        <label>備註：</label>
                        <div class="input_wrap">
                          <b-form-input
                            v-model="loan.remark"
                            placeholder="輸入備註"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <div
                    class="
                      d-flex
                      flex-column
                      justify-content-between
                      border-left
                      py-50
                      px-25
                    "
                  >
                    <feather-icon
                      size="16"
                      icon="PlusIcon"
                      class="cursor-pointer text-success"
                      @click="addItem(loanData.loans)"
                    />
                    <feather-icon
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer text-danger"
                      @click="removeItem(index, loanData.loans)"
                    />
                  </div>
                </div>
              </b-col>
              <div class="w-100 p-2" v-if="loan.message">
                <pre
                  class="ml-sm-2 mb-0 redColor"
                  style="white-space: break-spaces"
                  v-for="(loan, index) of Object.values(loan.message)"
                  :key="'message_' + index"
                  >{{ loan }}</pre
                >
              </div>
            </b-row>
          </div>

          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";

export default {
  data() {
    return {
      isInit: true,
      isCalc: false,
      loan_is_monthly: [
        { id: 1, name: "月供" },
        { id: 0, name: "半月供" },
      ],
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    hide() {
      this.$router.replace("/loans");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-loan/addLoan", { loans: this.loanData.loans })
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              if (this.loanData.id == 0) {
                this.loanData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    clientSearch(options, search) {
      var returnOptions = options.filter((item) => {
        return (
          item.name_cn.toLowerCase().includes(search.toLowerCase()) ||
          item.id_number.toLowerCase().includes(search.toLowerCase())
        );
      });
      return returnOptions;
    },
    addItem(arr) {
      arr.push(JSON.parse(JSON.stringify({ 
          id: 0, 
          first_pay_date: null,
          contract_end_date: null,
          pay_date: null,
          loan_date: this.formatTimeWithoutHMS(new Date()) 
        })));
    },
    removeItem(index, arr) {
      // check number of record
      let record = arr && arr.length;
      if (record <= 1) {
        this.makeToast("danger", "Warning", "請至少留有一個表單。");
      } else {
        // this.$swal({
        //   title: "你確定嗎?",
        //   text: "您的行動是最終的，您將無法檢索這記錄。",
        //   showCancelButton: true,
        //   confirmButtonText: "刪除",
        //   cancelButtonText: "取消",
        //   customClass: {
        //     confirmButton: "btn btn-primary",
        //     cancelButton: "btn btn-outline-danger ml-1",
        //   },
        //   buttonsStyling: false,
        // }).then((result) => {
        //   if (result.value) {
            arr.splice(index, 1);
        //   }
        // });
      }
    },
    // need
    formatTimeWithoutHMS(time){
      let newt = new Date(time),
        month,
        day,
        hour,
        min,
        sec;
      month = newt.getMonth() + 1;
      month = month < 10 ? "0" + month : month;

      day = newt.getDate();
      day = day < 10 ? "0" + day : day;

      return `${newt.getFullYear()}-${month}-${day}`;
    },
    calcLoanCloseDate(item) {
      if (
        item.loan_date &&
        item.period &&
        (item.is_monthly || item.is_monthly  == 0)
      ) {
        const data = {'loan_date': item.loan_date, 'period' : item.period, 'is_monthly': item.is_monthly}
     
        store
          .dispatch("app-loan/getContractEndDate",data )
          .then((response) => {
            item.first_pay_date = response.data.data.paid_date[0]
            item.contract_end_date = response.data.data.contract_end_date
            item.pay_date = response.data.data.paid_date.join(' , ');
          })
          .catch((error) => {
          
            this.$swal({
              text: JSON.stringify(error.response.data.message),
              icon: "error",
              cancelButtonText: "取消",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              showClass: {
                popup: "animate__animated animate__bounceIn",
              },
              buttonsStyling: false,
            });
          });
      }
    },
  },

  props: {
    loanData: {
      type: Object,
      required: true,
    },
    adminStatusOptions: {
      type: Array,
      required: true,
    },
    loanStatusOptions: {
      type: Array,
      required: true,
    },
    loanSupplierOptions: {
      type: Array,
      required: true,
    },
    clientsOptions: {
      type: Array,
      required: true,
    },
  },
  
  setup(props, { emit }) {
    const resetLoanData = () => {
      props.loanData = { 
        loans: [
          JSON.parse(JSON.stringify({ 
            id: 0, 
            first_pay_date: null,
            contract_end_date: null,
            pay_date: null,
            loan_date: this.formatTimeWithoutHMS(new Date()) }
        ))] };
    };

    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetLoanData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
</style>
