<template>
  <!-- modal PDF-->
  <b-modal
    id="modal-PDF"
    hide-footer
    hide-header
    cancel-variant="outline-secondary"
    centered
    size="lg"
    title=""
    :ok-disabled="false"
  >
    <b-card no-body class="bgColor3 mt-1">
      <b-card-body class="p-2">
        <div v-if="pdfData" class="pdf-info">
          <div class="bg-logo"></div>
          <div class="top mt-2 mb-3">
            <div
              class="d-flex flex-wrap flex-sm-nowrap justify-content-between"
            >
              <div>
                <div
                  v-if="pdfData.client && pdfData.client.id_number"
                  class="d-flex mobile-w100 align-items-center mb-1"
                >
                  <span>身分證號碼：</span
                  ><span>{{ pdfData.client && pdfData.client.id_number }}</span>
                </div>
                <!-- <div class="d-flex mobile-w100 align-items-center">
              <span>中文姓名：</span><span>{{ pdfData.client && pdfData.client.name_cn }}</span>
            </div>
            <div v-if="pdfData.client && pdfData.client.name_en" class="d-flex mobile-w100 align-items-center">
              <span>英文姓名：</span><span>{{ pdfData.client && pdfData.client.name_en }}</span>
            </div> -->

                <div class="d-flex mobile-w100 align-items-center">
                  <span>報告日期：</span><span>{{ pdfData.report_date }}</span>
                </div>
                <!-- <div class="d-flex mobile-w100 align-items-center">
              <span>數據日期：</span
              ><span>{{
                `${pdfData.start_period} - ${pdfData.end_period}`
              }}</span>
            </div> -->
              </div>

              <div class="mt-1 mt-sm-0">
                <div
                  class="d-flex flex-wrap mobile-w100 align-items-center mb-1"
                >
                  <span>搜尋次數：</span><span>{{ number_of_search }}</span>
                </div>
                <div class="d-flex flex-wrap mobile-w100 align-items-center">
                  <span>上傳次數：</span><span>{{ number_of_loan }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="middle mb-3">
            <p class="mb-0 title-size">拒絕記錄</p>

            <b-table
              ref="refRejectRecordTable"
              :tbody-tr-class="deleteLine"
              class="position-relative in_bg3"
              :items="pdfData.reject_records"
              responsive
              :fields="rejectTableColumns"
              primary-key="id"
              show-empty
              empty-text="未找到匹配的記錄"
            >
              <template #cell(record_date)="data">
                <div v-if="data.item.record_date">
                  {{ getCorrectDateTime(data.item.record_date, true) }}
                </div>
                <div v-else>
                  {{ getCorrectDateTime(data.item.insert_date, true) }}
                </div>
              </template>
              <template #cell(reject_option)="data">
                {{ data.item.reject_option.name }}
              </template>
            </b-table>
            <div class="mx-2 mt-1 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted"
                    >在{{ rejectDataMeta.of }}個記錄中，正在顯示
                    {{ rejectDataMeta.from }} 至
                    {{ rejectDataMeta.to }}個記錄</span
                  >
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="rejectCurrentPage"
                    :total-rows="totalRejects"
                    :per-page="rejectPerPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
          <hr class="w-100" />

          <div class="middle mb-3">
            <p class="mb-0 title-size">個人財務狀況</p>
            <b-table
              ref="refSearchLoanTable"
              class="position-relative in_bg3"
              :items="pdfData.loan_record"
              responsive
              :fields="loanTableColumns"
              primary-key="id"
              show-empty
              empty-text="未找到匹配的記錄"
            >
              <template #cell(is_monthly)="data">
                {{ data.item.is_monthly ? "月供" : "半月供" }}
              </template>
            </b-table>
            <div class="mx-2 mt-1 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted"
                    >在{{ loanDataMeta.of }}個記錄中，正在顯示
                    {{ loanDataMeta.from }} 至 {{ loanDataMeta.to }}個記錄</span
                  >
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="loanCurrentPage"
                    :total-rows="totalLoans"
                    :per-page="loanPerPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
          <hr class="w-100" />

          <div class="middle mb-3">
            <p class="mb-0 title-size">帳務記錄</p>
            <b-table
              ref="refAccountRecordTable"
              :tbody-tr-class="deleteLine"
              class="position-relative in_bg3"
              :items="pdfData.account_records"
              responsive
              :fields="accountTableColumns"
              primary-key="id"
              show-empty
              empty-text="未找到匹配的記錄"
            >
              <template #cell(record_date)="data">
                <div v-if="data.item.record_date">
                  {{ getCorrectDateTime(data.item.record_date, true) }}
                </div>
                <div v-else>
                  {{ getCorrectDateTime(data.item.insert_date, true) }}
                </div>
              </template>
              <template #cell(account_option)="data">
                <div v-if="data.item.account_option.id == 1">
                  <div class="bad-debts">
                    {{ data.item.account_option.name }}
                  </div>
                </div>
                <div v-else>{{ data.item.account_option.name }}</div>
              </template>
            </b-table>

            <div class="mx-2 mt-1 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted"
                    >在{{ accountDataMeta.of }}個記錄中，正在顯示
                    {{ accountDataMeta.from }} 至
                    {{ accountDataMeta.to }}個記錄</span
                  >
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="accountCurrentPage"
                    :total-rows="totalAccounts"
                    :per-page="accountPerPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
          <hr class="w-100" />
          <!-- hide -->
          <div class="middle mb-3" v-if="false">
            <p class="mb-0 title-size">過往搜尋記錄</p>
            <b-table
              ref="refSearchStatisticTable"
              class="position-relative in_bg3"
              :items="pdfData.statistic_record"
              responsive
              :fields="statisticTableColumns"
              primary-key="id"
              show-empty
              foot-clone
              empty-text="未找到匹配的記錄"
            >
              <template #foot(company_name_cn)> 總查看次數 </template>
              <template #foot(weekly)>
                {{ totalWeekly }}
              </template>
              <template #foot(monthly)>
                {{ totalMonthly }}
              </template>
              <template #foot(biannual)>
                {{ totalBiannual }}
              </template>
            </b-table>
          </div>

          <!-- hide -->
          <div class="bottom" v-if="false">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <p class="mb-0 title-size">7日搜尋詳情</p>
            </div>
            <b-table
              ref="refOldSearchDetailTable"
              class="position-relative in_bg3"
              :items="pdfData.detail_record"
              responsive
              :fields="searchDetailColumns"
              primary-key="id"
              show-empty
              empty-text="未找到匹配的記錄"
            >
              <!-- Column: search_date -->
              <template #cell(search_date)="data">
                <div class="d-flex" style="width: 160px">
                  {{ hkdUTC(data.item.search_date) }}
                </div>
              </template>
            </b-table>
          </div>

          <div class="bottom">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <p class="mb-0 title-size">15日搜尋詳情</p>
            </div>
            <b-table
              ref="refSearchDetailTable"
              class="position-relative in_bg3 search_detail_tb"
              :items="pdfData.new_search_records"
              responsive
              :fields="newSearchDetailColumns"
              primary-key="id"
              show-empty
              empty-text="未找到匹配的記錄"
            >
              <!-- Column: search_date -->
              <template #cell(date)="data">
                {{ getCorrectDateTime(data.item.date, true) }}
              </template>
              <!-- Column: description -->
              <template #cell(show_details)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                  {{ row.detailsShowing ? "隱藏" : "顯示" }}
                </b-button>
              </template>
              <template #row-details="data">
                <b-table
                  class="position-relative in_bg3 w-100"
                  :items="data.item.search_records"
                  thead-class="hidden_header"
                  responsive
                  :fields="searchDetailColumns"
                  primary-key="id"
                  show-empty
                  empty-text="未找到匹配的記錄"
                >
                  <template #cell(search_date)="data2">
                    {{ getCorrectDateTime(data2.item.search_date, true) }}
                  </template>
                </b-table>
              </template>
            </b-table>

            <div class="mx-2 mt-1 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted"
                    >在{{ detailDataMeta.of }}個記錄中，正在顯示
                    {{ detailDataMeta.from }} 至
                    {{ detailDataMeta.to }}個記錄</span
                  >
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="detailCurrentPage"
                    :total-rows="totalDetails"
                    :per-page="detailPerPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div class="d-flex w-100 justify-content-end pt-3">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="secondary"
            @click="hideModal"
            class="mr-sm-1 mobile-w100"
          >
            取消
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BModal,
  BButton,
  BTable,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";
import { ref, watch, computed, onMounted } from "@vue/composition-api";

export default {
  components: {
    BModal,
    BButton,
    BTable,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BPagination,
  },
  data() {
    return {
      rejectTableColumns: [
        { key: "record_date", label: "時間" },
        { key: "supplier.company_name_cn", label: "財務公司" },
        { key: "reject_option", label: "拒絕狀態" },
        { key: "remark", label: "備註" },
        { key: "status.name", label: "狀態" },
      ],
      loanTableColumns: [
        { key: "loan_date", label: "貸款日期" },
        { key: "supplier.company_name_cn", label: "銀行/財務機構" },
        { key: "amount", label: "貸款本金" },
        // { key: "min_paid", label: "每月/最低供款" },
        { key: "period", label: "總期數" },
        { key: "is_monthly", label: "供款週期" },
      ],
      accountTableColumns: [
        { key: "record_date", label: "時間" },
        { key: "supplier.company_name_cn", label: "財務公司" },
        { key: "account_option", label: "帳務狀態" },
        { key: "remark", label: "備註" },
        { key: "status.name", label: "狀態" },
      ],
      statisticTableColumns: [
        { key: "company_name_cn", label: "財務公司" },
        { key: "weekly", label: "7天" },
        { key: "monthly", label: "一個月" },
        { key: "biannual", label: "半年" },
      ],

      searchDetailColumns: [
        { key: "search_date", label: "搜尋時間" },
        { key: "supplier.company_name_cn", label: "銀行/財務機構" },
        { key: "cc", label: "" },
        { key: "ss", label: "" },
        ,
      ],
      newSearchDetailColumns: [
        { key: "date", label: "搜尋時間" },
        { key: "supplier", label: "銀行/財務機構" },
        { key: "accumulate_numbers", label: "累計次數" },
        { key: "show_details", label: "顯示全部 ", sortable: true },
      ],
      totalWeekly: null,
      totalMonthly: null,
      totalBiannual: null,
    };
  },
  directives: {
    Ripple,
  },
  props: {
    getPdfData: {
      type: Object,
      required: true,
    },
    number_of_search: {
      type: Number,
      required: true,
    },
    number_of_loan: {
      type: Number,
      required: true,
    },
  },
  // watch: {
  //   // whenever question changes, this function will run
  //   getPdfData: {
  //     handler(newValue, oldValue) {
  //       this.calcateTotal();
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-PDF", "#btnShow");
    },
    hkdUTC(time) {
      let newt = new Date(time),
        month,
        day,
        hour,
        min,
        sec;
      month = newt.getMonth() + 1;
      month = month < 10 ? "0" + month : month;

      day = newt.getDate();
      day = day < 10 ? "0" + day : day;

      hour = newt.getHours() + 8;
      hour = hour < 10 ? "0" + hour : hour;

      min = newt.getMinutes();
      min = min < 10 ? "0" + min : min;

      sec = newt.getSeconds();
      sec = sec < 10 ? "0" + sec : sec;

      return `${newt.getFullYear()}-${month}-${day} ${hour}:${min}:${sec}`;
    },

    deleteLine(item, type) {
      if (item && type === "row") {
        if (item.status.id == 2) {
          return "delete-line";
        } else {
          return "";
        }
      } else {
        return null;
      }
    },
    // calcateTotal() {
    //   this.totalWeekly = this.pdfData.statistic_record.reduce(
    //     (accumulator, object) => {
    //       return accumulator + object.weekly;
    //     },
    //     0
    //   );
    //   this.totalMonthly = this.pdfData.statistic_record.reduce(
    //     (accumulator, object) => {
    //       return accumulator + object.monthly;
    //     },
    //     0
    //   );
    //   this.totalBiannual = this.pdfData.statistic_record.reduce(
    //     (accumulator, object) => {
    //       return accumulator + object.biannual;
    //     },
    //     0
    //   );
    // },
  },
  setup(props) {
    const pdfData = ref(null);
    watch(
      () => props.getPdfData,
      (newVal) => {
        pdfData.value = newVal;
        if (
          pdfData.value.new_search_records &&
          pdfData.value.new_search_records.length > 0 &&
          pdfData.value.search_record &&
          pdfData.value.search_record.length > 0
        ) {
          pdfData.value.new_search_records =
            pdfData.value.new_search_records.map((item) => {
              const search_record = pdfData.value.search_record.filter(
                (ele) => ele.supplier_id === item.supplier_id
              );
              return { ...item, ...{ search_records: search_record } };
            });
          pdfData.value = { ...pdfData.value, new_search_records_forRef: [] };
          pdfData.value.new_search_records_forRef =
            pdfData.value.new_search_records;
        }
      }
    );
    // reject page change option
    const rejectCurrentPage = ref(1);
    const rejectPerPage = ref(10);
    const totalRejects = ref(0);

    const refRejectRecordTable = ref(null);
    const rejectDataMeta = computed(() => {
      const localItemsCount = refRejectRecordTable.value
        ? refRejectRecordTable.value.localItems.length
        : 0;
      return {
        from:
          rejectPerPage.value * (rejectCurrentPage.value - 1) +
          (localItemsCount ? 1 : 0),
        to:
          rejectPerPage.value * (rejectCurrentPage.value - 1) + localItemsCount,
        of: totalRejects.value,
      };
    });
    // loan page change option
    const loanCurrentPage = ref(1);
    const loanPerPage = ref(10);
    const totalLoans = ref(0);

    const refSearchLoanTable = ref(null);
    const loanDataMeta = computed(() => {
      const localItemsCount = refSearchLoanTable.value
        ? refSearchLoanTable.value.localItems.length
        : 0;
      return {
        from:
          loanPerPage.value * (loanCurrentPage.value - 1) +
          (localItemsCount ? 1 : 0),
        to: loanPerPage.value * (loanCurrentPage.value - 1) + localItemsCount,
        of: totalLoans.value,
      };
    });
    // account page change option
    const accountCurrentPage = ref(1);
    const accountPerPage = ref(10);
    const totalAccounts = ref(0);

    const refAccountRecordTable = ref(null);
    const accountDataMeta = computed(() => {
      const localItemsCount = refAccountRecordTable.value
        ? refAccountRecordTable.value.localItems.length
        : 0;
      return {
        from:
          accountPerPage.value * (accountCurrentPage.value - 1) +
          (localItemsCount ? 1 : 0),
        to:
          accountPerPage.value * (accountCurrentPage.value - 1) +
          localItemsCount,
        of: totalAccounts.value,
      };
    });
    // detail page change option
    const detailCurrentPage = ref(1);
    const detailPerPage = ref(10);
    const totalDetails = ref(0);

    const refSearchDetailTable = ref(null);
    const detailDataMeta = computed(() => {
      const localItemsCount = refSearchDetailTable.value
        ? refSearchDetailTable.value.localItems.length
        : 0;
      return {
        from:
          detailPerPage.value * (detailCurrentPage.value - 1) +
          (localItemsCount ? 1 : 0),
        to:
          detailPerPage.value * (detailCurrentPage.value - 1) + localItemsCount,
        of: totalDetails.value,
      };
    });

    const refetchTbData = (total, meta, perPage, current, target) => {
      total.value = props.getPdfData[target].length;
      const min = Number(meta.value.from - 1);
      const max = Number(perPage.value * current.value - 1);
      if (target !== "new_search_records") {
        pdfData.value[target] = props.getPdfData[target].filter(
          (ele, index) => index >= min && index <= max
        );
      } else {
        if (pdfData.value.hasOwnProperty("new_search_records_forRef")) {
          pdfData.value[target] =
            pdfData.value.new_search_records_forRef.filter(
              (ele, index) => index >= min && index <= max
            );
        }
      }
    };

    watch(
      [
        rejectCurrentPage,
        loanCurrentPage,
        accountCurrentPage,
        detailCurrentPage,
        pdfData,
      ],
      () => {
        refetchTbData(
          totalRejects,
          rejectDataMeta,
          rejectPerPage,
          rejectCurrentPage,
          "reject_records"
        );
        refetchTbData(
          totalLoans,
          loanDataMeta,
          loanPerPage,
          loanCurrentPage,
          "loan_record"
        );
        refetchTbData(
          totalAccounts,
          accountDataMeta,
          accountPerPage,
          accountCurrentPage,
          "account_records"
        );
        refetchTbData(
          totalDetails,
          detailDataMeta,
          detailPerPage,
          detailCurrentPage,
          "new_search_records"
        );
      }
    );

    return {
      pdfData,
      refRejectRecordTable,
      rejectDataMeta,
      rejectCurrentPage,
      rejectPerPage,
      totalRejects,
      refSearchLoanTable,
      loanDataMeta,
      loanCurrentPage,
      loanPerPage,
      totalLoans,
      refAccountRecordTable,
      accountDataMeta,
      accountCurrentPage,
      accountPerPage,
      totalAccounts,
      refSearchDetailTable,
      detailDataMeta,
      detailCurrentPage,
      detailPerPage,
      totalDetails,
    };
    //
  },
};
</script>


<style lang="scss">
.delete-line {
  position: relative;
  td:first-child {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 25px;
      left: 40px;
      width: 90vw;
      max-width: 780px;
      height: 2px;
      background-color: #000000;
    }
  }
}
#modal-PDF {
  user-select: none;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
  .modal-dialog {
    max-width: 1000px;
    overflow: hidden;
  }
  .bg-logo {
    width: 3000px;
    height: 3000px;
    left: -1000px;
    top: 0px;
    position: absolute;
    opacity: 0.1;
    background-image: url("~@/assets/images/logo/logo.png");
    background-repeat: round;
    z-index: 0;
    transform: rotate(45deg);
  }
  button {
    z-index: 1;
    position: relative;
  }
  .bad-debts {
    display: inline-block;
    color: #fff;
    background-color: #ea5455;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    border-radius: 0;
  }
  .search_detail_tb {
    td {
      width: 150px;
    }
    tr.b-table-details {
      > td {
        padding: 0px;
      }
      td {
        background: #d4d4d4;
      }
    }
  }
  .hidden_header {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  #modal-PDF .bg-logo {
    // top: 0px;
  }
}
@media screen and (max-width: 575px) {
}
</style>