import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/page/list',{params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchPage(ctx, qureyParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/page`, {params:qureyParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addPage(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/page', data,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }
            )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deletePage(ctx, qureyParams){
      return new Promise((resolve, reject) => {
        axiosIns.delete(`/page`, {params:qureyParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    

  },
}
