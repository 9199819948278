export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    action: "read",
    resource: "Auth",
  },
  {
    title: "客戶",
    route: "clients-list",
    icon: "UsersIcon",
    action: "read",
    resource: "client",
  },
  {
    title: "財務公司",
    route: "suppliers-list",
    icon: "MonitorIcon",
    action: "read",
    resource: "supplier",
  },
  {
    title: "貸款記錄",
    route: "loans-list",
    icon: "CreditCardIcon",
    action: "read",
    resource: "loan",
  },
  {
    title: "合同管理",
    route: "contracts-list",
    icon: "FileTextIcon",
    action: "read",
    resource: "Auth",
  },
  {
    title: "充值記錄",
    route: "payment-record-list",
    icon: "DollarSignIcon",
    action: "read",
    resource: "payment",
  },
  {
    title: "審批放債人號碼",
    route: "approves-list",
    icon: "ApertureIcon",
    action: "read",
    resource: "supplier",
  },
/*   {
    title: "中獎記錄",
    route: "wheel-get-award-record",
    icon: "GiftIcon",
    action: "read",
    resource: "draw",
  }, */
  {
    title: "搜尋報告",
    route: "suppliers-searchcount-list",
    icon: "SearchIcon",
    action: "read",
    resource: "supplier",
  },
  {
    title: "頁面",
    route: "pages-list",
    icon: "BookmarkIcon",
    action: "read",
    resource: "page",
  },
  {
    title: "設定",
    route: "",
    icon: "SettingsIcon",
    children: [

      {
        title: "獎品設定",
        route: "wheel-award-list",
        // icon: "GiftIcon",
        action: "read",
        resource: "drawsetting",
      },
      {
        title: "員工資料",
        route: "users-list",
        action: "read",
        resource: "user",
      },
      {
        title: "員工職位及權限",
        route: "roles-list",
        action: "read",
        resource: "user",
      },
    ],
  },
];
