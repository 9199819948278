import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useApprovesList() {
  // Use toast
  const toast = useToast();

  const refApproveListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "請求編號", sortable: true },
    { key: "supplier", label: "放債人名稱", sortable: true },
    { key: "moneylender_number", label: "放債人號碼" },
    { key: "remark", label: "備註" },
    { key: "user", label: "批核者" },
    { key: "handled", label: "已處理", sortable: true },
    { key: "approved", label: "已審批", sortable: true },
    // { key: "created_at", label: "上傳日期"},
    { key: "actions" , label: "操作"},
  ];
  const perPage = ref(10);
  const totalApproves = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const approvesList = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refApproveListTable.value
      ? refApproveListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalApproves.value,
    };
  });

  const refetchData = () => {
    refApproveListTable.value.refresh();
  };


  watch(
    [currentPage, perPage, searchQuery],
    () => {
      refetchData();
    }
  );

  const fetchApproves = (ctx, callback) => {
    store
      .dispatch("app-approve/fetchApproves", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const { supplier_change_requests, total } = response.data;
        callback(supplier_change_requests);
        // console.log(approves)
        approvesList.value = supplier_change_requests
        totalApproves.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "獲取修改資料請求清單時出錯",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchApproves,
    tableColumns,
    perPage,
    currentPage,
    totalApproves,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refApproveListTable,
    ability,
    approvesList,
    refetchData,
  };
}
