import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useAwardsList() {
  // Use toast
  const toast = useToast();

  const refAwardListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "ID" },
    { key: "reward_title", label: "獎品名稱"},
    { key: "reward_description", label: "獎品詳情" },
    { key: "amount", label: "獎品價值 " },
    { key: "winning_percentage", label: "得獎率" },
    { key: "is_coin", label: "是否幣" },
    { key: "actions" , label: "操作"},
  ];

  const totalAwards = ref(0);

  const refetchData = () => {
    refAwardListTable.value.refresh();
  };



  const fetchAwards = (ctx, callback) => {
    store
      .dispatch("app-award/fetchWheelAwardList")
      .then((response) => {
        const { lucky_draw_lists } = response.data;
        callback(lucky_draw_lists);
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching award list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchAwards,
    tableColumns,
    totalAwards,
    refAwardListTable,
    ability,
    refetchData,
  };
}
