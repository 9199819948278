<template>
  <component :is="loanData === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="loanData === undefined">
      <h4 class="alert-heading">獲取貸款數據時出錯</h4>
      <div class="alert-body">
        找不到相關貸款記錄。請查看
        <b-link class="alert-link" :to="{ name: 'loans-list' }">
          貸款清單
        </b-link>
      </div>
    </b-alert>
    <loan-edit-tab-information
      v-if="loanData"
      :loan-supplier-options="loanSupplierOptions"
      :admin-status-options="adminStatusOptions"
      :loan-status-options="loanStatusOptions"
      :clients-options="clientsOptions"
      :loan-data="loanData"
      class="p-sm-2"
    />

    <!-- @refetch-data="refetchData" -->
  </component>
</template>

<script>
import { BCard, BAlert, BLink, BTabs, BTab } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import store from "@/store";
import loanStoreModule from "../loanStoreModule";
import LoanEditTabInformation from "./LoanEditTabInformation.vue";

export default {
  components: {
    LoanEditTabInformation,
    BCard,
    BAlert,
    BTabs,
    BTab,
    BLink,
  },
  setup() {
    // need
    const formatTimeWithoutHMS = (time) => {
      let newt = new Date(time),
        month,
        day,
        hour,
        min,
        sec;
      month = newt.getMonth() + 1;
      month = month < 10 ? "0" + month : month;

      day = newt.getDate();
      day = day < 10 ? "0" + day : day;

      return `${newt.getFullYear()}-${month}-${day}`;
    };

    const loanData = ref({
      loans: [
        {
          id: "0",
          first_pay_date: null,
          contract_end_date: null,
          pay_date: null,
          loan_date: formatTimeWithoutHMS(new Date()) 
        },
      ],
    });

    const LOAN_APP_STORE_MODULE_NAME = "app-loan";

    const loan = { id: 0, is_active: 1, childs: [] };

    if (!store.hasModule(LOAN_APP_STORE_MODULE_NAME))
      store.registerModule(LOAN_APP_STORE_MODULE_NAME, loanStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LOAN_APP_STORE_MODULE_NAME))
        store.unregisterModule(LOAN_APP_STORE_MODULE_NAME);
    });

    // const refetchData = () => {
    //   if (router.currentRoute.name == "loans-create") {
    //     loanData.value = loan;
    //   } else {
    //     store
    //       .dispatch("app-loan/fetchLoan", { id: router.currentRoute.params.id })
    //       .then((response) => {
    //         loanData.value = response.data.loan;
    //       })
    //       .catch((error) => {
    //         console.log("error when fetching loan", error);
    //         if (error.response.status === 404) {
    //           loanData.value = undefined;
    //         }
    //       });
    //   }
    // };

    onMounted(() => {
      // refetchData();
      refetchOption();
    });

    const adminStatusOptions = ref([]);
    const loanStatusOptions = ref([]);
    const loanSupplierOptions = ref([]);
    const clientsOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          admin_status: true,
          loan_status: true,
          supplier: true,
          client: true,
        })
        .then((response) => {
          adminStatusOptions.value = response.data.admin_statuses;
          loanStatusOptions.value = response.data.loan_statuses;
          loanSupplierOptions.value = response.data.suppliers;
          clientsOptions.value = response.data.clients;
        });
    };

    return {
      loanData,
      adminStatusOptions,
      loanStatusOptions,
      loanSupplierOptions,
      clientsOptions,
      refetchOption,
    };
  },
};
</script>
