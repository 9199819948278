import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useRecordsList() {
  // Use toast
  const toast = useToast();

  const refRecordListTable = ref(null);

  // Table Handlers
  const tableColumns =  [
    { key: "transaction_id", label: "交易編號" },
    { key: "company_name_cn", label: "購買公司" },
    { key: "buyer_contact_name", label: "買家姓名" },
    { key: "amount", label: "花費港幣" },
    { key: "coin_amount", label: "獲得幣" },
    { key: "created_at", label: "交易時間" },
  ];
  const perPage = ref(10);
  const totalRecords = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refRecordListTable.value
      ? refRecordListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    };
  });

  const refetchData = () => {
    refRecordListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery],
    () => {
      refetchData();
    }
  );

  const fetchRecords = (ctx, callback) => {
    store
      .dispatch("app-payment/fetchRecordList", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const { transactions, total } = response.data;
        callback(transactions);
        totalRecords.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching record list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchRecords,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRecordListTable,
    ability,
    refetchData,
  };
}
