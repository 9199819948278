export default [
    {
        path: "/contracts",
        name: "contracts-list",
        component: () => import("@/views/contract/contracts-list/ContractsList.vue"),
        meta:{
            action: "read",
            resource: "Auth",
        }
    },
    {
        path: "/contracts/create",
        name: "contracts-create",
        component: () => import("@/views/contract/contracts-edit/ContractEdit.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    {
        path: "/contracts/edit/:id",
        name: "contracts-edit",
        component: () => import("@/views/contract/contracts-edit/ContractEdit.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    {
        path: "/contracts/copy/:id",
        name: "contracts-copy",
        component: () => import("@/views/contract/contracts-edit/ContractEdit.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    {
        path: "/contracts/schedule/:id",
        name: "contracts-schedule-edit",
        component: () => import("@/views/contract/contracts-edit/ContracScheduleEdit.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    // {
    //     path: "/contracts/detail/:id",
    //     name: "contracts-detail",
    //     component: () => import("@/views/contract/contracts-detail/ContractsDetail.vue"),
    //     meta:{
    //         action:"read",
    //         resource:"Auth"
    //     }
    // }


]