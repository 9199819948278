import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWheelAwardList(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/lucky_draw/list")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchWheelGetAwardList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/lucky_draw/reward/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchWheelPredict(ctx,number) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/lucky_draw/predict`, { params: {"number_of_draw":number}})
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editWheelAward(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/lucky_draw", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    
  }
};
