<template>
  <div class="p-2 bgCommon">
    <modal-change-award
      :awardData="awardData"
      @refetch-data="refetchData"
    ></modal-change-award>
    <modal-predict />
    <!-- Table Top -->

    <div
      class="d-flex flex-wrap mt-2 mb-1 align-items-center justify-content-between"
    >
      <span class="title-size text-nowrap">獎品設定</span>
      <b-button
        class="mobile-w100 mt-2 mt-sm-0"
        variant="primary"
        @click="$bvModal.show('modal-predict')"
      >
        <span class="text-nowrap">
          <feather-icon
            size="16"
            class="mr-50"
            style="position: relative; top: 1px"
            icon="AirplayIcon"
          />模擬抽獎</span
        >
      </b-button>
    </div>
    <b-card no-body class="mb-0">
      <b-card-body class="p-2">
        <b-table
          ref="refAwardListTable"
          class="position-relative"
          :items="fetchAwards"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="未找到匹配的記錄"
        >
          <!-- Column: winning_percentage -->
          <template #cell(winning_percentage)="data">
            {{ data.item.winning_percentage }}%
          </template>

          <!-- Column: is_coin -->
          <template #cell(is_coin)="data">
            <feather-icon
              v-if="data.item.is_coin"
              size="24"
              icon="CheckIcon"
            ></feather-icon>
            <feather-icon v-else size="24" icon="XIcon"></feather-icon>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              class="action-trigger-btn"
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <!-- v-if="ability.can('update', 'award')" -->
              <b-dropdown-item
                v-if="ability.can('update', 'drawsetting')"
                @click="triggerEditAwardModal(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">修改資料</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import ModalChangeAward from "../modal/ModalChangeAward";
import ModalPredict from "../modal/ModalPredict";
import useAwardsList from "./useAwardsList";
import awardStoreModule from "../../awardStoreModule";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalChangeAward,
    ModalPredict,
  },
  methods: {
    triggerEditAwardModal(item) {
      this.awardData = { ...item };
      this.$bvModal.show("modal-change-award");
    },
  },
  data() {
    return {
      awardData: {},
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-award";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, awardStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchAwards,
      tableColumns,
      refAwardListTable,
      refetchData,
      ability,
    } = useAwardsList();

    return {
      fetchAwards,
      tableColumns,
      refAwardListTable,
      refetchData,
      ability,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search-primary {
  // width: calc(100% - 700px);
  width: calc(100% - 500px);
}
.top-button-list {
  // width: 500px;
  margin: 0;
  .btn {
    width: 150px;
    margin: 0 auto;
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .search-primary {
    width: 100%;
  }
  .top-button-list {
    margin-left: auto;
    margin-right: 20px;
    .btn {
      width: 150px;
      margin: 0 auto;
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .search-primary {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .top-button-list {
    margin: 0;
    width: 100%;
  }
}
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
    