<template>
  <!-- modal deposit detail record-->
  <b-modal
    id="modal-deposit-detail-record"
    cancel-variant="outline-secondary"
    centered
    size="md"
    :ok-disabled="false"
    headerClass="px-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >
    <template #modal-header="{ close }">
      <h3 class="m-0">充值記錄詳情</h3>
      <a @click="close()">
        <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
      </a>
    </template>
    <div v-if="Object.keys(recordDetailsData).length > 1" class="recordDetailsList">
      <div class="mb-3">
        <div>
          <label>交易編號:</label>
          {{ recordDetailsData.transaction_id }}
        </div>

        <div>
          <label>付款編號:</label>
          {{ recordDetailsData.payment_id }}
        </div>

        <div>
          <label>買家編號:</label>
          {{ recordDetailsData.payer_id }}
        </div>

        <div>
          <label>購買公司:</label>
          {{ recordDetailsData.company_name_cn }}
        </div>

        <div>
          <label>買家姓名:</label>
          {{ recordDetailsData.buyer_contact_name }}
        </div>

        <div>
          <label>花費港幣:</label>
          {{ recordDetailsData.amount }}
        </div>

        <div>
          <label>獲得幣:</label>
          {{ recordDetailsData.coin_amount }}
        </div>

        <div>
          <label>交易時間:</label>
          {{ getCorrectDateTime(recordDetailsData.created_at) }}
        </div>

        <div>
          <label>城市:</label>
          {{ recordDetailsData.city }}
        </div>

        <div>
          <label>地址:</label>
          {{ recordDetailsData.address1 ? recordDetailsData.address1  : '' + ' ' + recordDetailsData.address2 ? recordDetailsData.address2 : '' }}
        </div>

        <div>
          <label>電郵:</label>
          {{ recordDetailsData.email }}
        </div>

        <div>
          <label>付款者名稱:</label>
          {{ recordDetailsData.first_name }}
        </div>

        <div>
          <label>付款者姓氏:</label>
          {{ recordDetailsData.last_name }}
        </div>
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="cancel"
        >
          取消
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {
  BModal,
  BOverlay,
  BButton,
  BTable,
  BFormInput,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    BTable,
    BFormInput,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BPagination,
    vSelect,
  },
  data() {
    return {};
  },
  directives: {
    Ripple,
  },
  methods: {
    hideModal() {
      this.$root.$emit(
        "bv::hide::modal",
        "modal-deposit-detail-record",
        "#btnShow"
      );
    },
  },
  props: {
    recordDetailsData: {
      type: Object,
      required: true,
    },
  },
  setup() {},
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
  .recordDetailsList {
    label{
      width: 6rem;
      font-size: 1rem;
    }
  }
</style>