<template>
  <div class="p-2 bgCommon">
    <div
      class="d-flex flex-wrap mt-2 mb-1 align-items-center justify-content-between"
    >
      <div class="d-flex flex-wrap align-items-center mobile-w100">
        <div>
          <!-- <feather-icon size="16" icon="SearchIcon"></feather-icon> -->
          <span class="title-size text-nowrap"
            >搜尋記錄 {{ date_from }} - {{ date_to }}</span
          >
        </div>
        <!-- <div class="form-col-select ml-2">
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block"
          />
          <feather-icon size="18" icon="ChevronDownIcon" />
        </div> -->
        <div class="form-col-select ml-2">
          <v-select
            style="width: 300px"
            v-model="supplier_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="loanSupplierOptions"
            label="company_name_cn"
            :reduce="(option) => option.id"
            placeholder="輸入銀行/財務機構"
          />
          <feather-icon size="18" icon="ChevronDownIcon" />
        </div>
      </div>
      <div class="d-flex">
        <b-button
           @click="exportSearchRecord"
          variant="primary"
          class="mobile-w100 mt-2 mt-sm-0 mr-1"
        >
          <feather-icon icon="FileTextIcon" class="mr-1" size="16" />
          <span class="text-nowrap">導出表單</span>
        </b-button>

        <v-select
          v-model="companyBit"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="companyOptions"
          label="name"
          :clearable="false"
          :reduce="(option) => option.id"
          placeholder="選擇公司／街客"
          class="mr-1"
          style="min-width: 150px"
        />
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
        >
        </el-date-picker>
      </div>
    </div>
    <b-card no-body class="">
      <b-card-body class="p-2">
        <b-overlay
          variant="white"
          :show="loading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <b-table
            ref="refSupplierSearchCountListTable"
            class="position-relative"
            :items="suppliers"
            responsive
            sticky-header
            :fields="tableColumns"
            primary-key="company_name_cn"
            show-empty
            empty-text="未找到匹配的記錄"
          >
            <template #cell(company_name_cn)="data">
              <span class="company_name color1" @click="setSupplierId(data.item.id)">{{
                data.item.company_name_cn
              }}</span>
            </template>
            <template #cell(uploadrate)="data">
              <span v-if="data.item.client_counts">{{
                (
                  (data.item.valid_client_counts * 100) /
                  data.item.client_counts
                ).toFixed(2) + "%"
              }}</span>
            </template>
            <template #cell(empty_rate)="data">
              <span v-if="data.item.search_records_count">{{
                (
                  (data.item.search_records_empty_count * 100) /
                  data.item.search_records_count
                ).toFixed(2) + "%"
              }}</span>
            </template>
          </b-table>

          <!-- <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                  {{ dataMeta.to }}個記錄</span
                >
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div> -->
        </b-overlay>
      </b-card-body>
    </b-card>
    <search-record-list
      v-if="date_from || date_to"
      :supplier_id="supplier_id"
      v-show="supplier_id"
      :date_to="date_to"
      :date_from="date_from"
      :in_dashbord="false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import store from "@/store";
import supplierStoreModule from "../supplierStoreModule";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import searchRecordList from "@/views/dashboard/dash/searchRecordList.vue";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BOverlay,
    vSelect,
    searchRecordList,
  },
  data() {
    return {
      companyOptions: [
        { id: 1, name: "公司" },
        { id: 0, name: "街客" },
      ],
      companyBit: 0,
      dateRange: [],
      suppliers: [],
      loanSupplierOptions: [],
      date_from: null,
      date_to: null,
      supplier_id: null,
      // perPage: 10,
      // totalRows: 0,
      // currentPage: 1,
      // perPageOptions: [10, 25, 50, 100],
      // sortBy: "id",
      // isSortDirDesc: true,
      tableColumns: [
        {
          key: "company_name_cn",
          stickyColumn: true,
          label: "財務名稱(中文) ",
          sortable: true,
        },
        { key: "company_name_en", label: "財務名稱(英文) ", sortable: true },
        { key: "uploadrate", label: "上傳率" },
        { key: "search_records_count", label: "搜尋數目", sortable: true },
        { key: "reject_records_count", label: "唔批" },
        { key: "client_loans_count", label: "做左" },
        { key: "account_records_count", label: "帳務紀錄" },
        { key: "empty_rate", label: "空氣率" },
      ],
    };
  },
  computed: {
    // dataMeta() {
    //   const localItemsCount = this.$refs.refSupplierSearchCountListTable
    //     ? this.$refs.refSupplierSearchCountListTable.localItems.length
    //     : 0;
    //   return {
    //     from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
    //     to: this.perPage * (this.currentPage - 1) + localItemsCount,
    //     of: this.totalRows,
    //   };
    // },
  },
  mounted() {
    this.getStartAndEndOfWeek(new Date());
    this.dateRange = [this.date_from, this.date_to];
    this.getSuppliers();
  },
  watch: {
    dateRange: {
      handler() {
        [this.date_from, this.date_to] = this.dateRange;
        if (typeof this.date_from === "object") {
          this.date_from = this.getFormatedDate(this.date_from);
          this.date_to = this.getFormatedDate(this.date_to);
          this.getSuppliers();
        }
      },
    },
    supplier_id: {
      handler() {
        this.getSuppliers();
      },
    },
    companyBit: {
      handler() {
        this.getSuppliers();
      },
    },
  },
  methods: {
    exportSearchRecord() {
      this.loading = true;
      const data  = {
        date_from :this.date_from, 
        date_to : this.date_to,
        company_bit : this.companyBit
      }
      store.dispatch("app-supplier/exportSearchRecord", data).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Search Record ${this.date_from} - ${this.date_to}`); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        this.loading = false
      });
    },
    setSupplierId(id) {
      if (this.supplier_id == id) {
        this.supplier_id = null;
      } else {
        this.supplier_id = id;
      }
    },
    getSuppliersOption(){
      this.$store
        .dispatch("app/fetchOptionList", {
          supplier: true,
        })
        .then((response) => {
          this.loanSupplierOptions = response.data.suppliers.filter(ele => ele.company_bit == this.companyBit || 0);
        });
    },
    getSuppliers() {
      this.loading = true;
      this.$store
        .dispatch("app-supplier/fetchSuppliersSearchCount", {
          // perPage: this.perPage,
          // page: this.currentPage,
          // sortBy: this.sortBy,
          // sortDesc: this.isSortDirDesc,
          date_from: this.date_from,
          date_to: this.date_to,
          company_bit: this.companyBit || 0,
          supplier_id: this.supplier_id || null,
        })
        .then((res) => {
          this.suppliers = res.data.suppliers;
          this.getSuppliersOption();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getFormatedDate(date) {
      const mm = date.getMonth() + 1; // getMonth() is zero-based
      const dd = date.getDate();

      return [
        date.getFullYear(),
        (mm > 9 ? "" : "0") + mm,
        (dd > 9 ? "" : "0") + dd,
      ].join("-");
    },
    getStartAndEndOfWeek(date) {
      if (date) {
        // Get start of week (Sunday)
        let startOfWeek = new Date(date.getTime());
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

        // Get end of week (Saturday)
        let endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);

        // Formatting to yyyy-mm-dd
        const yyyy_mm_dd = (date) => {
          const mm = date.getMonth() + 1; // getMonth() is zero-based
          const dd = date.getDate();

          return [
            date.getFullYear(),
            (mm > 9 ? "" : "0") + mm,
            (dd > 9 ? "" : "0") + dd,
          ].join("-");
        };

        this.date_from = yyyy_mm_dd(startOfWeek);
        this.date_to = yyyy_mm_dd(endOfWeek);

        console.log("End of week:", this.date_to );
      }
    },
  },
  setup() {
    const SUPPLIER_APP_STORE_MODULE_NAME = "app-supplier";
    const loading = ref(false);
    // Register module
    if (!store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME))
      store.registerModule(SUPPLIER_APP_STORE_MODULE_NAME, supplierStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME))
        store.unregisterModule(SUPPLIER_APP_STORE_MODULE_NAME);
    });

    // const loanSupplierOptions = ref([]);

    // const refetchOption = () => {
    //   store
    //     .dispatch("app/fetchOptionList", {
    //       supplier: true,
    //     })
    //     .then((response) => {
    //       loanSupplierOptions.value = response.data.suppliers.filter(ele => ele.id == 0);
    //     });
    // };

    // onMounted(() => {
    //   refetchOption();
    // });

    return {
      loading,
      // loanSupplierOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.company_name {
  text-decoration: underline;
  cursor: pointer;
}
</style>
