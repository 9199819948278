<template>
  <b-modal
    id="modal-change-award"
    hide-footer
    cancel-variant="outline-secondary"
    centered
    size="lg"
    title=""
    :ok-disabled="false"
  > 
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
    <b-form class="mt-sm-1" @submit.prevent="handleSubmit(onSubmit)">
      <b-row>
        <b-col cols="12" md="12">
          <validation-provider
            #default="validationContext"
            name="獎品名稱"
            rules="required"
          >
            <b-form-group
              label="獎品名稱："
              label-for="modal_award_name"
              label-cols-md="3"
              class="required"
            >
              <b-form-input
                id="modal_award_name"
                v-model="awardData.reward_title"
                :state="getValidationState(validationContext)"
                placeholder="獎品名稱"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="12">
            <b-form-group
              label="獎品詳情："
              label-for="modal_award_description"
              label-cols-md="3"
            >
              <b-form-input
                id="modal_award_description"
                v-model="awardData.reward_description"
                placeholder="獎品詳情"
              />
            </b-form-group>
        </b-col>

        <b-col cols="12" md="12">
          <validation-provider
            #default="validationContext"
            name="獎品價值"
            rules="required"
          >
            <b-form-group
              label="獎品價值："
              label-for="modal_award_amount"
              label-cols-md="3"
              class="required"
            >
              <b-form-input
                type="number"
                id="modal_award_amount"
                v-model="awardData.amount"
                :state="getValidationState(validationContext)"
                placeholder="輸入獎品價值"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="12">
          <validation-provider
            #default="validationContext"
            name="得獎率"
            rules="required"
          >
            <b-form-group
              label="得獎率："
              label-for="modal_winning_percentage"
              label-cols-md="3"
              class="required"
            >
              <b-form-input
                type="number"
                 step="0.01" min="0.00" 
                id="modal_winning_percentage"
                v-model="awardData.winning_percentage"
                :state="getValidationState(validationContext)"
                placeholder="輸入得獎率"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="12">
           <b-form-group
                label="是否幣？"
                label-for="is_active"
                label-cols-md="3"
              >
                <b-form-checkbox
                  :checked="awardData.is_coin"
                  name="is_active"
                  switch
                  inline
                  v-model="awardData.is_coin"
                  value="1"
                  style="margin-top: 5px"
                >
                  {{ awardData.is_coin ? "是" : "否" }}
                </b-form-checkbox>
              </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex mt-2 justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
        >
          修改
        </b-button>

        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="hide"
        >
          取消
        </b-button>
      </div>
    </b-form>
      </b-overlay>
  </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCardBody,
  BCard,
  BFormInvalidFeedback,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import awardStoreModule from "../../awardStoreModule";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import zh_CN from 'vee-validate/dist/locale/zh_CN.json';
import { ref, onUnmounted } from "@vue/composition-api";
localize('zh_cn',zh_CN)
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    vSelect,
    BCard,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      required
    };
  },
  watch: {
    // awardData: {
    //   handler(newName, oldName) {
    //     this.c_id = newName.award_id;
    //   },
    //   immediate: true,
    // },
  },
  props: {
    awardData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-change-award");
    },

    onSubmit() {
       this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-award/editWheelAward", this.awardData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.hide()
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
     makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
  },
  setup(props, { emit }) {

    const AWARD_APP_STORE_MODULE_NAME = "app-award";
    
    if (!store.hasModule(AWARD_APP_STORE_MODULE_NAME))
      store.registerModule(AWARD_APP_STORE_MODULE_NAME, awardStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AWARD_APP_STORE_MODULE_NAME))
        store.unregisterModule(AWARD_APP_STORE_MODULE_NAME);
    });

    const resetAwardData = () => {
      props.AwardData = JSON.parse(JSON.stringify({}));
    };

    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetAwardData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    };
  },
   
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

