import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function usePagesList() {
    //User toast
    const toast = useToast()

    const refPageListTable = ref(null)

    const tableColumns = [
        {key: 'title', label:'頁面', sortable: true},
        {key: 'show_details', label:'顯示內容'},
        {key: 'is_active', label:'狀態',sortable: true},
        {key: 'sort_order', label: '排序',sortable: true},
        {key: 'actions', label: '操作'},
    ]

    const perPage = ref(10)
    const totalPages = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('sort_order')
    const isSortDirDesc = ref(false)

    //Filter 
    const statusFilter = ref(-1)

    const dataMeta = computed(() => {
        const localItemsCount = refPageListTable.value ? refPageListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalPages.value,
        }
    })

    const refetchData = () => {
        refPageListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData()
    })

    const fetchPages = (ctx, callback) => {
        store.dispatch('page/fetchPages',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            is_active: statusFilter.value, // As defined -1 means get all page not matter it is active or not
        })
        .then(response =>{
            const {pages, total} = response.data
            callback(pages)
            totalPages.value = total
        
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching page list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchPages,
        tableColumns,
        perPage,
        currentPage,
        totalPages,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPageListTable,
        ability,
        refetchData,
        statusFilter, 
    }

}