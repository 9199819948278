import { render, staticRenderFns } from "./ApprovesList.vue?vue&type=template&id=47858231&scoped=true&"
import script from "./ApprovesList.vue?vue&type=script&lang=js&"
export * from "./ApprovesList.vue?vue&type=script&lang=js&"
import style0 from "./ApprovesList.vue?vue&type=style&index=0&id=47858231&lang=scss&scoped=true&"
import style1 from "./ApprovesList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47858231",
  null
  
)

export default component.exports